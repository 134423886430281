import React, { useRef } from "react";
import Lottie from "lottie-react";
import animation from "../assets/animations/phoneAnimation.json";

function Animation() {
  const animationRef = useRef();
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Lottie lottieRef={animationRef} animationData={animation} />
    </div>
  );
}

export default Animation;
