import React, { useEffect } from "react";
import Button from "../../UI/Button";
import { useNavigate } from "react-router-dom";
import useRequest from "../../../requests/request";
import TabBar from "../../UI/TabBar/TabBar";

const RoadmapsList = () => {
  const { data, loading, error, get } = useRequest();
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("token");
    get(`/Roadmap`, {
      headers: {
        Authorization: "Bearer " + JSON.parse(token),
      },
    });
  }, []);

  const hanleRoadmapClicked = (id) => {
    navigate(`/roadmaps/${id}`);
  };

  return (
    <div>
      {loading ? (
        <p>loading...</p>
      ) : data && !error ? (
        <>
          {data.map((roadmap) => (
            <Button
              onClick={() => {
                hanleRoadmapClicked(roadmap.id);
              }}
              style={{ margin: "15px 0" }}
              key={roadmap.id}
            >
              {roadmap.title}
            </Button>
          ))}
        </>
      ) : (
        error && <p>{error}</p>
      )}
      <TabBar />
    </div>
  );
};

export default RoadmapsList;
