import React, { useEffect, useState } from "react";

import classes from "./InfoModal.module.css";
import RoadmapSingleStep from "../../../Roadmap/RoadmapDetails/RoadmapSteps/RoadmapSingleStep";
import axiosInstance from "../../../../request/axiosInstance";
import { useNavigate } from "react-router-dom";

function InfoModal({ id, onClose }) {
  const [extended, setExtended] = useState(false);
  const navigate = useNavigate();
  const [roadmapId, setRoadmapId] = useState(0);
  const [loading, setIsLoading] = useState(true);
  const [roadmapEntity, setRoamapEntity] = useState({});
  const extendHandler = () => {
    setExtended((prev) => !prev);
  };

  const goToRoadmap = () => {
    navigate(`/roadmaps/${roadmapId}`);
  };

  useEffect(() => {
    axiosInstance
      .patch(`/roadrunner/entity/update/${id}`, {})
      .then((res) => {
        setRoamapEntity(
          res.data.UserRoadmapEntity.filter((entity) => entity.id === id)[0]
        );
        setRoadmapId(res.data.roadmapId);
      })
      .finally(() => setIsLoading(false));
  });

  return (
    <div id="myModal" className={classes.modal}>
      <div
        className={classes["modal-content"]}
        style={extended ? { height: "96vh" } : {}}
      >
        <div className={classes["modal-header"]}>
          <span className={classes.close}>
            {extended && (
              <svg
                width="22"
                height="11"
                viewBox="0 0 22 11"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                onClick={extendHandler}
              >
                <path
                  opacity="0.31"
                  d="M20 2L12.8418 7.56747C11.7585 8.41006 10.2415 8.41006 9.15818 7.56747L2 2"
                  stroke="#16C6ED"
                  stroke-width="4"
                  stroke-linecap="round"
                />
              </svg>
            )}
            {!extended && (
              <svg
                width="22"
                height="11"
                viewBox="0 0 22 11"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                onClick={extendHandler}
              >
                <path
                  opacity="0.31"
                  d="M2 9L9.15818 3.43253C10.2415 2.58994 11.7585 2.58994 12.8418 3.43253L20 9"
                  stroke="#16C6ED"
                  stroke-width="4"
                  stroke-linecap="round"
                />
              </svg>
            )}
          </span>
          <span className={classes.closeButton} onClick={onClose}>
            x
          </span>
        </div>

        <div className={classes["modal-body"]}>
          <div className={classes.stepHeader}>
            <p>Step Details</p>
            <button onClick={goToRoadmap}>
              Open Roadmap{"   "}
              <svg
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9 9.4995H3C2.86759 9.4993 2.74065 9.44661 2.64702 9.35298C2.55339 9.25935 2.5007 9.13241 2.5005 9V3C2.5005 2.72475 2.72475 2.5005 3 2.5005H5.5005C5.63299 2.49971 5.75981 2.44662 5.85336 2.3528C5.9469 2.25897 5.99961 2.13199 6 1.9995C6 1.72575 5.77425 1.5 5.5005 1.5H2.5005C1.9455 1.5 1.5 1.95 1.5 2.5005V9.4995C1.5 10.0504 1.95 10.5 2.5005 10.5H9.4995C10.0504 10.5 10.5 10.05 10.5 9.4995V6.4995C10.5 6.22575 10.2743 6 10.0005 6C9.86801 6.00039 9.74103 6.0531 9.6472 6.14664C9.55338 6.24019 9.50029 6.36701 9.4995 6.4995V9C9.4995 9.27525 9.27525 9.4995 9 9.4995ZM7.0005 1.9995C7.0005 2.27475 7.22475 2.5005 7.5 2.5005H8.79487L4.23038 7.065C4.03538 7.26 4.03538 7.575 4.23038 7.76962C4.42537 7.96462 4.74038 7.96462 4.93538 7.76962L9.49912 3.20512V4.5C9.49912 4.77525 9.72487 4.9995 10.0001 4.9995C10.2739 4.9995 10.4996 4.77525 10.4996 4.5V1.9995C10.5 1.72575 10.2743 1.5 10.0005 1.5H7.5C7.22475 1.5 7.0005 1.72575 7.0005 1.9995Z"
                  fill="white"
                />
              </svg>
            </button>
          </div>
          {!loading ? (
            <RoadmapSingleStep
              id={id}
              roadmap
              roadmapRefrence={
                roadmapEntity.RoadmapEntity !== undefined &&
                roadmapEntity.RoadmapEntity.roadmapRefrence
              }
              isUserRoadmap={true}
              description={roadmapEntity.RoadmapEntity.description}
              name={roadmapEntity.RoadmapEntity.title}
              stepNum={roadmapEntity.priority}
              dueDateEstimation={Math.ceil(
                (roadmapEntity.RoadmapEntity.minEstimation +
                  roadmapEntity.RoadmapEntity.maxEstimation) /
                  2
              )}
              stepOpen={true}
              stepDuration={`${roadmapEntity.RoadmapEntity.minEstimation}-${roadmapEntity.RoadmapEntity.maxEstimation} Weeks`}
              dueDateFrom={roadmapEntity.dueDateFrom}
              dueDateTo={roadmapEntity.dueDateTo}
              subSteps={roadmapEntity.SubSteps}
              isEnrolled={true}
              started={true}
              type={roadmapEntity.type}
              status={roadmapEntity.status}
            />
          ) : (
            <p>loading....</p>
          )}
        </div>

        <div className={classes["modal-footer"]}></div>
      </div>
    </div>
  );
}

export default InfoModal;
