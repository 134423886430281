import React from "react";
import Layout from "../components/UI/Layout/Layout";
import Home from "../components/Home/Home";
import { useParams } from "react-router-dom";
import AllPersonalTodos from "../components/Home/AllPersonalTodos/AllPersonalTodos";
import AllRoadmapTodos from "../components/Home/AllRodamapTodos/AllRoadmapTodos";

function HomePage() {
  const params = useParams();

  let currentPage = <Home />;
  switch (params.todo) {
    case "all-personalTodos":
      currentPage = <AllPersonalTodos />;
      break;
    case "all-roadmapTodos":
      currentPage = <AllRoadmapTodos />;
      break;

    default:
      break;
  }

  return <Layout>{currentPage}</Layout>;
}

export default HomePage;
