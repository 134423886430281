import React, { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

const GoogleRedirectHandler = () => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const token = searchParams.get("token");

    if (token) {
      // Store the token securely
      localStorage.setItem("token", JSON.stringify(token));

      // Redirect to a protected route or homepage
      navigate("/explore");
    } else {
      // Handle error or invalid token scenario
      console.error("Authentication failed");
      navigate("/login");
    }
  }, [location, navigate]);

  return <div>Loading...</div>;
};

export default GoogleRedirectHandler;
