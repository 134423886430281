import React, {useEffect, useContext, useState} from "react";
import UserContext from "../store/user-context";
import Layout from "../components/UI/Layout/Layout";
import ExploreContent from "../components/Explore/Explore";

function Explore() {
  

  return (
    <Layout>
     <ExploreContent />
    </Layout>
  );
}

export default Explore;
