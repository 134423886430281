import React, { useState } from "react";

import { Calendar, Card } from "react-rainbow-components";

import classes from "./FilterClander.module.css";
import { getTodoExtraClass } from "../../../utils/Utils";
import axiosInstance from "../../../request/axiosInstance";

function FilterCalender(props) {
  const [range, setRange] = useState(null);

  const calenderRangeHandler = () => {
    if (props.isPersonalTodo) {
      props.setPersonalLoading(true);
      axiosInstance
        .get("/personal-todo", {
          params: {
            filter: ["m", "n"],
            startDate: range[0],
            endDate: range[1] ? range[1] : "",
          },
        })
        .then((res) => {
          const personalTodos = res.data.map((pTodo) => {
            const deadline = new Date(pTodo.deadline);
            console.log(deadline);

            let extraClass = getTodoExtraClass(pTodo.status, pTodo.deadline);
            return {
              id: pTodo.id,
              title: pTodo.title,
              time:
                pTodo.deadline !== null
                  ? deadline.toLocaleTimeString(navigator.language, {
                      hour: "2-digit",
                      minute: "2-digit",
                      hour12: false,
                    })
                  : "",
              deadline: pTodo.deadline,
              repeatStartDate: pTodo.repeatStartDate,
              repeatEndDate: pTodo.repeatEndDate,
              description: pTodo.description,
              extraClass: extraClass,
            };
          });

          props.setPersonalItems(personalTodos);
        })
        .finally(() => props.setPersonalLoading(false));
    } else {
      props.setRoadmapLoading(true);
      axiosInstance
        .get("/roadrunner/roadmap-todo", {
          params: {
            filter: ["n", "m"],
            startDate: range[0],
            endDate: range[1] ? range[1] : "",
          },
        })
        .then((res) => {
          const roadmapStepsTodo = res.data.map((step) => {
            const deadline = new Date(step.dueDateTo);
            const roadmapName = step.RoadmapEntity.Roadmap.title;
            const stepName = step.RoadmapEntity.title;
            const tasks = step.SubSteps.map((subtask) => {
              return {
                id: subtask.id,
                title: subtask.RoadmapEntity.title,
                isDone: subtask.status === "done",
                priority: subtask.priority,
              };
            });

            const extraClass = getTodoExtraClass(step.status, step.dueDateTo);

            return {
              id: step.id,
              name: roadmapName,
              step: stepName,
              url: `/user-roadmap/${step.userRoadmapId}`,
              time:
                step.dueDateTo !== null
                  ? `${deadline.getDate()} ${deadline.toLocaleString(
                      "default",
                      {
                        month: "short",
                      }
                    )}`
                  : "",
              isDone: step.status === "done",
              tasks: tasks,
              extraClass: extraClass,
            };
          });

          props.setRoadmapItems(roadmapStepsTodo);
        })
        .finally(() => props.setRoadmapLoading(false));
    }
  };

  return (
    <Card
      className={`rainbow-p-around_large ${classes.calenderContainer}`}
      style={
        props.show
          ? {
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              zIndex: "1000",
              margin: "0 12px",
              width: "85%",
              position: "absolute",
              top: "30%",
              height: "430px",
            }
          : { display: "none" }
      }
    >
      <p className={classes.calednerTitle}>
        We can set deadlines if you'd like. Pick start and end dates.
      </p>

      <Calendar
        id="calendar-7"
        selectionType="range"
        value={range}
        onChange={(value) => {
          setRange(value);
        }}
        className={classes.calender}
      />
      <div className={classes.calenderButtons}>
        <button
          className={classes.calenderButton}
          onClick={() => {
            calenderRangeHandler();
            props.setShow(false);
          }}
        >
          Ok
        </button>
        <button
          className={classes.calenderButton}
          onClick={() => {
            setRange(null);
            props.setShow(false);
          }}
        >
          Cancel
        </button>
      </div>
    </Card>
  );
}

export default FilterCalender;
