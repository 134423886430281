import React, { useState } from "react";
import SearcBox from "./SearcBox";

import classes from "./AllTodos.module.css";
import filter from "../../../assets/images/Home/icons/filter.png";
import AllBadges from "./AllBadges";
import Popup from "./Popup";
import TodoItemsList from "../TodoItemsList/TodoItemsList";

function AllTodos(props) {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState("");
  const [filterList, setFilterList] = useState([]);

  return (
    <div className={classes.container}>
      <div className={classes.searchBox}>
        <SearcBox
          setPersonalItems={props.setPersonalItems}
          setPersonalLoading={props.setPersonalLoading}
          setRoadmapItems={props.setRoadmapItems}
          setRoadmapLoading={props.setRoadmapLoading}
          isPersonalTodo={props.isPersonalTodo}
        />
        <div className={classes.filter}>
          <img src={filter} alt="" onClick={() => setIsPopupOpen(true)} />
          <div
            className={classes.popupContainer}
            style={isPopupOpen ? {} : { display: "none" }}
          >
            <Popup
              show={isPopupOpen}
              onClose={() => {
                setIsPopupOpen(false);
                console.log(selectedFilter);
              }}
              setSelectedFilter={setSelectedFilter}
            />
          </div>
        </div>
      </div>
      <AllBadges
        setFilterList={setFilterList}
        filterList={filterList}
        setPersonalItems={props.setPersonalItems}
        setPersonalLoading={props.setPersonalLoading}
        setRoadmapItems={props.setRoadmapItems}
        setRoadmapLoading={props.setRoadmapLoading}
        isPersonalTodo={props.isPersonalTodo}
      />

      <TodoItemsList
        items={props.items}
        setItems={props.setItems}
        style={{ margin: 0, marginTop: "18px" }}
        isPersonalTodo={props.isPersonalTodo}
        isSeeAll={true}
        loading={props.loading}
      />
    </div>
  );
}

export default AllTodos;
