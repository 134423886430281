import { React, useState, useEffect } from "react";
import Checkbox from "react-custom-checkbox";
import { Tabs } from "antd";
import TabPane from "antd/es/tabs/TabPane";
import Menu from "./Menu/Menu";
import Tree from "./Tree";
import Resources from "./Resources/Resources";
import classes from "./TreeNode.module.css";
import dots from "../../../../assets/images/Roadmap/icon/threeDots.png";
import resources from "../../../../assets/images/Roadmap/icon/resource.png";

import check from "../../../../assets/images/Roadmap/icon/checkIcon.png";
import lock from "../../../../assets/images/Roadmap/icon/lock.png";
import useRequest from "../../../../requests/request";
import { findParentEntityWithSubstep } from "../../../../utils/Utils";
import axiosInstance from "../../../../request/axiosInstance";

function TreeNode({
  node,
  isEnrolled,
  started,
  dateRange,
  itemWidth,
  setFullScreenChat,
  registerCallback,
  roadmapSetter,
  roadmapEntity,
  isTDone,
  setRoadmapModalOpen,
  setIsStepDone,
  handleStepDone,
}) {
  const { SubSteps, title, type, description } = node;
  const { data, loading, patch } = useRequest();
  const [showChildren, setShowChildren] = useState(false);
  const [isDone, setIsDone] = useState(isTDone);
  const [isLocked, setIsLocked] = useState(false);
  const [checked, setChecked] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const [activeKey, setActiveKey] = useState(null);
  const [deadlineStatus, setDeadlineStatus] = useState("");

  function addDays(date, days) {
    const newDate = new Date(date);
    newDate.setDate(date.getDate() + days);
    return newDate;
  }
  const handleFullScreenChat = () => setFullScreenChat();

  const handleTabChange = (key) => {
    if (!started && key !== "2") {
      setActiveKey(null);
    } else if (isLocked && key !== "2") {
      setActiveKey(null);
    } else {
      setActiveKey(key);
    }
  };

  const tabClicked = () => {
    if (activeKey !== null) {
      setActiveKey(null);
    }
  };

  const handleClick = () => {
    setShowChildren(!showChildren);
  };

  const checkboxChange = (checked) => {
    setIsDone((prev) => !prev);
    axiosInstance
      .patch(`/roadrunner/entity/update/${node.id}`, {
        status: checked ? "pending" : "done",
      })
      .then((res) => {
        roadmapSetter(res.data);
      });
  };

  useEffect(() => {
    if (data) {
      const token = localStorage.getItem("token");
      const entities = findParentEntityWithSubstep(
        data.UserRoadmapEntity,
        node.id
      );

      if (entities.SubSteps.filter((ss) => ss.status !== "done").length === 0) {
        setIsStepDone(true);
        handleStepDone(token);
      }
    }
  }, [checked, data]);

  useEffect(() => {
    setIsDone(node.status === "done");
    setIsLocked(node.status === "locked");
  }, [node.status]);

  return (
    <>
      <div
        className={`${classes.item} ${isDone &&
          classes.gradient} ${deadlineStatus === "overdue" &&
          !isDone &&
          classes.overdue} ${deadlineStatus === "deadlineToT" &&
          !isDone &&
          classes.deadlineToT} ${isLocked && classes.locked}`}
      >
        <div className={classes.flexItems}>
          <div className={classes.checkboxContainer}>
            {started && !isLocked ? (
              <Checkbox
                size={10}
                checked={isDone}
                value={isDone}
                onChange={() => checkboxChange(isDone)}
                icon={
                  <img src={check} style={{ width: 7, height: 5 }} alt="" />
                }
                borderColor={`${
                  isDone ? "rgba(35, 204, 133, 1)" : "rgba(202, 196, 208, 1)"
                }`}
              />
            ) : (
              isLocked && (
                <Checkbox
                  size={12}
                  icon={
                    <img src={lock} style={{ width: 10, height: 12 }} alt="" />
                  }
                  borderColor="rgba(202, 196, 208, 1)"
                  checked
                  disabled
                />
              )
            )}

            <span
              className={type === "task" ? classes.name : classes.subName}
              onClick={handleClick}
            >
              {roadmapEntity ? roadmapEntity.title : title}
            </span>
          </div>
          <img
            src={dots}
            style={{ width: "4px", height: "15px" }}
            alt=""
            onClick={() => setShowMenu((prev) => !prev)}
          />
        </div>
        <div className={classes.menue}>
          {isEnrolled ? (
            <Menu
              setFullScreenChat={handleFullScreenChat}
              started={started}
              entityId={node.id}
              dueDateFrom={node.dueDateFrom}
              dueDateTo={node.dueDateTo}
              isLocked={isLocked}
              isDone={isDone}
              showMenu={showMenu}
              type={type}
              description={
                roadmapEntity ? roadmapEntity.description : description
              }
              registerCallback={registerCallback}
              itemWidth={itemWidth}
              isTask={true}
              setDeadlineStatus={setDeadlineStatus}
              dateRange={
                dateRange && [
                  dateRange[0],
                  addDays(
                    dateRange[0],
                    Math.ceil(
                      (roadmapEntity.minEstimation +
                        roadmapEntity.maxEstimation) /
                        2
                    ) * 7
                  ),
                ]
              }
            />
          ) : (
            <Tabs
              activeKey={activeKey}
              onChange={handleTabChange}
              size="small"
              indicator={{ size: 0 }}
              style={!showMenu && { display: "none" }}
            >
              <TabPane
                tab={
                  <div
                    className={
                      activeKey === "2" ? classes.activeTab : classes.tab
                    }
                    onClick={tabClicked}
                  >
                    <img src={resources} alt="" />
                  </div>
                }
                key="2"
              >
                {activeKey === "2" && (
                  <Resources
                    description={
                      roadmapEntity ? roadmapEntity.description : description
                    }
                  />
                )}
              </TabPane>
            </Tabs>
          )}
        </div>
        {type === "task" && (
          <div
            className={!showChildren ? classes.openTask : classes.closeTask}
            onClick={handleClick}
          ></div>
        )}
      </div>
      <ul className={classes.items}>
        {showChildren && SubSteps && (
          <Tree
            treeData={SubSteps}
            isEnrolled={isEnrolled}
            roadmapSetter={roadmapSetter}
            isTDone={isDone}
            started={started}
            setIsStepDone={setIsStepDone}
            dateRange={
              dateRange && [
                dateRange[0],
                addDays(
                  dateRange[0],
                  roadmapEntity
                    ? Math.ceil(
                        (roadmapEntity.minEstimation +
                          roadmapEntity.maxEstimation) /
                          2
                      ) * 7
                    : Math.ceil((node.minEstimation + node.maxEstimation) / 2) *
                        7
                ),
              ]
            }
            itemWidth={itemWidth}
            setFullScreenChat={handleFullScreenChat}
            registerCallback={registerCallback}
          />
        )}
      </ul>
    </>
  );
}

export default TreeNode;
