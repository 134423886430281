import styles from "./VerticalGrideBased.module.css";
import ExploreSingleItem from "../../ExploreSingleItem/ExploreSingleItem";

const VerticalGridBased = ({ items }) => {
  return (
    <div className={styles.container}>
      {items.map((item) => (
        <div key={item.id}>
          <ExploreSingleItem
            type="vertical"
            roadmapId={item.id}
            pic={item.thumbnail}
            title={item.title}
            roadmapOwnre={item.creator.fullName}
            rate={item.averageRate}
            roadRunners={item.roadRunners}
            premium={item.premium}
          />
        </div>
      ))}
    </div>
  );
};

export default VerticalGridBased;
