import React, { useContext } from "react";

import classes from "./TodoTitles.module.css";

import addIcon from "../../assets/images/Home/icons/addTodo.png";
import { useNavigate } from "react-router-dom";
import UserContext from "../../store/user-context";

function TodoTitles({
  title,
  isPersonalTodo,
  setOpenModal,
  isOpen,
  setIsOpen,
}) {
  const navigate = useNavigate();
  const userCtx = useContext(UserContext);

  const onclickHandler = () => {
    if (isPersonalTodo) {
      navigate(`/home-page/all-personalTodos`);
    } else {
      navigate(`/home-page/all-roadmapTodos`);
    }
  };

  return (
    <div
      className={classes.rTodoTitle}
      style={!isOpen ? { position: "unset" } : {}}
    >
      <div
        className={classes.titleContainer}
        style={userCtx.token === null ? { background: "none" } : {}}
      >
        <p
          className={userCtx.token === null && classes.inActive}
          onClick={() => {
            if (userCtx.token !== null) {
              setIsOpen((prev) => !prev);
            }
          }}
          style={
            isPersonalTodo
              ? {
                  background:
                    "linear-gradient(90deg, rgba(128, 223, 248, 0.9) 0%, rgba(3, 189, 243, 0.9) 100%)",
                }
              : {}
          }
        >
          {isOpen && (
            <svg
              width="9"
              height="6"
              viewBox="0 0 9 6"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8.2424 6L0.757563 6C0.0841293 6 -0.25243 5.07064 0.221584 4.52694L3.96557 0.252598C4.2603 -0.0841994 4.73935 -0.0841994 5.03187 0.252598L8.77806 4.52751C9.2527 5.07064 8.91583 6 8.2424 6Z"
                fill="white"
              />
            </svg>
          )}
          {!isOpen && (
            <svg
              width="9"
              height="6"
              viewBox="0 0 9 6"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.757605 0H8.24244C8.91587 0 9.25243 0.929355 8.77842 1.47306L5.03443 5.7474C4.7397 6.0842 4.26065 6.0842 3.96813 5.7474L0.22194 1.47249C-0.252703 0.929356 0.0841711 0 0.757605 0Z"
                fill="white"
              />
            </svg>
          )}

          {title}
        </p>
        {isPersonalTodo && (
          <span className={userCtx.token === null && classes.inActive}>
            <img src={addIcon} alt="" onClick={() => setOpenModal(true)} />
          </span>
        )}
      </div>
      <span className={classes.seeAllText} onClick={onclickHandler}>
        See All
      </span>
    </div>
  );
}

export default TodoTitles;
