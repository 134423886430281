import React, { useState } from "react";

import classes from "./AddTodoModal.module.css";
import back from "../../../assets/images/Home/icons/back.png";
import DueDateCalender from "./DueDateCalendar";
import CustomRepeat from "./CutstomRepeatModal/CustomRepeat";

function DueDateModal(props) {
  const [openRepeatModal, setOpneRepeatModal] = useState(false);
  const [repeatRange, setRepeatRange] = useState(null);
  const [selectedDaysList, setSelectedDaysList] = useState([-1]);
  const [repeatWay, setRepeatWay] = useState("Forever");

  return (
    <>
      <div id="myModalDue" className={classes.modal}>
        <div className={classes["modal-content"]} style={{ height: "65%" }}>
          <div
            className={classes["modal-header"]}
            style={{ position: "relative", height: "1%" }}
          >
            <div className={classes.back}>
              <img src={back} alt="" onClick={props.onBack} />
            </div>
          </div>

          <div className={classes["modal-body"]}>
            <DueDateCalender
              onClose={props.onClose}
              setPersonalItems={props.setPersonalItems}
              setPersonalLoading={props.setPersonalLoading}
              setOpenRepeatModal={setOpneRepeatModal}
              setTime={props.setTime}
              setRepeat={props.setRepeat}
              repeatRange={repeatRange}
              name={props.name}
              desc={props.desc}
              setName={props.setName}
              setDesc={props.setDesc}
              selectedDaysList={selectedDaysList}
            />
          </div>
        </div>
      </div>
      {openRepeatModal && (
        <CustomRepeat
          onClose={() => setOpneRepeatModal(false)}
          onOK={(val) => {
            setOpneRepeatModal(false);
          }}
          repeatRange={repeatRange}
          setRepeatRange={setRepeatRange}
          setSelectedList={setSelectedDaysList}
          selectedList={selectedDaysList}
          repeatWay={repeatWay}
          setRepeatWay={setRepeatWay}
        />
      )}
    </>
  );
}

export default DueDateModal;
