import React from "react";
import Layout from "../components/UI/Layout/Layout";
import UserRoadmap from "../components/Roadmap/UserRoadmap/UserRoadmap";
import { useParams } from "react-router-dom";

function UserRoadmapPage() {
  const params = useParams();

  return (
    <Layout>
      <UserRoadmap userRoadmapId={params.pageId} />
    </Layout>
  );
}

export default UserRoadmapPage;
