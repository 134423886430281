import { useState } from "react";
import { Modal } from "react-rainbow-components";
import Rating from "./Rating/Rating";
import { Input } from "antd";
import Button from "../../../UI/Button";
import useRequest from "../../../../requests/request";

import styles from "./RatingModal.module.css";

const { TextArea } = Input;

const RatingModal = ({ isModalOpen, setIsModalOpen, roadmap }) => {
  const { patch } = useRequest();
  const [userRate, setUserRate] = useState(0);
  const [userComment, setUserComment] = useState("");
  const [ratingSubmited, setRatingSubmited] = useState(false);

  const rateHandler = (rate) => {
    setUserRate(rate);
  };

  const onUserCommentChange = (e) => {
    setUserComment(e.target.value);
  };

  const ratingSubmitHandler = () => {
    const token = localStorage.getItem("token");
    patch(
      `/Roadmap/${roadmap.id}/rate/upsert`,
      { rate: userRate },
      {
        headers: {
          Authorization: "Bearer " + JSON.parse(token),
        },
      }
    );
    setRatingSubmited(true);
  };

  return (
    <Modal
      id="roadmap-rating"
      isOpen={isModalOpen}
      onRequestClose={() => setIsModalOpen(false)}
      style={{ width: "87%" }}
    >
      <div className={styles.modal}>
        {ratingSubmited ? (
          <pre>
            {
              "Thank you for your feedback!\nYour insights help us improve our\n roadmaps for everyone."
            }
          </pre>
        ) : (
          <>
            <p>How would you rate this Roadmap?</p>
            <Rating initialRating={userRate} onRate={rateHandler} />
            <p>Your detailed feedback helps us make the roadmaps better.</p>
            <TextArea
              value={userComment}
              onChange={onUserCommentChange}
              placeholder="Write your detailed feedback"
              className={styles.commentTextArea}
              style={{ height: "100px", resize: "none" }}
            />
            <div className={styles.modalFooter}>
              <button
                className={styles.modalSkipButton}
                onClick={() => setIsModalOpen(false)}
              >
                Not Now
              </button>
              <Button style={{ width: "48%" }} onClick={ratingSubmitHandler}>
                Submit
              </Button>
            </div>
          </>
        )}
      </div>
    </Modal>
  );
};

export default RatingModal;
