import React, { useState } from "react";

import { Calendar, Card } from "react-rainbow-components";

import classes from "./DueDateCalandar.module.css";
import TimePicker from "./TimePicker";
import RepeatPicker from "./RepeatPicker";
import axiosInstance from "../../../request/axiosInstance";
import { findIntForDays, getTodoExtraClass } from "../../../utils/Utils";

function DueDateCalender(props) {
  const [date, setDate] = useState({ date: null });
  const [time, setTime] = useState(null);
  const [repeat, setRepeat] = useState(null);

  const calenderRangeHandler = (value) => {
    props.setPersonalLoading(true);
    let deadline = new Date(date.date.toISOString());
    if (time) {
      deadline.setHours(time.slice(0, 2), time.slice(3, 5));
    }
    const daysList = findIntForDays(props.selectedDaysList);
    const today = new Date();

    let data = {};
    if (repeat === null) {
      data = {
        title: props.name,
        description: props.desc,
        deadline: deadline.toISOString(),
      };
    } else if (
      repeat === "daily" ||
      repeat === "weekly" ||
      repeat === "monthly"
    ) {
      data = {
        title: props.name,
        description: props.desc,
        deadline: deadline.toISOString(),
        repeatType: repeat,
        repeatStartDate: today.toISOString(),
        repeatEndDate: deadline.toISOString(),
      };
    } else if (repeat === "custom") {
      if (props.repeatRange !== null) {
        data = {
          title: props.name,
          description: props.desc,
          deadline: deadline.toISOString(),
          repeatType: repeat ? repeat : "norepeat",
          repeatStartDate: props.repeatRange[0].toISOString(),
          repeatEndDate: props.repeatRange[1].toISOString(),
          customDays: daysList,
        };
      } else {
        data = {
          title: props.name,
          description: props.desc,
          deadline: deadline.toISOString(),
          repeatType: repeat,
          repeatStartDate: today.toISOString(),
          repeatEndDate: deadline.toISOString(),
          customDays: daysList,
        };
      }
    }
    axiosInstance.post("/personal-todo", data).then((r) => {
      axiosInstance
        .get("/personal-todo", {
          params: {
            filter: ["home", "n"],
          },
        })
        .then((res) => {
          const personalTodos = res.data.map((pTodo) => {
            const deadline = new Date(pTodo.deadline);
            let extraClass = getTodoExtraClass(pTodo.status, pTodo.deadline);
            return {
              id: pTodo.id,
              title: pTodo.title,
              time:
                extraClass !== "nodate"
                  ? deadline.toLocaleTimeString(navigator.language, {
                      hour: "2-digit",
                      minute: "2-digit",
                      hour12: false,
                    })
                  : "",
              deadline: pTodo.deadline,
              repeatStartDate: pTodo.repeatStartDate,
              repeatEndDate: pTodo.repeatEndDate,
              description: pTodo.description,
              extraClass: extraClass,
            };
          });

          props.setPersonalItems(personalTodos);
        })
        .finally(() => props.setPersonalLoading(false));
    });

    props.setName("");
    props.setDesc("");
  };

  return (
    <Card
      className={`rainbow-p-around_large ${classes.calenderContainer}`}
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        zIndex: "1000",
        margin: "0 12px",
        width: "92%",
        border: "none",
        backgroundColor: "transparent",
        boxShadow: "none",
        marginTop: date.date === null && "35px",
      }}
    >
      {date.date !== null && (
        <p className={classes.calednerTitle}>{`${date.date.toLocaleString(
          "default",
          {
            month: "long",
          }
        )} ${date.date.getDate()}, ${date.date.getFullYear()}`}</p>
      )}

      <Calendar
        id="calendar-7"
        selectionType={"single"}
        value={date.date}
        onChange={(value) => {
          setDate({ date: value });
        }}
        className={classes.calender}
      />

      <div className={classes.timePikcer}>
        <TimePicker onTimeChange={(value) => setTime(value)} />
      </div>
      <div className={classes.repeat}>
        <RepeatPicker
          onRepeatChange={(value) => setRepeat(value)}
          setOpenRepeatModal={props.setOpenRepeatModal}
        />
      </div>

      <div className={classes.calenderButtons}>
        <button
          className={classes.calenderButton}
          onClick={() => {
            calenderRangeHandler();
            props.onClose();
          }}
        >
          OK
        </button>
        <button
          className={classes.calenderButton}
          onClick={() => props.onClose()}
        >
          REMOVE
        </button>
      </div>
    </Card>
  );
}

export default DueDateCalender;
