import ExploreSingleItem from "../../ExploreSingleItem/ExploreSingleItem";
import InnerVertical from "../InnerVertical/InnerVertical";
import styles from "./VerticalT1GridBased.module.css";

const VerticalT1GridBased = ({ items }) => {
  return (
    <div className={styles.container}>
      <ExploreSingleItem
        type="vertical"
        roadmapId={items[0].id}
        pic={items[0].thumbnail}
        title={items[0].title}
        roadmapOwnre={items[0].creator.fullName}
        rate={items[0].averageRate}
        roadRunners={items[0].roadRunners}
        premium={items[0].premium}
      />
      <InnerVertical items={items.slice(1, 3)} />
    </div>
  );
};

export default VerticalT1GridBased;
