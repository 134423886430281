import React, { useState } from "react";
import { Calendar } from "react-rainbow-components";

import classes from "./StepCalender.module.css";
import useRequest from "../../../../../requests/request";

function StepCalender(props) {
  const [range, setRange] = useState(props.defaultRange);
  const { data, loading, patch } = useRequest();
  const { patch: newPatch } = useRequest();

  const calenderRangeHandler = () => {
    const token = localStorage.getItem("token");
    let dataBody = {};
    if (range[1]) {
      dataBody = {
        dueDateTo: range[1].toISOString(),
        dueDateFrom: range[0].toISOString(),
      };
    } else {
      dataBody = {
        dueDateFrom: range[0].toISOString(),
      };
    }
    patch(`/roadrunner/entity/update/${props.entityId}`, dataBody, {
      headers: { Authorization: "Bearer " + JSON.parse(token) },
    });
    props.setRange(range);
    if (props.isStep) {
      if (props.type !== "roadmapStep") {
        props.setShow((prev) => !prev);
      }
      props.setShowCalender({ show: false, openedOnce: true });
    }
  };

  return (
    <>
      {props.isStep && (
        <p className={classes.calednerTitle}>
          We can set deadlines if you'd like. Pick start and end dates.
        </p>
      )}
      <Calendar
        id="calendar-7"
        selectionType="range"
        value={range}
        defaultValue={props.defaultRange}
        onChange={(value) => {
          if (!props.isDone) {
            setRange(value);
          }
        }}
        className={classes.calender}
      />
      <div className={classes.calenderButtons}>
        <button
          className={classes.calenderButton}
          onClick={() => calenderRangeHandler()}
        >
          Ok
        </button>
        <button
          className={classes.calenderButton}
          onClick={() => {
            console.log(props.defaultRange);
            props.setRange(props.defaultRange);
            setRange(props.defaultRange);
            if (props.isStep) {
              props.setShowCalender({ show: false, openedOnce: true });
              if (props.type !== "roadmapStep") {
                props.setShow((prev) => !prev);
              }
            }
          }}
        >
          Cancel
        </button>
      </div>
    </>
  );
}

export default StepCalender;
