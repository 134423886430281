import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import UserContext from "../../../store/user-context";
import axiosInstance from "../../../request/axiosInstance";

import styles from "./ExploreHeader.module.css";
import search from "../../../assets/images/Explore/icon/search.png";
import filter from "../../../assets/images/Explore/icon/filter.png";
import back from "../../../assets/images/Explore/icon/back.png";
import close from "../../../assets/images/Explore/icon/searchClose.png";
import Popup from "./Popup/Popup";

const ExploreHeader = ({ setExploreItems, setIsLoading, loading }) => {
  const userCtx = useContext(UserContext);
  const navigate = useNavigate();
  const [searchVal, setSearchVal] = useState("");
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isFocused, setIsFocused] = useState(false);

  const onSearching = (e) => {
    if (e.key === "Enter") {
      if (searchVal.trim()) {
        axiosInstance
          .post("/explore/query", {
            categoryIds: [-1],
            interestIds: [-1],
            Page: 1,
            search: searchVal,
          })
          .then((res) => {
            setExploreItems(res.data.data);
          })
          .finally(() => setIsLoading(false));
        setSearchVal("");
      }
    }
  };

  const searchHandler = (e) => {
    setSearchVal(e.target.value);
  };

  const backFocuseHandler = () => {
    setIsFocused(false);
    navigate("/explore");
    setSearchVal("");
  };

  const closeSearchHandler = () => {
    setSearchVal("");
  };

  return (
    <div className={styles.header}>
      {isFocused && (
        <img
          src={back}
          className={styles.backButton}
          onClick={backFocuseHandler}
          alt=""
        />
      )}
      <div
        className={styles.searchContainer}
        style={{ width: userCtx.token === null ? "75%" : "100%" }}
      >
        <img src={search} style={{ width: "15px", height: "15px" }} alt="" />
        <input
          className={styles.searchInput}
          placeholder="Search"
          value={searchVal}
          onChange={searchHandler}
          onFocus={() => setIsFocused(true)}
          onKeyPress={onSearching}
        />
        {searchVal !== "" && (
          <img
            src={close}
            className={styles.closeButton}
            onClick={closeSearchHandler}
            alt=""
          />
        )}
        <img
          src={filter}
          style={{ width: "17px", height: "17px" }}
          alt=""
          onClick={() => setIsPopupOpen(!isPopupOpen)}
        />
      </div>
      <div className={styles.popupContainer}>
        <Popup
          show={isPopupOpen}
          onClose={() => setIsPopupOpen(false)}
          setExploreItems={setExploreItems}
          setIsLoading={setIsLoading}
          loading={loading}
        />
      </div>
      {userCtx.token === null && (
        <button
          className={styles.signUpButton}
          onClick={() => navigate("/signup")}
        >
          Sign Up
        </button>
      )}
    </div>
  );
};

export default ExploreHeader;
