import React, { useContext, useEffect, useRef, useState } from "react";

import classes from "./Home.module.css";
import TodoItemsList from "./TodoItemsList/TodoItemsList";
import TodoTitles from "./TodoTitles";
import AddTodoModal from "./AddTodoModal/AddTodoModal";
import DueDateModal from "./AddTodoModal/DueDateModal";
import TabBar from "../UI/TabBar/TabBar";
import axiosInstance from "../../request/axiosInstance";
import { getTodoExtraClass } from "../../utils/Utils";
import onBoardChar from "../../assets/images/Home/notOnboardHome.png";
import signUp from "../../assets/images/Home/signUpHome.png";
import arrow from "../../assets/images/Home/icons/rightArrow.png";
import UserContext from "../../store/user-context";
import { useNavigate } from "react-router-dom";

function getDate() {
  const today = new Date();
  const month = today.toLocaleString("default", { month: "short" });
  const date = today.getDate();
  return `${date} ${month}`;
}

function Home() {
  const ptRef = useRef();
  const navigate = useNavigate();
  const userCtx = useContext(UserContext);
  const [todayDate, setTodayDate] = useState(getDate());
  const [personalLoading, setPersonalLoading] = useState(true);
  const [recommendedlLoading, setRecommendedLoading] = useState(true);
  const [roadmapLoading, setRoadmapLoading] = useState(true);
  const [isRoadmapOpen, setIsRoadmapOpen] = useState(true);
  const [isPersonalOpen, setIsPersonalOpen] = useState(false);
  const [isRoadmapRecomOpen, setIsRoadmapRecomOpen] = useState(false);
  const [isPersonaRecomlOpen, setIsPersonaRecomlOpen] = useState(false);
  const [openAddModal, setOpenAddModal] = useState(false);
  const [openDueModal, setOpenDueModal] = useState(false);
  const [name, setName] = useState("");
  const [desc, setDesc] = useState("");
  const [roadmapItems, setRoadmapItems] = useState([]);
  const [personalItems, setPersonalItems] = useState([]);
  const [personalRecommended, setPersonalRecommended] = useState([]);

  useEffect(() => {
    setTodayDate(getDate());
  }, []);

  useEffect(() => {
    if (userCtx.token !== null) {
      axiosInstance
        .get("/roadrunner/roadmap-todo", {
          params: {
            filter: ["v", "m"],
          },
        })
        .then((res) => {
          const roadmapStepsTodo = res.data.map((step) => {
            const roadmapName = step.RoadmapEntity.Roadmap.title;
            const stepName = step.RoadmapEntity.title;
            const tasks = step.SubSteps.map((subtask) => {
              return {
                id: subtask.id,
                title: subtask.RoadmapEntity.title,
                isDone: subtask.status === "done",
                priority: subtask.priority,
              };
            });

            const extraClass = getTodoExtraClass(step.status, step.dueDateTo);

            return {
              id: step.id,
              name: roadmapName,
              url: `/user-roadmap/${step.userRoadmapId}`,
              step: stepName,
              isDone: step.status === "done",
              tasks: tasks,
              extraClass: extraClass,
            };
          });

          setRoadmapItems(roadmapStepsTodo);
        })
        .finally(() => setRoadmapLoading(false));

      axiosInstance
        .get("/personal-todo", {
          params: {
            filter: ["home", "n"],
          },
        })
        .then((res) => {
          const personalTodos = res.data.map((pTodo) => {
            const deadline = new Date(pTodo.deadline);
            let extraClass = getTodoExtraClass(pTodo.status, pTodo.deadline);
            return {
              id: pTodo.id,
              title: pTodo.title,
              time:
                extraClass !== "nodate"
                  ? deadline.toLocaleTimeString(navigator.language, {
                      hour: "2-digit",
                      minute: "2-digit",
                      hour12: false,
                    })
                  : "",
              deadline: pTodo.deadline,
              repeatStartDate: pTodo.repeatStartDate,
              repeatEndDate: pTodo.repeatEndDate,
              description: pTodo.description,
              extraClass: extraClass,
            };
          });

          setPersonalItems(personalTodos);
        })
        .finally(() => setPersonalLoading(false));

      axiosInstance
        .get("/personal-todo", {
          params: {
            filter: ["missed", "nodate"],
          },
        })
        .then((res) => {
          const personalTodos = res.data.map((pTodo) => {
            const deadline = new Date(pTodo.deadline);
            let extraClass = getTodoExtraClass(pTodo.status, pTodo.deadline);
            return {
              id: pTodo.id,
              title: pTodo.title,
              time:
                extraClass !== "nodate"
                  ? deadline.toLocaleTimeString(navigator.language, {
                      hour: "2-digit",
                      minute: "2-digit",
                      hour12: false,
                    })
                  : "",
              deadline: pTodo.deadline,
              repeatStartDate: pTodo.repeatStartDate,
              repeatEndDate: pTodo.repeatEndDate,
              description: pTodo.description,
              extraClass: extraClass,
            };
          });

          setPersonalRecommended(personalTodos);
        })
        .finally(() => setRecommendedLoading(false));
    }
  }, []);

  const addPersonalTodoHandler = () => {
    setPersonalLoading(true);
    axiosInstance
      .post("/personal-todo", {
        title: name,
        description: desc,
        repeatType: "none",
        deadline: null,
      })
      .then((r) => {
        axiosInstance
          .get("/personal-todo", {
            params: {
              filter: ["home", "n"],
            },
          })
          .then((res) => {
            const personalTodos = res.data.map((pTodo) => {
              const deadline = new Date(pTodo.deadline);
              let extraClass = getTodoExtraClass(pTodo.status, pTodo.deadline);
              return {
                id: pTodo.id,
                title: pTodo.title,
                time:
                  extraClass !== "nodate"
                    ? deadline.toLocaleTimeString(navigator.language, {
                        hour: "2-digit",
                        minute: "2-digit",
                        hour12: false,
                      })
                    : "",
                deadline: pTodo.deadline,
                repeatStartDate: pTodo.repeatStartDate,
                repeatEndDate: pTodo.repeatEndDate,
                description: pTodo.description,
                extraClass: extraClass,
              };
            });

            setPersonalItems(personalTodos);
          })
          .finally(() => setPersonalLoading(false));
      });
  };

  return (
    <div className={classes.container}>
      <div className={classes.todayDate}>
        <p>Today, {todayDate}</p>
      </div>
      {!userCtx.user.selectedCategories && (
        <div
          className={classes.notOnboarded}
          onClick={() => {
            navigate("/onboarding");
          }}
        >
          <div className={classes.notOnboardedImg}>
            <img src={onBoardChar} alt="" />
            <p>Let’s get onboard!</p>
          </div>
          <div className={classes.notOnboardedArrow}>
            <img src={arrow} alt="" />
          </div>
        </div>
      )}
      <div
        className={`${classes.itemsContainer}`}
        style={
          !isRoadmapOpen
            ? { marginTop: 0 }
            : !isPersonalOpen && roadmapItems.length > 0
            ? { height: "fit-content" }
            : roadmapItems.length > 0
            ? { height: "fit-content" }
            : {
                height: "fit-content",
              }
        }
      >
        <TodoTitles
          title={"Roadmaps To Do"}
          isPersonalTodo={false}
          isOpen={isRoadmapOpen}
          setIsOpen={(val) => {
            setIsRoadmapOpen(val);
          }}
        />
        <div
          className={`${classes.roadmapTodo}  ${userCtx.token === null &&
            classes.inActiveRoadmapTodo}`}
          style={
            !isRoadmapOpen ? { display: "none" } : { height: "fit-content" }
          }
        >
          <div
            style={isRoadmapRecomOpen ? { height: "50%" } : { height: "90%" }}
          >
            {" "}
            <TodoItemsList
              isPersonalTodo={false}
              items={roadmapItems}
              loading={roadmapLoading}
              setItems={setRoadmapItems}
            />
          </div>
          <div
            className={classes.recommended}
            onClick={() => setIsRoadmapRecomOpen((prev) => !prev)}
          >
            <p> Recommended to do</p>{" "}
            {!isRoadmapRecomOpen && (
              <svg
                width="8"
                height="6"
                viewBox="0 0 8 6"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  opacity="0.6"
                  d="M0.673426 0H7.32661C7.92522 0 8.22438 0.826093 7.80304 1.30939L4.47505 5.1088C4.21307 5.40818 3.78725 5.40818 3.52723 5.1088L0.19728 1.30888C-0.224625 0.826094 0.0748188 0 0.673426 0Z"
                  fill="#E5642B"
                />
              </svg>
            )}
            {isRoadmapRecomOpen && (
              <svg
                width="8"
                height="6"
                viewBox="0 0 8 6"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  opacity="0.6"
                  d="M7.32657 5.33301L0.673389 5.33301C0.0747814 5.33301 -0.224382 4.50691 0.196963 4.02362L3.52495 0.224206C3.78693 -0.07517 4.21275 -0.07517 4.47277 0.224206L7.80272 4.02413C8.22462 4.50691 7.92518 5.33301 7.32657 5.33301Z"
                  fill="#E5642B"
                />
              </svg>
            )}
            <div className={classes.hr}></div>
          </div>
          {isRoadmapRecomOpen && (
            <div className={classes.recommendedItems}>
              <TodoItemsList
                isPersonalTodo={false}
                items={roadmapItems}
                loading={roadmapLoading}
                setItems={setRoadmapItems}
              />
            </div>
          )}
        </div>
      </div>
      <div
        className={`${classes.itemsContainer}`}
        style={
          !isPersonalOpen
            ? { marginTop: "10px" }
            : !isRoadmapOpen
            ? { marginTop: "60px", height: "fit-content" }
            : { height: "fit-content" }
        }
      >
        <TodoTitles
          title={"Personal To Do"}
          isPersonalTodo={true}
          setOpenModal={setOpenAddModal}
          isOpen={isPersonalOpen}
          setIsOpen={(val) => {
            setIsPersonalOpen(val);
          }}
        />
        <div
          className={`${classes.personalTodo}`}
          ref={ptRef}
          style={
            !isPersonalOpen
              ? { display: "none" }
              : personalItems.length === 0 && !isRoadmapOpen
              ? { height: "fit-content" }
              : personalItems.length === 0
              ? { height: "fit-content" }
              : {}
          }
        >
          <div
            style={isPersonaRecomlOpen ? { height: "50%" } : { height: "90%" }}
          >
            <TodoItemsList
              isPersonalTodo={true}
              items={personalItems}
              loading={personalLoading}
              setPersonalItems={setPersonalItems}
              setItems={setPersonalItems}
            />
          </div>
          <div
            className={classes.recommended}
            onClick={() => setIsPersonaRecomlOpen((prev) => !prev)}
          >
            <p style={{ color: "#16C6ED" }}> Recommended to do</p>{" "}
            {!isPersonaRecomlOpen && (
              <svg
                width="8"
                height="6"
                viewBox="0 0 8 6"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  opacity="0.6"
                  d="M0.673426 0H7.32661C7.92522 0 8.22438 0.826093 7.80304 1.30939L4.47505 5.1088C4.21307 5.40818 3.78725 5.40818 3.52723 5.1088L0.19728 1.30888C-0.224625 0.826094 0.0748188 0 0.673426 0Z"
                  fill="#16C6ED"
                />
              </svg>
            )}
            {isPersonaRecomlOpen && (
              <svg
                width="8"
                height="6"
                viewBox="0 0 8 6"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  opacity="0.6"
                  d="M7.32657 5.33301L0.673389 5.33301C0.0747814 5.33301 -0.224382 4.50691 0.196963 4.02362L3.52495 0.224206C3.78693 -0.07517 4.21275 -0.07517 4.47277 0.224206L7.80272 4.02413C8.22462 4.50691 7.92518 5.33301 7.32657 5.33301Z"
                  fill="#16C6ED"
                />
              </svg>
            )}
            <div
              className={classes.hr}
              style={{ borderColor: "#16C6ED" }}
            ></div>
          </div>
          {isPersonaRecomlOpen && (
            <div className={classes.recommendedItems}>
              <TodoItemsList
                isPersonalTodo={true}
                items={personalRecommended}
                loading={recommendedlLoading}
                recommended={true}
                setPersonalItems={setPersonalItems}
                setItems={setPersonalRecommended}
              />
            </div>
          )}
        </div>
      </div>

      {userCtx.token === null && (
        <div className={classes.signUpHome}>
          <img
            src={signUp}
            alt=""
            onClick={() => {
              navigate("/signup");
            }}
          />
        </div>
      )}

      <TabBar />

      {openAddModal && (
        <AddTodoModal
          onClose={() => setOpenAddModal(false)}
          onNext={() => {
            setOpenAddModal(false);
            setOpenDueModal(true);
          }}
          title={"Task Name"}
          setPersonalItems={setPersonalItems}
          addTodo={addPersonalTodoHandler}
          name={name}
          desc={desc}
          setName={setName}
          setDesc={setDesc}
        />
      )}
      {openDueModal && (
        <DueDateModal
          title={""}
          onBack={() => {
            setOpenAddModal(true);
            setOpenDueModal(false);
          }}
          onClose={() => {
            setOpenDueModal(false);
            setOpenAddModal(false);
          }}
          setPersonalItems={setPersonalItems}
          setPersonalLoading={setPersonalLoading}
          name={name}
          desc={desc}
          setName={setName}
          setDesc={setDesc}
        />
      )}
    </div>
  );
}

export default Home;
