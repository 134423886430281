import ExploreSingleItem from "../../ExploreSingleItem/ExploreSingleItem";
import styles from "./InnerVertical.module.css";

const InnerVertical = ({ items }) => {
  return (
    <div className={styles.container}>
      {items.map((item) => (
        <div key={item.id}>
          <ExploreSingleItem
            type="square2"
            roadmapId={item.id}
            pic={item.thumbnail}
            title={item.title}
            premium={item.premium}
          />
        </div>
      ))}
    </div>
  );
};

export default InnerVertical;
