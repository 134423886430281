import React from "react";
import Tree from "./Tree";

import classes from "./StepSection.module.css";
import arrowUp from "../../../../assets/images/Roadmap/icon/arrowUp.svg";

function StepSection(props) {
  const handleFullScreenChat = () => {
    props.setFullScreenChat();
  };

  return (
    <section
      className={
        props.show ? classes.stepSectionOpened : classes.stepSectionClosed
      }
      style={{ width: `${props.itemWidth - 0.5}px` }}
    >
      <Tree
        roadmapSetter={props.roadmapSetter}
        treeData={props.subSteps}
        isEnrolled={props.isEnrolled}
        started={props.started}
        handleStepDone={props.handleStepDone}
        setIsStepDone={props.setIsStepDone}
        dateRange={props.range && props.range}
        itemWidth={props.itemWidth}
        setFullScreenChat={handleFullScreenChat}
        registerCallback={props.registerCallback}
        setRoadmapModalOpen={props.setRoadmapModalOpen}
      />
      <div className={classes.cardFooterSection}></div>
      <div
        className={classes.cardPolygonSection}
        onClick={() => props.setShow((prev) => !prev)}
      >
        <div>
          <img src={arrowUp} alt="" />
        </div>
      </div>
    </section>
  );
}

export default StepSection;
