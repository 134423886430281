import React from 'react';

import classes from './Input.module.css'

const Input = props => {
    return (
        <div className={classes.container}>
            {props.source !== null && (
                <div style={{paddingRight: 8, position: 'absolute', left: '4%', top: '32%'}}>
                    <img
                        className={[
                            props.icon === 'email' && classes.emailIcon,
                            props.icon === 'password' && classes.passIcon,
                            props.icon === 'user' && classes.userIcon,
                            props.icon === 'code' && classes.codeIcon,
                        ]}
                        src={props.source}
                        alt={""}
                    />
                </div>
            )}
            <input
                type={props.type}
                className={classes.input}
                placeholder={props.placeholder}
                onChange={e => props.onChange(e.target.value)}
                value={props.value}

            />
        </div>
    );
};


export default Input;
