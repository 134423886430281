import React, { useEffect, useState } from "react";

import classes from "./AllRodamapTodos.module.css";
import AllTodos from "../AllTodos/AllTodos";
import AllTodosHeader from "../AllTodosHeader/AllTodosHeader";
import axiosInstance from "../../../request/axiosInstance";
import { getTodoExtraClass } from "../../../utils/Utils";

function AllRoadmapTodos() {
  const [roadmapLoading, setRoadmapLoading] = useState(true);
  const [roadmapItems, setRoadmapItems] = useState([]);

  useEffect(() => {
    axiosInstance
      .get("/roadrunner/roadmap-todo", {
        params: {
          filter: ["n", "m"],
        },
      })
      .then((res) => {
        const roadmapStepsTodo = res.data.map((step) => {
          const deadline = new Date(step.dueDateTo);
          const roadmapName = step.RoadmapEntity.Roadmap.title;
          const stepName = step.RoadmapEntity.title;
          const tasks = step.SubSteps.map((subtask) => {
            return {
              id: subtask.id,
              title: subtask.RoadmapEntity.title,
              isDone: subtask.status === "done",
              priority: subtask.priority,
            };
          });

          const extraClass = getTodoExtraClass(step.status, step.dueDateTo);

          return {
            id: step.id,
            name: roadmapName,
            step: stepName,
            url: `/user-roadmap/${step.userRoadmapId}`,
            time:
              step.dueDateTo !== null
                ? `${deadline.getDate()} ${deadline.toLocaleString("default", {
                    month: "short",
                  })}`
                : "",
            isDone: step.status === "done",
            tasks: tasks,
            extraClass: extraClass,
          };
        });

        setRoadmapItems(roadmapStepsTodo);
      })
      .finally(() => setRoadmapLoading(false));
  }, []);

  return (
    <div className={classes.container}>
      <AllTodosHeader title={"All Road-maps To Do"} />

      <AllTodos
        isPersonalTodo={false}
        setItems={setRoadmapItems}
        items={roadmapItems}
        loading={roadmapLoading}
        setRoadmapItems={setRoadmapItems}
        setRoadmapLoading={setRoadmapLoading}
      />
    </div>
  );
}

export default AllRoadmapTodos;
