import React, { useState } from "react";

import Checkbox from "react-custom-checkbox";
import axiosInstance from "../../../request/axiosInstance";

function RoadmapTodoSingleItem({
  arrayLength,
  priority,
  isTaskDone,
  borderColor,
  check,
  id,
  setStepDone,
  stepDone,
}) {
  const [isDone, setIsDone] = useState(isTaskDone);
  const [thisBorderColor, setThisBorderColor] = useState(
    isDone ? "#23CC85" : borderColor
  );

  const checkboxChange = () => {
    setIsDone((prev) => !prev);

    setThisBorderColor(isDone ? borderColor : "#23CC85");
    axiosInstance
      .patch(`/roadrunner/entity/update/${id}`, {
        status: isDone ? "pending" : "done",
      })
      .then((res) => {
        if (priority === arrayLength) {
          setStepDone(!stepDone);
        }
      });
  };
  return (
    <>
      <Checkbox
        size={10}
        checked={isDone}
        value={isDone}
        onChange={() => checkboxChange(isDone)}
        icon={<img src={check} style={{ width: 7, height: 5 }} alt="" />}
        borderColor={thisBorderColor}
        borderWidth={0.5}
        borderRadius={2}
        className={!isDone ? "checkbox-div" : ""}
        data-checkbox={!isDone ? "checkbox-div" : ""}
      />
    </>
  );
}

export default RoadmapTodoSingleItem;
