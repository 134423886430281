import React from "react";

import classes from "./Roadmapsteps.module.css";
import roadmap from "../../../../assets/images/Roadmap/icon/roadmapIcon.png";
import RoadmapSingleStep from "./RoadmapSingleStep";
import Stepper from "../../UserRoadmap/Stepper/Stepper";

function Roadmapsteps(props) {
  const handleFullScreenChat = () => {
    props.setFullScreenChat();
  };

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <img src={roadmap} alt="roadmap" />
        <h1>Roadmap</h1>
      </div>

      {props.isUserRoadmap && (
        <Stepper
          progress={props.progress}
          setRatingModalOpen={props.setRatingModalOpen}
        />
      )}
      {props.steps.map((step) => (
        <RoadmapSingleStep
          key={step.id}
          id={step.id}
          stepOpen={props.currId && props.currId === step.id}
          roadmap
          roadmapRefrence={
            step.RoadmapEntity
              ? step.RoadmapEntity.roadmapRefrence
              : step.roadmapRefrence
          }
          roadmapSetter={props.roadmapSetter}
          isUserRoadmap={props.isUserRoadmap}
          handleStepDone={props.handleStepDone}
          description={
            step.RoadmapEntity
              ? step.RoadmapEntity.description
              : step.description
          }
          name={step.RoadmapEntity ? step.RoadmapEntity.title : step.title}
          stepNum={step.priority}
          dueDateEstimation={
            step.RoadmapEntity
              ? Math.ceil(
                  (step.RoadmapEntity.minEstimation +
                    step.RoadmapEntity.maxEstimation) /
                    2
                )
              : Math.ceil((step.minEstimation + step.maxEstimation) / 2)
          }
          stepDuration={`${
            step.RoadmapEntity
              ? step.RoadmapEntity.minEstimation
              : step.minEstimation
          }-${
            step.RoadmapEntity
              ? step.RoadmapEntity.maxEstimation
              : step.maxEstimation
          } Weeks`}
          dueDateFrom={step.dueDateFrom}
          dueDateTo={step.dueDateTo}
          subSteps={step.SubSteps}
          isEnrolled={props.isEnrolled}
          started={props.started}
          type={step.type}
          status={step.status}
          setFullScreenChat={handleFullScreenChat}
          registerCallback={props.registerCallback}
          setRoadmapModalOpen={props.setRatingModalOpen}
        />
      ))}
    </div>
  );
}

export default Roadmapsteps;
