import ExploreSingleItem from "../../ExploreSingleItem/ExploreSingleItem";
import InnerVertical from "../InnerVertical/InnerVertical";
import styles from "./VerticalT2GridBased.module.css";

const VerticalT2GridBased = ({ items }) => {
  return (
    <div className={styles.container}>
      <InnerVertical items={items.slice(0, 2)} />
      <ExploreSingleItem
        type="vertical"
        roadmapId={items[2].id}
        pic={items[2].thumbnail}
        title={items[2].title}
        roadmapOwnre={items[2].creator.fullName}
        rate={items[2].averageRate}
        roadRunners={items[2].roadRunners}
        premium={items[2].premium}
      />
    </div>
  );
};

export default VerticalT2GridBased;
