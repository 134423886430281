import React from "react";

import classes from "./TodoItemsList.module.css";
import TodoItem from "../TodoItems/TodoItem";
import { Skeleton } from "antd";
import { Reorder } from "framer-motion";

function TodoItemsList(props) {
  return (
    <div className={classes.itemsContainer} {...props}>
      <Reorder.Group
        values={props.items}
        onReorder={props.setItems}
        className={classes.reorder}
      >
        {props.loading ? (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              width: "100%",
            }}
          >
            {" "}
            <Skeleton.Input
              active={true}
              style={{ width: "100%", height: "80px", borderRadius: "25px" }}
            />
            <Skeleton.Input
              active={true}
              style={{ width: "100%", height: "80px", borderRadius: "25px" }}
            />
            <Skeleton.Input
              active={true}
              style={{ width: "100%", height: "80px", borderRadius: "25px" }}
            />
            <Skeleton.Input
              active={true}
              style={{ width: "100%", height: "80px", borderRadius: "25px" }}
            />
            <Skeleton.Input
              active={true}
              style={{ width: "100%", height: "80px", borderRadius: "25px" }}
            />
          </div>
        ) : props.items.length > 0 ? (
          props.items.map((item) => (
            <Reorder.Item value={item} key={item.id}>
              <TodoItem
                id={item.id}
                title={item.title}
                isPersonalTodo={props.isPersonalTodo}
                time={item.time}
                deadline={item.deadline}
                repeatStartDate={item.repeatStartDate}
                repeatEndDate={item.repeatEndDate}
                description={item.description}
                extraClass={item.extraClass}
                roadmapTasks={item.tasks}
                roadmapName={item.name}
                roadmapStep={item.step}
                isSeeAll={props.isSeeAll}
                justAdded={item.justAdded}
                url={item.url}
                recommended={props.recommended || item.recommended}
                setPersonalItems={props.setPersonalItems}
              />
            </Reorder.Item>
          ))
        ) : (
          <p className={classes.nothingFound}>
            There are no tasks to do for today!
          </p>
        )}
      </Reorder.Group>
    </div>
  );
}

export default TodoItemsList;
