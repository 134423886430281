import React, { useState } from "react";
import styles from "./Rating.module.css";
import { ReactComponent as StarIcon } from "../../../../../assets/images/Roadmap/icon/ratingStarIcon.svg";

const Rating = ({ initialRating = 0, onRate }) => {
  const [rating, setRating] = useState(initialRating);

  const clickHandler = (rate) => {
    const newRating = rate === rating ? rate - 1 : rate;
    setRating(newRating);
    if (onRate) {
      onRate(newRating);
    }
  };

  return (
    <div className={styles.rating}>
      {[1, 2, 3, 4, 5].map((rate) => (
        <span key={rate} onClick={() => clickHandler(rate)}>
          <StarIcon
            fill={rate <= rating ? "rgba(255, 193, 7, 1)" : "white"}
            width={40}
            height={40}
          />
        </span>
      ))}
    </div>
  );
};

export default Rating;
