import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Button from "../../UI/Button";
import OnboardingCategory from "../OnboardingCategoryItem/OnboardingCategoryItem";

import classes from "./OnBoardingCurious.module.css";
import character from "../../../assets/images/curiousMessage.png";
import education from "../../../assets/images/education.png";
import fun from "../../../assets/images/fun.png";
import career from "../../../assets/images/career.png";
import relation from "../../../assets/images/relationships.png";
import presonalDev from "../../../assets/images/personalDev.png";
import health from "../../../assets/images/health.png";
import tech from "../../../assets/images/tech.png";
import explore from "../../../assets/images/explore.png";
import art from "../../../assets/images/art.png";
import finance from "../../../assets/images/finance.png";
import axios from "axios";

const icons = [
  { name: "Fun & Entertainment ", img: fun },
  { name: "Career Growth", img: career },
  { name: "Education", img: education },
  { name: "Relationships", img: relation },
  {
    name: "Personal Development",
    img: presonalDev,
  },
  { name: "Exploration", img: explore },
  { name: " Technology & Innovation", img: tech },
  { name: "Health & Fitness", img: health },
  { name: "Art and Design", img: art },
  { name: "Finance & Cryptocurrency", img: finance },
];

const api_url = process.env.REACT_APP_API_URL;

function OnboardingCurious() {
  const navigate = useNavigate();
  const location = useLocation();
  const { birthDate, name } = location.state;
  const [categories, setCategories] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [allowToProgress, setAllowToProgress] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    axios
      .get(api_url + "/onboarding/categories")
      .then((res) => {
        console.log(res.data);
        let all_categories = [];
        all_categories = res.data.map((category) => {
          return {
            id: category.id,
            name: category.name,
            img: icons[category.id - 1].img,
            selected: false,
          };
        });
        setCategories(all_categories);
      })
      .finally(() => setIsLoading(false));
  }, []);

  const categoryClicked = (e) => {
    setAllowToProgress(true);

    setCategories(
      categories.map((category) => {
        // eslint-disable-next-line eqeqeq
        return category.id == e.target.getAttribute("data-id")
          ? { ...category, selected: !category.selected }
          : category;
      })
    );
  };

  const handleSkip = () => {
    navigate("/");
  };

  const handleBack = () => {
    navigate(-1);
  };

  const handleNextClick = () => {
    if (allowToProgress) {
      navigate(`/onboarding/onboarding-intrests`, {
        state: {
          birthDate: birthDate,
          name: name,
          categories: categories
            .filter((category) => category.selected === true)
            .map((cat) => {
              return { id: cat.id, name: cat.name };
            }),
        },
      });
    }
  };

  return (
    <div className={classes.background}>
      <div className={classes.progress}>
        <hr />
        <svg
          onClick={handleBack}
          width="23"
          height="14"
          viewBox="0 0 23 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6.33554 0L0 6.91738L6.33554 13.8348L7.66158 12.3548L3.62452 7.94694H22.5427V5.88781H3.62452L7.66158 1.48L6.33554 0Z"
            fill="#010440"
            fill-opacity="0.7"
          />
        </svg>
      </div>
      <div className={classes.character}>
        <img src={character} alt="" />
      </div>

      {isLoading ? (
        <p>loading...</p>
      ) : (
        <div className={classes.categories}>
          {categories.map((category) => (
            <OnboardingCategory
              onClick={categoryClicked}
              id={category.id}
              key={category.id}
              name={category.name}
              img={category.img}
              selected={category.selected}
            />
          ))}
        </div>
      )}

      <div className={classes.control}>
        <button className={classes.skipButton} onClick={handleSkip}>
          Skip
        </button>
        <Button onClick={handleNextClick} style={{ width: "40%" }}>
          Next
        </Button>
      </div>
    </div>
  );
}

export default OnboardingCurious;
