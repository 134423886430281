import React from "react";
import { Progress, Upload } from "antd";

import classes from "./UploadTab.module.css";

import uploadIcon from "../../../../../assets/images/Roadmap/icon/uploadIcon.png";
import deleteUpload from "../../../../../assets/images/Roadmap/icon/deleteUpload.png";

function UploadTab(props) {
  const customRenderItem = (originNode, file, fileList, actions) => {
    return (
      <div
        className={classes.customUploadItem}
        style={{ margin: "8px 0 !important" }}
      >
        <div className={classes.uploadItemInfo}>
          <div
            style={{
              padding: "12px",
              display: "flex",
              gap: "12px",
              position: "relative",
            }}
          >
            <p className={classes.fileExt}>{file.name.split(".").pop()}</p>
            <Progress
              percent={file.status !== "uploading" ? 100 : file.percent}
              strokeLinecap="round"
              showInfo={false}
              type="circle"
              strokeWidth={5}
              strokeColor={"#16C6ED"}
              className={classes.uploadProgress}
              tip={<p>test</p>}
            />
            <span>{file.name}</span>
          </div>
          <div onClick={actions.remove} style={{ width: "7%", padding: "8px" }}>
            <img src={deleteUpload} alt="" style={{ width: "100%" }} />
          </div>
        </div>
      </div>
    );
  };

  return (
    <Upload
      name={`upload${props.entityId}`}
      action="https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload"
      headers={{
        authorization: "authorization-text",
      }}
      onChange={(info) => {
        if (info.file.status !== "uploading") {
          console.log(info.file, info.fileList);
        }
        if (info.file.status === "done") {
          console.log(`${info.file.name} file uploaded successfully`);
        } else if (info.file.status === "error") {
          console.log(`${info.file.name} file upload failed.`);
        }
      }}
      progress={{
        strokeLinecap: "round",
        strokeWidth: "8",
      }}
      itemRender={customRenderItem}
      // customRequest={dummyRequest}
      className={classes.upload}
    >
      <div className={classes.uploadBtn}>
        <img src={uploadIcon} alt="" />
        <p> Click to Upload</p>
      </div>
    </Upload>
  );
}

export default UploadTab;
