import { useParams } from "react-router-dom";
import OnboardingStart from "../components/Onboarding/OnboardingStart/OnboardingStart";
import OnboardingName from "../components/Onboarding/OnboardingName/OnboardingName";
import OnboardingAge from "../components/Onboarding/OnboardingAge/OnboardingAge";
import OnboardingCurious from "../components/Onboarding/OnboardingCurious/OnboardingCurious";
import OnboardingIntrests from "../components/Onboarding/OnboardingIntrests/OnboardingIntrests";
import Layout from "../components/UI/Layout/Layout";

const OnboardingPage = () => {
  const params = useParams();

  let currentOnboardingPage = <OnboardingStart />;

  switch (params.pageName) {
    case "onboarding-name":
      currentOnboardingPage = <OnboardingName />;
      break;
    case "onboarding-age":
      currentOnboardingPage = <OnboardingAge />;
      break;

    case "onboarding-curious":
      currentOnboardingPage = <OnboardingCurious />;
      break;
    case "onboarding-intrests":
      currentOnboardingPage = <OnboardingIntrests />;
      break;
    default:
      break;
  }

  return <Layout>{currentOnboardingPage}</Layout>;
};

export default OnboardingPage;
