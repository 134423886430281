import { useEffect, useState } from "react";
import axiosInstance from "../../../../request/axiosInstance";
import TabContentContainer from "../TabContentContainer/TabContentContainer";
import GridBasedContent from "../GridBasedContent/GridBasedContent";
import LoadingSkeleton from "../Skeleton/LoadingSkeleton";
import InfiniteScroll from "react-infinite-scroll-component";

const PopularContent = () => {
  const [explorItems, setExploreItems] = useState([]);
  const [page, setPage] = useState(2);
  const [hasMore, setHasMore] = useState(true);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    axiosInstance
      .get("/explore/popular", { params: { page: 1 } })
      .then((res) => {
        setExploreItems(res.data.data);
      })
      .catch((err) => console.log(err))
      .finally(() => setIsLoading(false));
  }, []);

  const fetchMoreData = () => {
    axiosInstance
      .get("/explore/popular", { params: { page: page } })
      .then((res) => {
        // eslint-disable-next-line eqeqeq
        if (res.data.page >= res.data.totalPages) {
          setHasMore(false);
        }
        setExploreItems((prev) => prev.concat(res.data.data));
        setPage((p) => p + 1);
      })
      .catch((err) => console.log(err));
  };

  const renderGridContent = () => {
    const contentChunks = [];

    for (let i = 0; i < explorItems.length; i += 2) {
      let chunk = explorItems.slice(i, i + 2);
      if (chunk.some((item) => item.blockType === "divider")) {
        contentChunks.push(
          <GridBasedContent key={i + 1} items={[explorItems[i]]} row={1} />
        );
      } else {
        contentChunks.push(<GridBasedContent key={i + 3} items={chunk} />);
      }
    }
    return contentChunks;
  };

  return (
    <TabContentContainer>
      {isLoading && <LoadingSkeleton />}
      <InfiniteScroll
        dataLength={explorItems.length}
        next={fetchMoreData}
        hasMore={hasMore}
        loader={<LoadingSkeleton />}
        style={{ height: "100%" }}
        scrollThreshold={0.02}
      >
        {renderGridContent()}
        {<GridBasedContent items={[]} />}
      </InfiniteScroll>
    </TabContentContainer>
  );
};

export default PopularContent;
