import { useCallback, useRef, useState } from "react";

const useLongPress = (
  onLongPress,
  onClick,
  {
    shouldPreventDefault = true,
    delay = 300,
    excludedElements = [],
    excludedClasses = [],
    excludedAttributes = [],
  } = {}
) => {
  const [longPressTriggered, setLongPressTriggered] = useState(false);
  const timeout = useRef(null);
  const target = useRef(null);

  const start = useCallback(
    (event) => {
      const targetElement = event.target;
      console.log(targetElement.tagName);
      if (
        excludedElements.includes(targetElement.tagName) ||
        excludedClasses.some((cls) => targetElement.classList.contains(cls)) ||
        excludedAttributes.some((attr) => targetElement.hasAttribute(attr))
      ) {
        return;
      }

      if (shouldPreventDefault && event.target) {
        event.target.addEventListener("touchend", preventDefault, {
          passive: false,
        });
        target.current = event.target;
      }
      timeout.current = setTimeout(() => {
        onLongPress(event);
        setLongPressTriggered(true);
      }, delay);
    },
    [onLongPress, delay, shouldPreventDefault, excludedElements]
  );

  const clear = useCallback(
    (event, shouldTriggerClick = true) => {
      timeout.current && clearTimeout(timeout.current);
      if (shouldPreventDefault && target.current) {
        target.current.removeEventListener("touchend", preventDefault);
      }
      if (shouldTriggerClick && !longPressTriggered) {
        onClick(event);
      }
      setLongPressTriggered(false);
    },
    [onClick, shouldPreventDefault, longPressTriggered]
  );

  return {
    onMouseDown: (e) => start(e),
    onTouchStart: (e) => start(e),
    onMouseUp: (e) => clear(e, true),
    onMouseLeave: (e) => clear(e, false),
    onTouchEnd: (e) => clear(e, true),
  };
};

const isTouchEvent = (event) => "touches" in event;

const preventDefault = (event) => {
  if (!isTouchEvent(event)) return;

  if (event.touches.length < 2 && event.preventDefault) {
    event.preventDefault();
  }
};

export default useLongPress;
