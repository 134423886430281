import React from "react";

import classes from "./AddTodoModal.module.css";
import date from "../../../assets/images/Home/icons/stepDate.png";
import send from "../../../assets/images/Home/icons/send.png";

function AddTodoModal(props) {
  return (
    <div id="myModal" className={classes.modal}>
      <div className={classes["modal-content"]}>
        <div className={classes["modal-header"]}>
          <span className={classes.close} onClick={props.onClose}>
            &times;
          </span>
        </div>

        <div className={classes["modal-body"]}>
          <input
            className={classes.nameInput}
            value={props.name}
            onChange={(e) => props.setName(e.target.value)}
            type="text"
            placeholder="Task Name"
          />
          <input
            className={classes.descInput}
            name={props.desc}
            onChange={(e) => props.setDesc(e.target.value)}
            type="text"
            placeholder="Description"
          />
        </div>

        <div className={classes["modal-footer"]}>
          <div className={classes.dueDate} onClick={props.onNext}>
            <img src={date} alt="" /> <p>Deadline</p>
          </div>
          <div className={classes.send}>
            <img
              src={send}
              alt=""
              onClick={() => {
                props.setPersonalItems((prev) => {
                  return [
                    ...prev,
                    {
                      id: Math.random(),
                      title: props.name,
                      description: props.desc,
                      time: null,
                      extraClass: "nodate",
                    },
                  ];
                });
                props.addTodo();
                props.setDesc("");
                props.setName("");
                props.onClose();
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddTodoModal;
