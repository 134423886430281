import React, { useContext } from "react";
import Button from "../components/UI/Button";
import UserContext from "../store/user-context";
import { useNavigate } from "react-router-dom";
import TabBar from "../components/UI/TabBar/TabBar";

function Logout() {
  const userCtx = useContext(UserContext);
  const navigate = useNavigate();
  return (
    <div style={{ marginTop: "50px" }}>
      <Button onClick={() => userCtx.logOut(() => navigate("/explore"))}>
        log out
      </Button>
      <TabBar />
    </div>
  );
}

export default Logout;
