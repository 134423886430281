import { DeepChat } from "deep-chat-react";

import avatar from "../../../../../assets/images/authChar.png";

const history = [
  { role: "user", text: "Hey, how are you today?" },
  { role: "ai", text: "I am doing very well!" },
];

const Chat = (props) => {
  return (
    <DeepChat
      demo={true}
      {...props}
      textInput={{
        placeholder: { text: "Message Hopee" },
        styles: {
          container: {
            border: "1px solid #CCC2DC",
            borderRadius: "10px",
            padding: "8px",
            display: "flex",
            maxHeight: "100px",
          },
        },
      }}
      submitButtonStyles={{
        submit: {
          svg: {
            styles: {
              default: {
                fill: "#625B71",
                filter:
                  "brightness(0) saturate(100%) invert(32%) sepia(0%) saturate(924%) hue-rotate(46deg) brightness(95%) contrast(99%);",
                rotate: "45deg",
              },
            },
          },
          container: {
            default: { height: "2.65rem" },
          },
        },
      }}
      history={history}
      avatars={{
        ai: {
          src: avatar,
          styles: {
            avatar: { width: props.avatarWidth, height: props.avatarHeight },
          },
        },
        user: { styles: { avatar: { display: "none" } } },
      }}
      messageStyles={{
        default: {
          user: {
            bubble: {
              backgroundColor: "#16C6ED",
              color: "#010440B2",
              padding: "11px",
            },
          },
          ai: {
            bubble: {
              backgroundColor: "#FFFFFF",
              color: "#010440B2",
              boxShadow:
                "rgba(255, 255, 255, 0.35) 1px 0px 66px 58px inset, rgba(0, 0, 0, 0.1) 3px 3px 38.5px 12px, rgba(0, 0, 0, 0.1) 0px 9px 28.5px 0px inset, rgba(0, 0, 0, 0.1) 0px -6px 6.8px 0px inset, rgba(0, 0, 0, 0.1) 8px 0px 20.7px 0px inset, rgb(255, 255, 255) 0px 4px 63px 0px inset, rgb(255, 255, 255) 4px 2px 61px 0px inset",
              padding: "11px",
              border: "1px solid #9B9B9B8A",
            },
          },
        },
      }}
    />
  );
};

export default Chat;
