import React, { useState } from "react";

import classes from "./RepeatPicker.module.css";
import repeat from "../../../assets/images/Home/icons/repeat.png";
import cancel from "../../../assets/images/Home/icons/cancle.png";
import { ConfigProvider, Switch } from "antd";

function RepeatPicker({ onRepeatChange, setOpenRepeatModal }) {
  const [isOpen, setIsOpen] = useState(false);
  const [repeatChecked, setRepeatChecked] = useState(false);
  const [reapteVal, setRepeatVal] = useState(null);

  const togglePopup = () => {
    setIsOpen(!isOpen);
  };

  const onClickHandler = (val) => {
    setRepeatVal(val);

    onRepeatChange(val);

    setIsOpen(false);
  };

  return (
    <div className={classes["time-picker"]}>
      <div className={classes["input-container"]}>
        <img src={repeat} className={classes["left-icon"]} alt="left icon" />
        <input type="text" value={reapteVal} placeholder="Repeat" readOnly />
        <div className={classes.timeBadge} onClick={togglePopup}>
          {reapteVal ? reapteVal : "No Repeat"}
        </div>
        <ConfigProvider
          theme={{
            components: {
              Switch: {
                handleBg: repeatChecked ? "#16C6ED" : "#9B9B9B80",
              },
            },
          }}
        >
          <Switch
            size="small"
            className={classes["right-icon"]}
            value={repeatChecked}
            onChange={(val) => {
              setRepeatChecked(val);
              setRepeatVal((prev) => (val === true ? prev : "no repeat"));
            }}
          />
        </ConfigProvider>
      </div>
      {isOpen && (
        <div className={classes["time-popup"]}>
          <div className={classes.cancelButton}>
            <img src={cancel} onClick={() => setIsOpen(false)} alt="cancel" />
          </div>
          <div className={classes.repeatVals}>
            <p onClick={() => onClickHandler("daily")}>Daily</p>
            <p onClick={() => onClickHandler("weekly")}>Weekly</p>
            <p onClick={() => onClickHandler("monthly")}>Monthly</p>
            <p
              onClick={() => {
                setOpenRepeatModal(true);
                onClickHandler("custom");
              }}
            >
              Custom
            </p>
          </div>
        </div>
      )}
    </div>
  );
}

export default RepeatPicker;
