import { useEffect, useState, useContext } from "react";
import axiosInstance from "../../../../request/axiosInstance";
import TabContentContainer from "../TabContentContainer/TabContentContainer";
import GridBasedContent from "../GridBasedContent/GridBasedContent";
import UserContext from "../../../../store/user-context";
import LoadingSkeleton from "../Skeleton/LoadingSkeleton";
import InfiniteScroll from "react-infinite-scroll-component";

const ForYouContent = () => {
  const [explorItems, setExploreItems] = useState([]);
  const [page, setPage] = useState(2);
  const [totalPages, setTotalPages] = useState(10);
  const userCtx = useContext(UserContext);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let categoryIds = [1];
    let interestIds = [1];

    if (userCtx.user.selectedCategories && userCtx.user.selectedInterests) {
      categoryIds = userCtx.user.selectedCategories.map((cat) => cat.id);
      interestIds = userCtx.user.selectedInterests.map(
        (interest) => interest.id
      );
    } else if (userCtx.user.selectedCategories) {
      categoryIds = userCtx.user.selectedCategories.map((cat) => cat.id);
      interestIds = [-1];
    }

    axiosInstance
      .post("/explore/query", { categoryIds, interestIds, Page: page })
      .then((res) => {
        setExploreItems(res.data.data);
        setTotalPages(res.data.totalPages);
      })
      .finally(() => setLoading(false));
  }, [userCtx.user.selectedCategories, userCtx.user.selectedInterests]);

  const fetchMoreData = () => {
    let categoryIds = [1];
    let interestIds = [1];
    if (userCtx.user.selectedCategories && userCtx.user.selectedInterests) {
      categoryIds = userCtx.user.selectedCategories.map((cat) => cat.id);
      interestIds = userCtx.user.selectedInterests.map(
        (interest) => interest.id
      );
    } else if (userCtx.user.selectedCategories) {
      categoryIds = userCtx.user.selectedCategories.map((cat) => cat.id);
      interestIds = [-1];
    }
    axiosInstance
      .post("/explore/query", { categoryIds, interestIds, Page: page })
      .then((res) => {
        setExploreItems((prev) => [...prev, res.data.data]);
        setPage((p) => p + 1);
      });
  };

  const renderGridContent = () => {
    const contentChunks = [];

    for (let i = 0; i < explorItems.length; i += 2) {
      let chunk = explorItems.slice(i, i + 2);
      if (chunk.some((item) => item.blockType === "divider")) {
        contentChunks.push(
          <GridBasedContent key={i} items={[explorItems[i]]} row={1} />
        );
      } else {
        contentChunks.push(<GridBasedContent key={i} items={chunk} />);
      }
    }
    return contentChunks;
  };

  return (
    <TabContentContainer>
      {loading && <LoadingSkeleton />}
      <InfiniteScroll
        dataLength={explorItems.length}
        next={fetchMoreData}
        hasMore={totalPages >= page}
        loader={<LoadingSkeleton />}
        style={{ height: "100%" }}
        scrollThreshold={0.02}
      >
        {renderGridContent()}
        {<GridBasedContent items={[]} />}
      </InfiniteScroll>
    </TabContentContainer>
  );
};

export default ForYouContent;
