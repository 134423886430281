import React from "react";
import { calculateDateRange } from "../../../../../utils/Utils";

import time from "../../../../../assets/images/Roadmap/icon/time.png";
import calender from "../../../../../assets/images/Roadmap/icon/stepDate.png";

function DateInfo(props) {
  let condition = props.condition;

  return (
    <p>
      {props.isRoadmap ? (
        <span style={condition ? { width: "20%" } : { width: "19%" }}>
          <img src={props.urCondition ? calender : time} alt="" />{" "}
        </span>
      ) : (
        <span style={condition ? { width: "5%" } : {}}>
          <img src={props.urCondition ? calender : time} alt="" />{" "}
        </span>
      )}
      {props.isUserRoadmap
        ? calculateDateRange(
            { openedOnce: true },
            props.range,
            props.stepDuration
          )
        : calculateDateRange(
            props.showCalender,
            props.range,
            props.stepDuration
          )}
    </p>
  );
}

export default DateInfo;
