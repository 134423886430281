import styles from "./AuthTitle.module.css";
import signupChar from "../../assets/images/signupChar.png";
import authChar from "../../assets/images/authChar.png";
import {Link} from "react-router-dom";

const AuthTitle = (props) => {
    return (
        <div className={styles.container}>
            {props.pageType === "signup" ? (
                <img src={signupChar} className={styles.signupChar} alt="character"/>
            ) : (
                <img src={authChar} className={styles.authChar} alt="character"/>
            )}
            <p className={styles.title}>{props.title}</p>
            {props.pageType === "signup" && (
                <div className={styles.questionContainer}>
                    <p className={styles.question}>Already have an account?</p>
                    <Link className={styles.loginLink} to={props.to}>
                        Log In
                    </Link>
                </div>
            )}
            {props.pageType === "login" && (
                <div className={styles.questionContainer}>
                    <p className={styles.question}>New?</p>
                    <Link className={styles.signupLink} to={props.to}>
                        Create New Account
                    </Link>
                </div>
            )}
            {props.pageType === "confirm" && (
                <div className={styles.questionContainer}>
                    <p className={styles.txt}>Enter code we sent to {props.email}</p>
                </div>
            )}
            {props.pageType === "createPass" && (
                <div className={styles.questionContainer}>
                    <p className={styles.txt}>Let's set your Password!</p>
                </div>
            )}
            {props.pageType === "newPass" && (
                <div className={styles.questionContainer}>
                    <p className={styles.txt}>Enter your new password</p>
                </div>
            )}
        </div>
    );
};

export default AuthTitle;
