import React from "react";
import Layout from "../components/UI/Layout/Layout";
import { useParams } from "react-router-dom";
import LogIn from "../components/LogIn/LogIn/LogIn";
import ForgetPassword from "../components/LogIn/ForgetPass/ForgetPassword";
import FrogetPasswordVerification from "../components/LogIn/ForgetPassVerificationCode/ForgetPassVerification";
import SetNewPassword from "../components/LogIn/SetNewPass/SetNewPassword";

function LoginPage() {
  const params = useParams();

  let currentPage = <LogIn />;

  switch (params.pageName) {
    case "forgetpassword":
      currentPage = <ForgetPassword />;
      break;
    case "verification":
      currentPage = <FrogetPasswordVerification />;
      break;
    case "newPass":
      currentPage = <SetNewPassword />
      break;
    default:
      break;
  }

  return <Layout>{currentPage}</Layout>;
}

export default LoginPage;
