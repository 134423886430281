import React from "react";

import classes from "./OnboardingIntrestItem.module.css";

function OnboardingIntrestItem(props) {
  let subClass = classes.education;

  switch (props.category.name) {
    case "Fun & Entertainment ":
      subClass = classes.fun;
      break;
    case "Career Growth":
      subClass = classes.career;
      break;
    case "Education":
      subClass = classes.education;
      break;
    case "Relationships":
      subClass = classes.realtion;
      break;
    case "Personal Development":
      subClass = classes.presonalDev;
      break;
    case "Exploration":
      subClass = classes.explore;
      break;
    case " Technology &  Innovation":
      subClass = classes.tech;
      break;
    case "Health  & Fitness":
      subClass = classes.health;
      break;
    case "Art and Design":
      subClass = classes.art;
      break;
    case "Finance & Cryptocurrency":
      subClass = classes.finance;
      break;

    default:
      break;
  }

  return (
    <div className={classes.subCatsContainer}>
      <h1>{props.category.name}</h1>
      <div className={classes.subCats}>
        {props.category.subCat
          .filter((subCat) => subCat !== null)
          .map((subCat) => {
            return (
              <p
                key={subCat.name}
                data-id={subCat.id}
                onClick={props.onClick}
                className={`${subClass} ${
                  subCat.selected ? classes.selected : ""
                }`}
              >
                {subCat.name}
              </p>
            );
          })}
      </div>
    </div>
  );
}

export default OnboardingIntrestItem;
