import { useState } from "react";
import { Avatar } from "antd";
import { useNavigate } from "react-router-dom";
import Popup from "../../Popup/Popup";

import styles from "./HeaderButtons.module.css";
import backButton from "../../../../../assets/images/Roadmap/icon/Vector.png";
import moreButton from "../../../../../assets/images/Roadmap/icon/more.png";
import hoperise from "../../../../../assets/images/Roadmap/Profiles/hoperise.png";
import anna from "../../../../../assets/images/Roadmap/Profiles/anna.png";
import henna from "../../../../../assets/images/Roadmap/Profiles/henna.png";

const HeaderButtons = () => {
  const avatars = [
    {
      src: hoperise,
      initial: "H",
      title: "Hoperise Groupe",
      assistiveText: "Hoperise Groupe",
    },
    // {
    //   src: anna,
    //   initial: "A",
    //   title: "Anna",
    //   assistiveText: "Anna",
    // },
    // {
    //   src: henna,
    //   initial: "L",
    //   title: "LHenna",
    //   assistiveText: "LHenna",
    // },
  ];

  const navigate = useNavigate();
  const [isMorePopupOpen, setIsMorePopupOpen] = useState(false);
  const [isAvatarsPopupOpen, setIsAvatarsPopupOpen] = useState(false);

  const backHandler = () => {
    navigate(-1);
  };

  return (
    <div className={styles.headerButtonsContainer}>
      <div className={styles.backButtonContainer}>
        <img
          src={backButton}
          className={styles.backButton}
          onClick={backHandler}
          alt=""
        />
        <div
          className={styles.avatarsContainer}
          onClick={() => setIsAvatarsPopupOpen(!isAvatarsPopupOpen)}
        >
          <Avatar.Group>
            {avatars.map((avatar) => (
              <Avatar src={avatar.src} size="small" />
            ))}
          </Avatar.Group>
          <p>
            {avatars.length > 1
              ? `${avatars[0].title} With ${avatars.length - 1} Others`
              : `${avatars[0].title}`}
          </p>
        </div>
        <div className={styles.avatarsPopupContainer}>
          <Popup
            show={isAvatarsPopupOpen}
            onClose={() => setIsAvatarsPopupOpen(!isAvatarsPopupOpen)}
            avatars={avatars}
          />
        </div>
      </div>
      <div className={styles.moreButtonContainer}>
        <img
          src={moreButton}
          className={styles.moreButton}
          onClick={() => setIsMorePopupOpen(!isMorePopupOpen)}
          alt=""
        />
      </div>
      <div className={styles.morePopupContainer}>
        <Popup
          show={isMorePopupOpen}
          onClose={() => setIsMorePopupOpen(!isMorePopupOpen)}
          morePopup={true}
        />
      </div>
    </div>
  );
};

export default HeaderButtons;
