import React, { useContext, useState } from "react";
import Button from "../../UI/Button";
import Input from "../../UI/Input";
import AuthTitle from "../../UI/AuthTitle";
import UserContext from "../../../store/user-context";
import ErrorMessage from "../../UI/ErrorMessage";
import { isValidPassword, isUnequalPass } from "../../../utils/Utils";
import classes from "./SetNewPassword.module.css";
import pass from "../../../assets/images/icon/password.png";
import { useLocation, useNavigate } from "react-router-dom";

const SetNewPassword = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [enteredPass, setEnteredPass] = useState("");
  const [enteredPassConfirmation, setEnteredPassConfirmation] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const { email, otp } = location.state;

  const userCtx = useContext(UserContext);

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <div className={classes.container}>
      <div className={classes.backButton}>
        <svg
          onClick={handleBack}
          width="23"
          height="14"
          viewBox="0 0 23 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6.33554 0L0 6.91738L6.33554 13.8348L7.66158 12.3548L3.62452 7.94694H22.5427V5.88781H3.62452L7.66158 1.48L6.33554 0Z"
            fill="#010440"
            fill-opacity="0.7"
          />
        </svg>
      </div>

      <div className={classes.authContainer}>
        <div>
          <AuthTitle title="New Password" pageType="newPass" />
          <div className={classes.inputContainer}>
            <Input
              placeholder="Password"
              source={pass}
              icon={"password"}
              secureTextEntry={true}
              value={enteredPass}
              type={"password"}
              onChange={(pass) => setEnteredPass(pass)}
            />
            <Input
              placeholder="Password Confirmation"
              source={pass}
              icon={"password"}
              secureTextEntry={true}
              type={"password"}
              value={enteredPassConfirmation}
              onChange={(pass) => setEnteredPassConfirmation(pass)}
            />
          </div>
          {error && <ErrorMessage message={error} />}
        </div>
        <div className={classes.buttonContainer}>
          <Button
            isLoading={isLoading}
            onClick={() => {
              if (isUnequalPass(enteredPass, enteredPassConfirmation)) {
                setError("Passwords don't match. Please check and try again.");
              } else if (isValidPassword(enteredPass)) {
                userCtx.updatePassword(
                  email,
                  enteredPass,
                  otp,
                  (val) => setIsLoading(val),
                  () => navigate("/login")
                );
              } else {
                setError(
                  "Your password needs to be at least 8 characters long and include uppercase letters, lowercase letters, and numbers."
                );
              }
            }}
          >
            Sign In
          </Button>
        </div>
      </div>
    </div>
  );
};

export default SetNewPassword;
