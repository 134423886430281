import styles from "./SquarGridBased.module.css";
import ExploreSingleItem from "../../ExploreSingleItem/ExploreSingleItem";

const SquarGridBased = ({ items }) => {
  return (
    <div className={styles.container}>
      <ExploreSingleItem
        type="square"
        roadmapId={items[0].id}
        pic={items[0].thumbnail}
        title={items[0].title}
        roadmapOwnre={items[0].creator.fullName}
        rate={items[0].averageRate}
        roadRunners={items[0].roadRunners}
        premium={items[0].premium}
      />
    </div>
  );
};

export default SquarGridBased;
