import { useState } from "react";
import axios from "axios";

const api_url = process.env.REACT_APP_API_URL;

export default function useRequest() {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const get = async (url, headers = {}, callback = () => {}) => {
    setLoading(true);
    try {
      const res = await axios.get(api_url + url, headers);
      setData(res.data);
      callback(res.data);
      setError(null);
    } catch (err) {
      handleApiError(err);
    } finally {
      setLoading(false);
    }
  };

  const post = async (url, body, headers = {}, callback = () => {}) => {
    setLoading(true);
    try {
      const res = await axios.post(api_url + url, body, headers);
      setData(res.data);
      callback(res.data)
      setError(null);
    } catch (err) {
      handleApiError(err);
    } finally {
      setLoading(false);
    }
  };

  const patch = async (url, body, headers = {}) => {
    setLoading(true);
    try {
      const res = await axios.patch(api_url + url, body, headers);
      setData(res.data);
      setError(null);
    } catch (err) {
      handleApiError(err);
    } finally {
      setLoading(false);
    }
  };

  const del = async (url, headers = {}) => {
    setLoading(true);
    try {
      const res = await axios.delete(api_url + url, headers);
      setData(res.data);
      setError(null);
    } catch (err) {
      handleApiError(err);
    } finally {
      setLoading(false);
    }
  };

  const handleApiError = (err) => {
    setData(null);
    if (err.response) {
      setError(err.response.data);
    } else if (err.request) {
      setError("No response received");
    } else {
      setError(err.message);
    }
  };
  return { data, loading, error, get, post, patch, del };
}
