import React, { useState, useRef, useEffect } from "react";
import classes from "./TimePicker.module.css";
import cancel from "../../../assets/images/Home/icons/cancle.png";
import clock from "../../../assets/images/Home/icons/time.png";
import { ConfigProvider, Switch } from "antd";

const TimePicker = ({ onTimeChange }) => {
  const [time, setTime] = useState("00:00");
  const [isOpen, setIsOpen] = useState(false);
  const [timeChecked, setTimeChecked] = useState(false);
  const [hour, setHour] = useState("00");
  const [minute, setMinute] = useState("00");
  const timePickerRef = useRef(null);
  const hourColumnRef = useRef(null);
  const minuteColumnRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        timePickerRef.current &&
        !timePickerRef.current.contains(event.target)
      ) {
        setIsOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (hour && minute) {
      const selectedTime = `${hour}:${minute}`;
      setTime(selectedTime);
      if (timeChecked) {
        onTimeChange(selectedTime);
      } else {
        onTimeChange(null);
      }
    }
  }, [hour, minute, onTimeChange, timeChecked]);

  const togglePopup = () => {
    setIsOpen(!isOpen);
  };

  const handleHourChange = (e) => {
    setHour(e.target.textContent);
  };

  const handleMinuteChange = (e) => {
    setMinute(e.target.textContent);
  };

  const generateOptions = (start, end) => {
    const options = [];
    for (let i = start; i <= end; i++) {
      options.push(i < 10 ? `0${i}` : `${i}`);
    }
    return options;
  };

  const hours = generateOptions(0, 23);
  const minutes = generateOptions(0, 59);

  return (
    <div ref={timePickerRef} className={classes["time-picker"]}>
      <div className={classes["input-container"]}>
        <img src={clock} className={classes["left-icon"]} alt="left icon" />
        <input type="text" value={time} placeholder="Time" readOnly />
        <div className={classes.timeBadge} onClick={togglePopup}>
          {time}
        </div>
        <ConfigProvider
          theme={{
            components: {
              Switch: {
                handleBg: timeChecked ? "#16C6ED" : "#9B9B9B80",
              },
            },
          }}
        >
          <Switch
            size="small"
            className={classes["right-icon"]}
            checked={timeChecked}
            onChange={(val) => setTimeChecked(val)}
          />
        </ConfigProvider>
      </div>
      {isOpen && (
        <div className={classes["time-popup"]}>
          <div className={classes.cancelButton}>
            <img src={cancel} onClick={() => setIsOpen(false)} alt="cancel" />
          </div>
          <div className={classes["time-columns"]}>
            <div ref={hourColumnRef} className={classes["time-column"]}>
              {hours.map((h) => (
                <div
                  key={h}
                  className={`${classes["time-option"]} ${
                    hour === h ? classes["selected"] : ""
                  }`}
                  onClick={handleHourChange}
                >
                  {h}
                </div>
              ))}
            </div>
            <div ref={minuteColumnRef} className={classes["time-column"]}>
              {minutes.map((m) => (
                <div
                  key={m}
                  className={`${classes["time-option"]} ${
                    minute === m ? classes["selected"] : ""
                  }`}
                  onClick={handleMinuteChange}
                >
                  {m}
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default TimePicker;
