import React, { useState } from "react";
import useLongPress from "./useLongPress";

import classes from "./TodoItme.module.css";
import Checkbox from "react-custom-checkbox";
import check from "../../../assets/images/Roadmap/icon/checkIcon.png";
import axiosInstance from "../../../request/axiosInstance";
import RoadmapTodoSingleItem from "./RoadmapTodoSingleItem";
import { useNavigate } from "react-router-dom";
import { Button, Popconfirm } from "antd";
import InfoModal from "./InfoModal/InfoModal";

function TodoItem(props) {
  const [isInfoOpen, setIsInfoOpen] = useState(false);
  const [isDone, setIsDone] = useState(
    props.extraClass === "completed" ? true : false
  );
  const navigate = useNavigate();
  const [extraClass, setExtraClass] = useState(props.extraClass);

  const onLongPress = () => {
    if (!props.isPersonalTodo) {
      setIsInfoOpen(true);
    }
  };

  const onClick = () => {
    console.log("click is triggered");
  };

  const longPressEvent = useLongPress(onLongPress, onClick, {
    shouldPreventDefault: true,
    delay: 500,
    excludedElements: ["P", "IMG", "path", "svg"],
    excludedClasses: ["checkbox-div"],
    excludedAttributes: ["data-checkbox"],
  });

  const checkboxChange = () => {
    setIsDone((perv) => !perv);
    setExtraClass((prev) => (prev !== "completed" ? "completed" : "upcoming"));

    if (props.isPersonalTodo) {
      axiosInstance
        .patch(`/personal-todo/${props.id}`, {
          status: isDone ? "pending" : "done",
        })
        .then((res) => console.log(res.data));
    }
  };
  let borderColor = "#FF7A0080";
  switch (extraClass) {
    case "future":
      borderColor = "#77B7FF";
      break;
    case "completed":
      borderColor = "#23CC85";
      break;
    case "nodate":
      borderColor = "#B7BFFE";
      break;
    default:
      break;
  }

  const clickHandler = () => {
    if (!props.isPersonalTodo) {
      navigate(`${props.url}#${props.id}`, {
        state: {
          currId: props.id,
        },
      });
    }
  };

  const addRecomHandler = () => {
    let extraClass = "nodate";

    props.setPersonalItems((prev) => [
      ...prev,
      {
        id: props.id,
        title: props.title,
        time: "",
        deadline: props.deadline,
        repeatStartDate: props.repeatStartDate,
        repeatEndDate: props.repeatEndDate,
        description: props.description,
        extraClass: extraClass,
        recommended: true,
        justAdded: true,
      },
    ]);
  };

  const removeRecomHandler = () => {
    props.setPersonalItems((prev) => {
      return prev.filter((item) => item.id !== props.id);
    });
  };

  return (
    <>
      {" "}
      <div
        className={`${classes.container} ${!isDone &&
          classes[`${extraClass}`]} ${isDone && classes.completed}`}
        {...longPressEvent}
      >
        {(props.isPersonalTodo || props.isSeeAll) &&
          props.extraClass !== "nodate" && (
            <div
              className={`${classes.badge} ${classes[`badge-${extraClass}`]}`}
            >
              <p>{props.time}</p>
            </div>
          )}
        {!props.isPersonalTodo && (
          <Popconfirm
            placement="top"
            title={""}
            description={`${props.roadmapName} > ${props.roadmapStep}`}
            showCancel={false}
            icon={null}
            okButtonProps={{
              style: {
                display: "none",
              },
            }}
          >
            <Button className={classes.headerContainer}>
              <div className={classes.roadmapAddress}>
                <p>
                  {props.roadmapName} &gt; {props.roadmapStep}
                </p>
              </div>
            </Button>
          </Popconfirm>
        )}
        {props.roadmapTasks &&
          props.roadmapTasks.map((task) => (
            <div className={classes.mainContainer} key={task.id}>
              <RoadmapTodoSingleItem
                borderColor={borderColor}
                check={check}
                arrayLength={props.roadmapTasks.length}
                priority={task.priority}
                setStepDone={setIsDone}
                stepDone={isDone}
                isTaskDone={task.isDone}
                id={task.id}
              />
              <p
                className={classes.title}
                onClick={clickHandler}
                style={task.isDone || isDone ? { color: "#8D8D8D" } : {}}
              >
                {task.title}
              </p>
            </div>
          ))}
        {props.isPersonalTodo && (
          <>
            <div className={classes.mainContainer}>
              <Checkbox
                size={10}
                checked={isDone}
                value={isDone}
                onChange={() => checkboxChange()}
                icon={
                  <img src={check} style={{ width: 7, height: 5 }} alt="" />
                }
                borderColor={borderColor}
                borderWidth={0.5}
                borderRadius={2}
                className={!isDone ? "checkbox-div" : ""}
                data-checkbox={!isDone ? "checkbox-div" : ""}
              />
              <p className={classes.title}>{props.title}</p>
              {props.recommended &&
                (props.justAdded ? (
                  <div
                    className={classes.addRecom}
                    onClick={removeRecomHandler}
                  >
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M8 0C3.582 0 0 3.582 0 8C0 12.418 3.582 16 8 16C12.418 16 16 12.418 16 8C16 3.582 12.418 0 8 0ZM12 8.66667H8.66667C8.37429 8.66667 8.368 8.66667 8 8.66667C7.632 8.66667 7.6 8.66667 7.33333 8.66667H4C3.632 8.66667 3.33333 8.36867 3.33333 8C3.33333 7.63133 3.632 7.33333 4 7.33333H7.33333C7.63307 7.33333 7.632 7.33333 8 7.33333C8.368 7.33333 8.37319 7.33333 8.66667 7.33333H12C12.368 7.33333 12.6667 7.63133 12.6667 8C12.6667 8.36867 12.368 8.66667 12 8.66667Z"
                        fill="#79747E"
                      />
                    </svg>
                  </div>
                ) : (
                  <div className={classes.addRecom} onClick={addRecomHandler}>
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M8 0C3.582 0 0 3.582 0 8C0 12.418 3.582 16 8 16C12.418 16 16 12.418 16 8C16 3.582 12.418 0 8 0ZM12 8.66667H8.66667V12C8.66667 12.3687 8.368 12.6667 8 12.6667C7.632 12.6667 7.33333 12.3687 7.33333 12V8.66667H4C3.632 8.66667 3.33333 8.36867 3.33333 8C3.33333 7.63133 3.632 7.33333 4 7.33333H7.33333V4C7.33333 3.63133 7.632 3.33333 8 3.33333C8.368 3.33333 8.66667 3.63133 8.66667 4V7.33333H12C12.368 7.33333 12.6667 7.63133 12.6667 8C12.6667 8.36867 12.368 8.66667 12 8.66667Z"
                        fill="#79747E"
                      />
                    </svg>
                  </div>
                ))}
            </div>

            <p className={classes.description}>{props.description}</p>
          </>
        )}
      </div>
      {isInfoOpen && (
        <InfoModal onClose={() => setIsInfoOpen(false)} id={props.id} />
      )}
    </>
  );
}

export default TodoItem;
