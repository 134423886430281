import React from "react";
import styles from "./Resources.module.css";
import doc from "../../../../../assets/images/Roadmap/icon/doc.png";
import image from "../../../../../assets/images/Roadmap/icon/image.png";

const Resources = (props) => {
  const resourcesDescription = props.description;
  console.log(props.description);
  const resources = [
    { src: doc, title: "An introduction to front-end." },
    { src: image, title: "An introduction to front-end." },
  ];
  return (
    <div className={styles.resourcesContainer}>
      <span className={styles.resourcesDescription}>
        {resourcesDescription}
      </span>
      {resources.map((resource) => (
        <div className={styles.resourceContainer}>
          <img
            src={resource.src}
            style={{ width: "8px", height: "8px" }}
            alt=""
          />
          <span>{resource.title}</span>
        </div>
      ))}
    </div>
  );
};

export default Resources;
