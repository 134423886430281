import React, { useState, useContext } from "react";
import Button from "../../UI/Button";
import AuthTitle from "../../UI/AuthTitle";
import ErrorMessage from "../../UI/ErrorMessage";
import UserContext from "../../../store/user-context";
import Input from "../../UI/Input";
import { isValidEmail } from "../../../utils/Utils";
import { Link, useNavigate } from "react-router-dom";
import classes from "./LogIn.module.css";
import email from "../../../assets/images/icon/email.png";
import password from "../../../assets/images/icon/password.png";

const LogIn = () => {
  const [enteredEmail, setEnteredEmail] = useState("");
  const [enteredPassword, setEnteredPasswore] = useState("");
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const activateButton =
    enteredEmail.length >= 5 && enteredPassword.length >= 8;
  const userCtx = useContext(UserContext);

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <div className={classes.container}>
      <div className={classes.backButton}>
        <svg
          onClick={handleBack}
          width="23"
          height="14"
          viewBox="0 0 23 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6.33554 0L0 6.91738L6.33554 13.8348L7.66158 12.3548L3.62452 7.94694H22.5427V5.88781H3.62452L7.66158 1.48L6.33554 0Z"
            fill="#010440"
            fill-opacity="0.7"
          />
        </svg>
      </div>
      <div className={classes.authContainer}>
        <div>
          <AuthTitle title="Log In" pageType="login" to={"/signup"} />
          <div className={classes.inputContainer}>
            <Input
              placeholder="Email"
              source={email}
              icon={"email"}
              value={enteredEmail}
              onChange={(email) => setEnteredEmail(email)}
            />
            <Input
              placeholder="Password"
              source={password}
              secureTextEntry={true}
              type={"password"}
              icon={"password"}
              value={enteredPassword}
              onChange={(pass) => setEnteredPasswore(pass)}
            />
            <Link
              className={classes.forgetPassLink}
              to={"/login/forgetpassword"}
            >
              Forget password?
            </Link>
          </div>
          {error && <ErrorMessage message={error} />}
        </div>

        <div className={classes.buttonsContainer}>
          <Button
            isLoading={isLoading}
            onClick={() => {
              if (isValidEmail(enteredEmail)) {
                userCtx.logIn(
                  enteredEmail,
                  enteredPassword,
                  {},
                  (val) => setError(val),
                  (val) => setIsLoading(val),
                  () => navigate("/explore")
                );
              } else {
                setError("Your email should contain '@'.");
              }
            }}
            disabled={!activateButton}
          >
            Sign In
          </Button>
          <Button
            googleButton={true}
            onClick={userCtx.googleSignUp}
            isLoading={isLoading}
          >
            Sign in with Google
          </Button>
        </div>
      </div>
    </div>
  );
};

export default LogIn;
