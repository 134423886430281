import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { DatePicker } from "react-rainbow-components";
import Button from "../../UI/Button";

import classes from "./OnboardingAge.module.css";
import character from "../../../assets/images/onboradingCharacterAge.png";

function OnboardingAge() {
  const navigate = useNavigate();
  const location = useLocation();
  const { name } = location.state;
  const [birthDate, setBirthdate] = useState(null);

  const handleSkip = () => {
    navigate("/");
  };

  const handleBack = () => {
    navigate(-1);
  };

  const handleNextClick = () => {
    if (birthDate !== null) {
      navigate(`/onboarding/onboarding-curious`, {
        state: { name: name, birthDate: birthDate },
      });
    }
  };

  return (
    <div className={classes.background}>
      <div className={classes.progress}>
        <hr />
        <svg
          onClick={handleBack}
          width="23"
          height="14"
          viewBox="0 0 23 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6.33554 0L0 6.91738L6.33554 13.8348L7.66158 12.3548L3.62452 7.94694H22.5427V5.88781H3.62452L7.66158 1.48L6.33554 0Z"
            fill="#010440"
            fill-opacity="0.7"
          />
        </svg>
      </div>
      <div className={classes.nameInputContainer}>
        <DatePicker
          id="datePicker-1"
          value={birthDate}
          onChange={(date) => {
            setBirthdate(date);
          }}
          placeholder={"Your BirthDate"}
          className={classes.datePicker}
          formatStyle="medium"
        />
        <div className={classes.character}>
          <div className={classes.message}>
            <p>Your birth date?</p>
          </div>
          <img src={character} alt="" />
        </div>
      </div>
      <div className={classes.control}>
        <button className={classes.skipButton} onClick={handleSkip}>
          Skip
        </button>
        <Button onClick={handleNextClick} style={{ width: "40%" }}>
          Next
        </Button>
      </div>
    </div>
  );
}

export default OnboardingAge;
