import { useState, useEffect, useContext } from "react";
import axiosInstance from "../../../../request/axiosInstance";
import styles from "./Popup.module.css";
import close from "../../../../assets/images/Explore/icon/close.png";
import check from "../../../../assets/images/Explore/icon/check.png";
import UserContext from "../../../../store/user-context";
import SkeletonLoading from "./Skeleton/SkeletonLoading";

const Popup = ({ show, onClose, setExploreItems, setIsLoading, loading }) => {
  const userCtx = useContext(UserContext);
  const [categoryPopupOpen, setCategoryPopupOpen] = useState(false);
  const [categories, setCategories] = useState([]);
  const [popUpLoading, setPopUpLoading] = useState(false);
  const [intersts, setInterests] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedInterests, setSelectedInterests] = useState([]);
  const [interestPopupOpen, setInterestPopupOpen] = useState(false);

  useEffect(() => {
    if (userCtx.token !== null) {
      const userCats = userCtx.user.selectedCategories;
      const userInterests = userCtx.user.selectedInterests;

      setSelectedCategories(userCats && userCats.map((cat) => cat.id));
      setSelectedInterests(
        userInterests && userInterests.map((interest) => interest.id)
      );
    } else {
      setSelectedCategories([]);
      setSelectedInterests([]);
    }
  }, [
    userCtx.user.selectedCategories,
    userCtx.user.selectedInterests,
    userCtx.token,
  ]);

  if (!show) {
    return null;
  }

  const showCategoriesHandler = () => {
    setPopUpLoading(true);
    axiosInstance
      .get("/onboarding/categories")
      .then((res) => setCategories(res.data))
      .finally(() => setPopUpLoading(false));
    setCategoryPopupOpen(true);
  };

  const showInterestsHandler = () => {
    setPopUpLoading(true);
    axiosInstance
      .get("onboarding/interests")
      .then((res) => setInterests(res.data))
      .finally(() => setPopUpLoading(false));
    setInterestPopupOpen(true);
  };

  const selectCategoryHandler = (catId) => {
    setSelectedCategories((prev) => {
      if (prev.includes(catId)) {
        return prev.filter((id) => id !== catId);
      }
      return [...prev, catId];
    });
  };

  const selectInterestHandler = (interestId) => {
    setSelectedInterests((prev) => {
      if (prev.includes(interestId)) {
        return prev.filter((id) => id !== interestId);
      } else if (prev.length < 2 || prev.length === 0) {
        return [...prev, interestId];
      }
      return prev;
    });
  };

  const filterResultHandler = () => {
    axiosInstance
      .post("/explore/query", {
        categoryIds: selectedCategories.length > 0 ? selectedCategories : [-1],
        interestIds: selectedInterests.length > 0 ? selectedInterests : [-1],
        Page: 1,
      })
      .then((res) => setExploreItems(res.data.data))
      .finally(() => setIsLoading(false));
    onClose();
  };

  const categoryPopup = (
    <div
      className={styles.insidePopupContainer}
      style={{ top: "43%", right: "6%" }}
    >
      <img src={close} onClick={() => setCategoryPopupOpen(false)} alt="" />
      <div className={styles.insidePopupBody}>
        {popUpLoading && <SkeletonLoading />}
        {!popUpLoading &&
          !loading &&
          categories.map((cat) => (
            <div key={cat.id} className={styles.category}>
              <p
                className={styles.category}
                onClick={() => selectCategoryHandler(cat.id)}
              >
                {cat.name}
              </p>
              {selectedCategories.includes(cat.id) && (
                <span>
                  <img
                    src={check}
                    style={{ width: "14px", height: "10px" }}
                    alt=""
                  />
                </span>
              )}
            </div>
          ))}
      </div>
    </div>
  );

  const interestPopup = (
    <div
      className={styles.insidePopupContainer}
      style={{ top: "66%", right: "6%", height: "300px", overflowY: "hidden" }}
    >
      <img src={close} onClick={() => setInterestPopupOpen(false)} alt="" />
      <div
        className={styles.insidePopupBody}
        style={{ height: "85%", overflowY: "scroll" }}
      >
        {popUpLoading && <SkeletonLoading />}
        {!popUpLoading &&
          !loading &&
          intersts.map((interest) => (
            <div key={interest.id} className={styles.category}>
              <p
                className={styles.category}
                onClick={() => selectInterestHandler(interest.id)}
              >
                {interest.name}
              </p>
              {selectedInterests.includes(interest.id) && (
                <span>
                  <img
                    src={check}
                    style={{ width: "14px", height: "10px" }}
                    alt=""
                  />
                </span>
              )}
            </div>
          ))}
      </div>
    </div>
  );

  return (
    <div className={styles.container}>
      <img src={close} alt="" onClick={onClose} className={styles.closeIcon} />
      <div className={styles.popupBody}>
        <div className={styles.filterItemContainer}>
          <span className={styles.filterItemTitle}>Category</span>
          <div className={styles.filterItem} onClick={showCategoriesHandler}>
            <span>{selectedCategories.length} Items</span>
          </div>
          {categoryPopupOpen && categoryPopup}
        </div>
        <div className={styles.filterItemContainer}>
          <span className={styles.filterItemTitle}>Interests</span>
          <div className={styles.filterItem} onClick={showInterestsHandler}>
            <span>{selectedInterests.length} Items</span>
          </div>
          {interestPopupOpen && interestPopup}
        </div>
      </div>
      <div className={styles.footer}>
        <p onClick={onClose}>CANCLE</p>
        <p onClick={filterResultHandler}>OK</p>
      </div>
    </div>
  );
};

export default Popup;
