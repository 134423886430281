import React from "react";

import classes from "./TabBar.module.css";
import home from "../../../assets/images/Home/icons/home.png";
import roadmap from "../../../assets/images/Home/icons/roadmap.png";
import explore from "../../../assets/images/Home/icons/explore.png";
import chat from "../../../assets/images/Home/icons/chat.png";
import profile from "../../../assets/images/Home/icons/profile.png";
import { Link, useLocation } from "react-router-dom";

const tabs = [
  { id: 1, image: home, alt: "home", slug: "/home-page" },
  { id: 2, image: roadmap, alt: "roadmap", slug: "/roadmaps" },
  { id: 3, image: explore, alt: "explore", slug: "/explore" },
  { id: 4, image: chat, alt: "chat", slug: "/" },
  { id: 5, image: profile, alt: "profile", slug: "/logout" },
];

function TabBar() {
  const location = useLocation();
  return (
    <div className={classes.tabBar}>
      <div className={classes.container}>
        {tabs.map((tab) => (
          <Link to={tab.slug} key={tab.id} replace className={classes.link}>
            <div
              className={
                location.pathname === tab.slug ? classes.activeTab : classes.tab
              }
              style={tab.alt === "roadmap" ? { width: "55%" } : {}}
            >
              <img src={tab.image} alt={tab.alt} />
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
}

export default TabBar;
