import React, { useEffect, useState } from "react";

import styles from "./UserRoadmap.module.css";
import FullScreenChat from "../RoadmapDetails/FullScreenChat/FullScreenChat";
import RoadmapDetailsHeader from "../RoadmapDetails/RoadmapDetailsHeader/RoadmapDetailsHeader";
import RoadmapDetailsBody from "../RoadmapDetails/RoadmapDetailsBody/RoadmapDetailsBody";
import RatingModal from "../RoadmapDetails/RatingModal/RatingModal";
import axiosInstance from "../../../request/axiosInstance";
import LoadinSkeleton from "../RoadmapDetails/Skeleton/LoadinSkeleton";
import { useLocation } from "react-router-dom";

const UserRoadmap = (props) => {
  const location = useLocation();
  const { currId } = location.state !== null && location.state;
  const [fullScreenChat, setFullScreenChat] = useState(false);
  const [childCallback, setChildCallback] = useState(null);
  const [started, setStarted] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [startLoading, setStartLoading] = useState(false);
  const [userRoadmapDetail, setUserRoadmapDetail] = useState(null);
  const [userRoadmapLoading, setUserRoadmapLoading] = useState(true);

  useEffect(() => {
    axiosInstance
      .get(`/roadrunner/${props.userRoadmapId}/detail`)
      .then((res) => {
        setUserRoadmapDetail(res.data);
        setUserRoadmapLoading(false);
        const element = document.getElementById(currId);
        if (element) {
          element.scrollIntoView({ behavior: "smooth" });
        }
        if (res.data) {
          setStarted(res.data.status === "started");
        }
      });
  }, [props.userRoadmapId]);

  const registerChildCallback = (callback) => {
    setChildCallback(() => callback);
  };

  const handleFullScreenChats = () => {
    setFullScreenChat((prev) => !prev);
    if (childCallback) {
      childCallback();
    }
  };

  const startClickHandler = () => {
    setStartLoading(true);

    axiosInstance
      .post(`/roadrunner/${userRoadmapDetail.id}/Start`)
      .then((res) => {
        if (res.status === 201) {
          setStarted(true);
          setUserRoadmapDetail(res.data);
        }
      })
      .finally(() => setStartLoading(false));
  };

  return (
    <div className={styles.container}>
      {userRoadmapDetail && !userRoadmapLoading ? (
        <>
          <FullScreenChat
            fullScreen={fullScreenChat}
            onFullScreen={() => {
              handleFullScreenChats();
              window.scrollTo(0, 0);
            }}
          />
          <RoadmapDetailsHeader
            roadmap={userRoadmapDetail}
            userRoadmap={userRoadmapDetail}
          />
          <RoadmapDetailsBody
            loading={userRoadmapLoading}
            roadmap={userRoadmapDetail}
            roadmapSetter={setUserRoadmapDetail}
            isEnrolled={true}
            started={started}
            onStart={startClickHandler}
            onFullScreenChat={handleFullScreenChats}
            registerCallback={registerChildCallback}
            handleStepDone={(token) => {}}
            startLoading={startLoading}
            setRatingModalOpen={setIsModalOpen}
            isUserRoadmap={true}
            currId={currId}
          />
          <RatingModal
            isModalOpen={isModalOpen}
            setIsModalOpen={setIsModalOpen}
            roadmap={userRoadmapDetail}
          />
        </>
      ) : (
        <LoadinSkeleton />
      )}
    </div>
  );
};

export default UserRoadmap;
