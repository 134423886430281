import ExploreSingleItem from "../../ExploreSingleItem/ExploreSingleItem";
import InnerHorizontal from "../InnerHorizontal/InnerHorizontal";
import styles from "./HorizontalT1GridBased.module.css";

const HorizontalT1GridBased = ({ items }) => {
  return (
    <div className={styles.container}>
      <ExploreSingleItem
        type="horizontal"
        roadmapId={items[0].id}
        pic={items[0].thumbnail}
        title={items[0].title}
        roadmapOwnre={items[0].creator.fullName}
        rate={items[0].averageRate}
        roadRunners={items[0].roadRunners}
        premium={items[0].premium}
      />
      <InnerHorizontal items={items.slice(1, 3)} />
    </div>
  );
};

export default HorizontalT1GridBased;
