import React, { useEffect, useState } from "react";
import { Tabs } from "antd";
import TabPane from "antd/es/tabs/TabPane";
import { Card } from "react-rainbow-components";

import ToDoList from "../ToDoList/ToDoList";
import Resources from "../Resources/Resources";
import Chat from "../Chatbot/Chatbot";
import Note from "../Note/Note";
import { calculateDateRange, datesAreEqual } from "../../../../../utils/Utils";
import StepCalender from "../Calender/StepCalender";

import classes from "./Menu.module.css";

import todo from "../../../../../assets/images/Roadmap/icon/todo.png";
import calender from "../../../../../assets/images/Roadmap/icon/stepDate.png";
import resources from "../../../../../assets/images/Roadmap/icon/resource.png";
import bot from "../../../../../assets/images/Roadmap/icon/bot.png";
import note from "../../../../../assets/images/Roadmap/icon/note.png";
import upload from "../../../../../assets/images/Roadmap/icon/upload.png";
import maximize from "../../../../../assets/images/Roadmap/icon/maximize.png";
import UploadTab from "../UploadTab/UploadTab";

function Menu(props) {
  const [currentFullScreenChat, setCurrentFullScreenChat] = useState(false);
  const [activeKey, setActiveKey] = useState(null);
  const [deadlineStatus, setDeadlineStatus] = useState("");
  const [range, setRange] = useState(props.dateRange);

  function addDays(date, days) {
    const newDate = new Date(date);
    newDate.setDate(date.getDate() + days);
    return newDate;
  }

  const checkOverDue = (date) => {
    const today = new Date();

    if (datesAreEqual(today, date) || datesAreEqual(addDays(today, 1), date)) {
      if (props.isTask) {
        props.setDeadlineStatus("deadlineToT");
      }
      setDeadlineStatus("deadlineToT");
    }
    if (today > date && today.getDate() > date.getDate()) {
      setDeadlineStatus("overdue");
      if (props.isTask) {
        props.setDeadlineStatus("overdue");
      }
    }
  };

  useEffect(() => {
    if (props.dueDateFrom !== null && props.dueDateTo !== null) {
      const from = new Date(props.dueDateFrom);
      const to = new Date(props.dueDateTo);
      checkOverDue(to);
      setRange([from, to]);
    }
    if (props.dueDateFrom !== null && props.dueDateTo === null) {
      const from = new Date(props.dueDateFrom);
      checkOverDue(from);
      setRange([from]);
    }
    if (
      props.dueDateFrom === null &&
      props.dueDateTo === null &&
      props.dateRange
    ) {
      if (props.dateRange[1]) {
        checkOverDue(props.dateRange[1]);
      } else {
        checkOverDue(props.dateRange[0]);
      }
      setRange(props.dateRange);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.dueDateFrom, props.dueDateTo, props.dateRange]);

  const handleFullScreenChat = () => {
    props.setFullScreenChat();
  };

  const handleTabChange = (key) => {
    if (!props.started && key !== "2") {
      setActiveKey(null);
    } else if (props.isLocked && key !== "2") {
      setActiveKey(null);
    } else {
      setActiveKey(key);
    }
  };

  const tabClicked = () => {
    if (activeKey !== null) {
      setActiveKey(null);
    }
  };

  return (
    <Tabs
      activeKey={activeKey}
      onChange={handleTabChange}
      size="small"
      indicator={{ size: 0 }}
      style={!props.showMenu && { display: "none" }}
    >
      <TabPane
        tab={
          <div
            className={activeKey === "1" ? classes.activeTab : classes.tab}
            style={!props.started || props.isLocked ? { opacity: "0.5" } : {}}
            onClick={tabClicked}
          >
            <img src={todo} alt="" />
          </div>
        }
        key="1"
      >
        {activeKey === "1" && (
          <ToDoList id={props.entityId} type={props.type} />
        )}
      </TabPane>
      <TabPane
        tab={
          <div
            className={activeKey === "2" ? classes.activeTab : classes.tab}
            onClick={tabClicked}
          >
            <img src={resources} alt="" />
          </div>
        }
        key="2"
      >
        {activeKey === "2" && <Resources description={props.description} />}
      </TabPane>
      <TabPane
        tab={
          <div
            className={activeKey === "3" ? classes.activeTab : classes.tab}
            style={
              deadlineStatus === "overdue"
                ? { background: "#FF00001A" }
                : !props.started || props.isLocked
                ? { opacity: "0.5" }
                : {}
            }
            onClick={tabClicked}
          >
            <img
              src={calender}
              alt=""
              style={range && range[1] ? { width: "21%" } : { width: "32%" }}
            />
            <p>{calculateDateRange({ openedOnce: true }, range, "0-0")}</p>
          </div>
        }
        key="3"
      >
        {activeKey === "3" && (
          <Card
            className="rainbow-p-around_large"
            style={{
              display: "flex",
              flexDirection: "column",
              zIndex: "1000",
              width: "100%",
            }}
          >
            <StepCalender
              isStep={false}
              setRange={setRange}
              refreshUserRoadmap={props.refreshUserRoadmap}
              entityId={props.entityId}
              defaultRange={range}
              isDone={props.isDone}
            />
          </Card>
        )}
      </TabPane>
      <TabPane
        tab={
          <div
            className={activeKey === "4" ? classes.activeTab : classes.tab}
            style={!props.started || props.isLocked ? { opacity: "0.5" } : {}}
            onClick={tabClicked}
          >
            <img src={bot} alt="" />
          </div>
        }
        key="4"
      >
        {activeKey === "4" && (
          <div style={currentFullScreenChat ? { display: "none" } : {}}>
            <img
              src={maximize}
              alt=""
              style={{
                position: "absolute",
                width: "18px",
                height: "18px",
                top: "8px",
                left: "15px",
                zIndex: "1000000",
              }}
              onClick={() => {
                handleFullScreenChat();
                document.body.style.overflow = "hidden";
                props.registerCallback(() => {
                  setCurrentFullScreenChat(false);
                  document.body.style.overflow = "auto";
                });
                setCurrentFullScreenChat((prev) => !prev);
                window.scrollTo(0, 0);
              }}
            />
            <Chat
              style={{
                width: `${0.79 * props.itemWidth}px`,
                borderRadius: "10px",
              }}
              avatarWidth={"2.5rem"}
              avatarHeight={"2.5rem"}
            />
          </div>
        )}
      </TabPane>
      <TabPane
        tab={
          <div
            className={activeKey === "5" ? classes.activeTab : classes.tab}
            style={!props.started || props.isLocked ? { opacity: "0.5" } : {}}
            onClick={tabClicked}
          >
            <img src={note} alt="" />
          </div>
        }
        key="5"
      >
        {activeKey === "5" && <Note id={props.entityId} />}
      </TabPane>
      {props.isTask && (
        <TabPane
          tab={
            <div
              className={activeKey === "6" ? classes.activeTab : classes.tab}
              style={!props.started || props.isLocked ? { opacity: "0.5" } : {}}
              onClick={tabClicked}
            >
              <img src={upload} alt="" />
            </div>
          }
          key="6"
        >
          {activeKey === "6" && <UploadTab entityId={props.entityId} />}
        </TabPane>
      )}
    </Tabs>
  );
}

export default Menu;
