import ExploreSingleItem from "../../ExploreSingleItem/ExploreSingleItem";
import styles from "./DividerGridBased.module.css";

const DividerGridBased = ({ items }) => {
  return (
    <div className={styles.container}>
      <ExploreSingleItem
        type="square2"
        roadmapId={items[0].id}
        pic={items[0].thumbnail}
        title={items[0].title}
        premium={items[0].premium}
      />
      <ExploreSingleItem
        type="horizontal"
        roadmapId={items[1].id}
        pic={items[1].thumbnail}
        title={items[1].title}
        roadmapOwnre={items[1].creator.fullName}
        rate={items[1].averageRate}
        roadRunners={items[1].roadRunners}
        premium={items[1].premium}
      />
      <ExploreSingleItem
        type="square2"
        roadmapId={items[2].id}
        pic={items[2].thumbnail}
        title={items[2].title}
        premium={items[2].premium}
      />
    </div>
  );
};

export default DividerGridBased;
