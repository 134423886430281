import React, { useCallback, useEffect, useState } from "react";
import { Input } from "antd";
import useRequest from "../../../../../requests/request";

const { TextArea } = Input;

function debounce(func, delay) {
  let timer;
  return function(...args) {
    clearTimeout(timer);
    timer = setTimeout(() => func.apply(this, args), delay);
  };
}

function Note(props) {
  const [textArea, setTextArea] = useState("");
  const { data, loading, get, patch } = useRequest();

  useEffect(() => {
    const token = localStorage.getItem("token");
    get(`/roadrunner/note/fetch/${props.id}`, {
      headers: {
        Authorization: "Bearer " + JSON.parse(token),
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.currEntityId]);

  useEffect(() => {
    if (data) {
      setTextArea(data.description);
    }
  }, [data]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedApiCall = useCallback(
    debounce((value) => {
      const token = localStorage.getItem("token");
      patch(
        `/roadrunner/note/upsert/${props.id}`,
        {
          content: value,
        },
        {
          headers: {
            Authorization: "Bearer " + JSON.parse(token),
          },
        }
      );
    }, 2000),
    []
  );

  const handleTextAreaChange = (e) => {
    const newValue = e.target.value;
    setTextArea(newValue);
    debouncedApiCall(newValue);
  };
  return (
    <TextArea
      maxLength={100}
      value={textArea}
      onChange={handleTextAreaChange}
      placeholder={loading ? "loading..." : "Note’s space |"}
      style={{
        height: 150,
        resize: "none",
      }}
    />
  );
}

export default Note;
