import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../UI/Button";

import classes from "./OnboardingName.module.css";
import character from "../../../assets/images/onboradingCharacterName.png";

function OnboardingName() {
  const navigate = useNavigate();
  const [name, setName] = useState("");

  const handleBack = () => {
    navigate(-1);
  };

  const handleNextClick = () => {
    if (name !== "") {
      navigate(`/onboarding/onboarding-age`, { state: { name: name } });
    }
  };

  return (
    <div className={classes.background}>
      <div className={classes.progress}>
        <hr />
        <svg
          onClick={handleBack}
          width="23"
          height="14"
          viewBox="0 0 23 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6.33554 0L0 6.91738L6.33554 13.8348L7.66158 12.3548L3.62452 7.94694H22.5427V5.88781H3.62452L7.66158 1.48L6.33554 0Z"
            fill="#010440"
            fill-opacity="0.7"
          />
        </svg>
      </div>
      <div className={classes.nameInputContainer}>
        <input
          type="text"
          className={classes.nameInput}
          placeholder="Your name"
          onChange={(e) => {
            setName(e.target.value);
          }}
          value={name}
        />
        <div className={classes.character}>
          <div className={classes.message}>
            <p>I’m Hopee. What is your name?</p>
          </div>
          <img src={character} alt="" />
        </div>
      </div>
      <div className={classes.control}>
        <Button onClick={handleNextClick} style={{ margin: "0 8%" }}>
          Next
        </Button>
      </div>
    </div>
  );
}

export default OnboardingName;
