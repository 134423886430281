import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../UI/Button";

import classes from "./GetStarted.module.css";
import character from "../../assets/images/homeCharacter.png";
// import message from "../../assets/images/homeMessage.svg";

function GetStarted() {
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("token");

    if (token) {
      navigate("explore", { replace: true });
    }
  }, []);

  const handleNextClick = () => {
    navigate(`/onboarding/`);
  };

  const handleSkip = () => {
    navigate("/login", { replace: true });
  };

  return (
    <div className={classes.background}>
      <div className={classes.character}>
        <img src={character} alt="" />
      </div>
      {/* <div className={classes.message}>
                <img src={message} alt=""/>
            </div> */}
      <div className={classes.control}>
        <Button onClick={handleNextClick} style={{ width: "100%" }}>
          Get Started
        </Button>
        <button className={classes.skipButton} onClick={handleSkip}>
          I Already have an account
        </button>
      </div>
    </div>
  );
}

export default GetStarted;
