import styles from "./GridBasedContent.module.css";
import HorizontalGridBased from "./HorizontalGridBased/HorizontalGridBased";
import VerticalGridBased from "./VerticalGridBased/VerticalGridBased";
import SquarGridBased from "./SquarGridBased/SquarGridBased";
import VerticalT1GridBased from "./VerticalT1GridBased/VerticalT1GridBased";
import VerticalT2GridBased from "./VerticalT2GridBased/VerticalT2GridBased";
import HorizontalT1GridBased from "./HorizontalT1GriBased/HorizontalT1GridBased";
import HorizontalT2GriBased from "./HorizontalT2GriBased/HorizontalT2GriBased";
import DividerGridBased from "./DividerGridBased/DividerGridBased";

const GridBasedContent = ({ items, row }) => {
  // const demo = [
  //   {
  //     blockType: "horizontal",
  //     items: [
  //       {
  //         id: 31,
  //         title: "roadmap19",
  //         creator: {
  //           id: 2,
  //           profilePicAttachmentId: null,
  //           fullName: "Kaveh moradian",
  //         },
  //         premium: true,
  //         thumbnail: 0,
  //         averageRate: 0,
  //         roadRunners: 0,
  //       },
  //       {
  //         id: 30,
  //         title: "roadmap18",
  //         creator: {
  //           id: 2,
  //           profilePicAttachmentId: null,
  //           fullName: "Kaveh moradian",
  //         },
  //         premium: false,
  //         thumbnail: 0,
  //         averageRate: 0,
  //         roadRunners: 0,
  //       },
  //     ],
  //   },
  //   {
  //     blockType: "horizontal",
  //     items: [
  //       {
  //         id: 26,
  //         title: "roadmap14",
  //         creator: {
  //           id: 2,
  //           profilePicAttachmentId: null,
  //           fullName: "Kaveh moradian",
  //         },
  //         premium: false,
  //         thumbnail: 0,
  //         averageRate: 0,
  //         roadRunners: 0,
  //       },
  //       {
  //         id: 25,
  //         title: "roadmap13",
  //         creator: {
  //           id: 2,
  //           profilePicAttachmentId: null,
  //           fullName: "Kaveh moradian",
  //         },
  //         premium: true,
  //         thumbnail: 0,
  //         averageRate: 0,
  //         roadRunners: 0,
  //       },
  //     ],
  //   },
  //   {
  //     blockType: "square",
  //     items: [
  //       {
  //         id: 27,
  //         title: "roadmap15",
  //         creator: {
  //           id: 2,
  //           profilePicAttachmentId: null,
  //           fullName: "Kaveh moradian",
  //         },
  //         premium: true,
  //         thumbnail: 0,
  //         averageRate: 0,
  //         roadRunners: 0,
  //       },
  //     ],
  //   },
  //   {
  //     blockType: "vertical",
  //     items: [
  //       {
  //         id: 29,
  //         title: "roadmap17",
  //         creator: {
  //           id: 2,
  //           profilePicAttachmentId: null,
  //           fullName: "Kaveh moradian",
  //         },
  //         premium: false,
  //         thumbnail: 0,
  //         averageRate: 0,
  //         roadRunners: 0,
  //       },
  //       {
  //         id: 28,
  //         title: "roadmap16",
  //         creator: {
  //           id: 2,
  //           profilePicAttachmentId: null,
  //           fullName: "Kaveh moradian",
  //         },
  //         premium: true,
  //         thumbnail: 0,
  //         averageRate: 0,
  //         roadRunners: 0,
  //       },
  //     ],
  //   },
  //   {
  //     blockType: "vertical",
  //     items: [
  //       {
  //         id: 24,
  //         title: "roadmap12",
  //         creator: {
  //           id: 2,
  //           profilePicAttachmentId: null,
  //           fullName: "Kaveh moradian",
  //         },
  //         premium: false,
  //         thumbnail: 0,
  //         averageRate: 0,
  //         roadRunners: 0,
  //       },
  //       {
  //         id: 23,
  //         title: "roadmap11",
  //         creator: {
  //           id: 2,
  //           profilePicAttachmentId: null,
  //           fullName: "Kaveh moradian",
  //         },
  //         premium: false,
  //         thumbnail: 0,
  //         averageRate: 0,
  //         roadRunners: 0,
  //       },
  //     ],
  //   },
  //   {
  //     blockType: "square",
  //     items: [
  //       {
  //         id: 32,
  //         title: "roadmap20",
  //         creator: {
  //           id: 2,
  //           profilePicAttachmentId: null,
  //           fullName: "Kaveh moradian",
  //         },
  //         premium: false,
  //         thumbnail: 0,
  //         averageRate: 0,
  //         roadRunners: 0,
  //       },
  //     ],
  //   },
  //   {
  //     blockType: "vertical",
  //     items: [
  //       {
  //         id: 19,
  //         title: "roadmap7",
  //         creator: {
  //           id: 2,
  //           profilePicAttachmentId: null,
  //           fullName: "Kaveh moradian",
  //         },
  //         premium: false,
  //         thumbnail: 0,
  //         averageRate: 0,
  //         roadRunners: 0,
  //       },
  //       {
  //         id: 18,
  //         title: "roadmap6",
  //         creator: {
  //           id: 2,
  //           profilePicAttachmentId: null,
  //           fullName: "Kaveh moradian",
  //         },
  //         premium: false,
  //         thumbnail: 0,
  //         averageRate: 0,
  //         roadRunners: 0,
  //       },
  //     ],
  //   },
  //   {
  //     blockType: "square",
  //     items: [
  //       {
  //         id: 22,
  //         title: "roadmap10",
  //         creator: {
  //           id: 2,
  //           profilePicAttachmentId: null,
  //           fullName: "Kaveh moradian",
  //         },
  //         premium: false,
  //         thumbnail: 0,
  //         averageRate: 0,
  //         roadRunners: 0,
  //       },
  //     ],
  //   },
  // ];

  // const temp = [
  //   {
  //     blockType: "square",
  //     items: [
  //       {
  //         id: 27,
  //         title: "roadmap15",
  //         creator: {
  //           id: 2,
  //           profilePicAttachmentId: null,
  //           fullName: "Kaveh moradian",
  //         },
  //         premium: true,
  //         thumbnail: p1,
  //         averageRate: 0,
  //         roadRunners: 0,
  //       },
  //     ],
  //   },
  //   {
  //     blockType: "horizontal",
  //     items: [
  //       {
  //         id: 31,
  //         title: "roadmap19",
  //         creator: {
  //           id: 2,
  //           profilePicAttachmentId: null,
  //           fullName: "Kaveh moradian",
  //         },
  //         premium: true,
  //         thumbnail: p2,
  //         averageRate: 0,
  //         roadRunners: 0,
  //       },
  //       {
  //         id: 30,
  //         title: "roadmap18",
  //         creator: {
  //           id: 2,
  //           profilePicAttachmentId: null,
  //           fullName: "Kaveh moradian",
  //         },
  //         premium: false,
  //         thumbnail: p3,
  //         averageRate: 0,
  //         roadRunners: 0,
  //       },
  //     ],
  //   },
  //   {
  //     blockType: "temp2",
  //     items: [
  //       {
  //         id: 24,
  //         title: "roadmap12",
  //         creator: {
  //           id: 2,
  //           profilePicAttachmentId: null,
  //           fullName: "Kaveh moradian",
  //         },
  //         premium: false,
  //         thumbnail: p4,
  //         averageRate: 0,
  //         roadRunners: 0,
  //       },
  //       {
  //         id: 23,
  //         title: "roadmap11",
  //         creator: {
  //           id: 2,
  //           profilePicAttachmentId: null,
  //           fullName: "Kaveh moradian",
  //         },
  //         premium: false,
  //         thumbnail: p2,
  //         averageRate: 0,
  //         roadRunners: 0,
  //       },
  //       {
  //         id: 22,
  //         title: "roadmap10",
  //         creator: {
  //           id: 2,
  //           profilePicAttachmentId: null,
  //           fullName: "Kaveh moradian",
  //         },
  //         premium: false,
  //         thumbnail: p3,
  //         averageRate: 0,
  //         roadRunners: 0,
  //       },
  //     ],
  //   },
  //   {
  //     blockType: "temp1",
  //     items: [
  //       {
  //         id: 24,
  //         title: "roadmap12",
  //         creator: {
  //           id: 2,
  //           profilePicAttachmentId: null,
  //           fullName: "Kaveh moradian",
  //         },
  //         premium: false,
  //         thumbnail: p4,
  //         averageRate: 0,
  //         roadRunners: 0,
  //       },
  //       {
  //         id: 23,
  //         title: "roadmap11",
  //         creator: {
  //           id: 2,
  //           profilePicAttachmentId: null,
  //           fullName: "Kaveh moradian",
  //         },
  //         premium: false,
  //         thumbnail: p2,
  //         averageRate: 0,
  //         roadRunners: 0,
  //       },
  //       {
  //         id: 22,
  //         title: "roadmap10",
  //         creator: {
  //           id: 2,
  //           profilePicAttachmentId: null,
  //           fullName: "Kaveh moradian",
  //         },
  //         premium: false,
  //         thumbnail: p3,
  //         averageRate: 0,
  //         roadRunners: 0,
  //       },
  //     ],
  //   },
  //   {
  //     blockType: "temp5",
  //     items: [
  //       {
  //         id: 24,
  //         title: "roadmap12",
  //         creator: {
  //           id: 2,
  //           profilePicAttachmentId: null,
  //           fullName: "Kaveh moradian",
  //         },
  //         premium: false,
  //         thumbnail: p4,
  //         averageRate: 0,
  //         roadRunners: 0,
  //       },
  //       {
  //         id: 23,
  //         title: "roadmap11",
  //         creator: {
  //           id: 2,
  //           profilePicAttachmentId: null,
  //           fullName: "Kaveh moradian",
  //         },
  //         premium: false,
  //         thumbnail: p2,
  //         averageRate: 0,
  //         roadRunners: 0,
  //       },
  //       {
  //         id: 22,
  //         title: "roadmap10",
  //         creator: {
  //           id: 2,
  //           profilePicAttachmentId: null,
  //           fullName: "Kaveh moradian",
  //         },
  //         premium: false,
  //         thumbnail: p3,
  //         averageRate: 0,
  //         roadRunners: 0,
  //       },
  //     ],
  //   },
  //   {
  //     blockType: "temp3",
  //     items: [
  //       {
  //         id: 24,
  //         title: "roadmap12",
  //         creator: {
  //           id: 2,
  //           profilePicAttachmentId: null,
  //           fullName: "Kaveh moradian",
  //         },
  //         premium: false,
  //         thumbnail: p4,
  //         averageRate: 0,
  //         roadRunners: 0,
  //       },
  //       {
  //         id: 23,
  //         title: "roadmap11",
  //         creator: {
  //           id: 2,
  //           profilePicAttachmentId: null,
  //           fullName: "Kaveh moradian",
  //         },
  //         premium: false,
  //         thumbnail: p3,
  //         averageRate: 0,
  //         roadRunners: 0,
  //       },
  //       {
  //         id: 22,
  //         title: "roadmap10",
  //         creator: {
  //           id: 2,
  //           profilePicAttachmentId: null,
  //           fullName: "Kaveh moradian",
  //         },
  //         premium: false,
  //         thumbnail: p2,
  //         averageRate: 0,
  //         roadRunners: 0,
  //       },
  //     ],
  //   },
  //   {
  //     blockType: "temp4",
  //     items: [
  //       {
  //         id: 24,
  //         title: "roadmap12",
  //         creator: {
  //           id: 2,
  //           profilePicAttachmentId: null,
  //           fullName: "Kaveh moradian",
  //         },
  //         premium: false,
  //         thumbnail: p4,
  //         averageRate: 0,
  //         roadRunners: 0,
  //       },
  //       {
  //         id: 23,
  //         title: "roadmap11",
  //         creator: {
  //           id: 2,
  //           profilePicAttachmentId: null,
  //           fullName: "Kaveh moradian",
  //         },
  //         premium: false,
  //         thumbnail: p2,
  //         averageRate: 0,
  //         roadRunners: 0,
  //       },
  //       {
  //         id: 22,
  //         title: "roadmap10",
  //         creator: {
  //           id: 2,
  //           profilePicAttachmentId: null,
  //           fullName: "Kaveh moradian",
  //         },
  //         premium: false,
  //         thumbnail: p3,
  //         averageRate: 0,
  //         roadRunners: 0,
  //       },
  //     ],
  //   },
  // ];

  return (
    <div
      className={styles.gridContainer}
      style={
        row === 1 ? { gridTemplateRows: "repeat(1, 1fr)", height: "14%" } : {}
      }
    >
      {items.map((item) => {
        if (item.blockType === "horizontal") {
          return <HorizontalGridBased items={item.items} />;
        } else if (item.blockType === "vertical") {
          return <VerticalGridBased items={item.items} />;
        } else if (item.blockType === "square") {
          return <SquarGridBased items={item.items} />;
        } else if (item.blockType === "vertical_type1") {
          return <VerticalT1GridBased items={item.items} />;
        } else if (item.blockType === "vertical_type2") {
          return <VerticalT2GridBased items={item.items} />;
        } else if (item.blockType === "horizontal_type1") {
          return <HorizontalT1GridBased items={item.items} />;
        } else if (item.blockType === "horizontal_type2") {
          return <HorizontalT2GriBased items={item.items} />;
        } else if (item.blockType === "divider") {
          return <DividerGridBased items={item.items} />;
        }
      })}
    </div>
  );
};

export default GridBasedContent;
