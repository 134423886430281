import HeaderButtons from "./HeaderButtons/HeaderButtons";
import { formatCompactNumber } from "../../../../utils/Utils";

import styles from "./RoadmaoDetailsHeader.module.css";
import star from "../../../../assets/images/Roadmap/icon/star.png";
import roadRunner from "../../../../assets/images/Roadmap/icon/roadRunner.png";
import clock from "../../../../assets/images/Roadmap/icon/clock.png";
import level from "../../../../assets/images/Roadmap/icon/level.png";
import roadmapPic from "../../../../assets/images/Roadmap/roadmapPic.png";

const RoadmapDetailsHeader = ({ roadmap, userRoadmap }) => {
  const roadRunners = roadmap
    ? formatCompactNumber(roadmap.roadRunners)
    : formatCompactNumber(userRoadmap.roadRunners);

  return (
    <div className={styles.headerContainer}>
      <HeaderButtons />
      <p className={styles.title}>
        {userRoadmap ? userRoadmap.Roadmap.title : roadmap.title}
      </p>
      <div className={styles.statisticsContainer}>
        <div className={styles.statisticContainer}>
          <img src={star} className={styles.star} alt="" />
          <span>
            {userRoadmap
              ? userRoadmap.averageRate
              : roadmap.rating && roadmap.rating.averageRate}
          </span>
        </div>
        <div className={styles.statisticContainer}>
          <img src={roadRunner} className={styles.roadRunner} alt="" />
          <span>{roadRunners} RoadRunners</span>
        </div>
      </div>
      <div className={styles.infoContainer}>
        <div className={styles.info}>
          <img src={clock} className={styles.clock} alt="" />
          <span>
            {userRoadmap
              ? userRoadmap.Roadmap.minEstimation
              : roadmap.minEstimation}
            -
            {userRoadmap
              ? userRoadmap.Roadmap.maxEstimation
              : roadmap.maxEstimation}
            M
          </span>
        </div>
        <div className={styles.info}>
          <img src={level} className={styles.level} alt="" />
          <span>
            {userRoadmap ? userRoadmap.Roadmap.difficulty : roadmap.difficulty}
          </span>
        </div>
      </div>
      <div className={styles.roadmapPicContainer}>
        <img src={roadmapPic} className={styles.roadmapPic} alt="" />
      </div>
    </div>
  );
};

export default RoadmapDetailsHeader;
