import React, { useState } from "react";

import classes from "./SearchBox.module.css";
import date from "../../../assets/images/Home/icons/searchDate.png";
import search from "../../../assets/images/Home/icons/search.png";
import FilterCalender from "./FilterCalender";
import axiosInstance from "../../../request/axiosInstance";
import { getTodoExtraClass } from "../../../utils/Utils";

function SearcBox(props) {
  const [searchTerm, setSearchTerm] = useState(null);
  const [showCalender, setShowCalender] = useState(false);

  const ChangeHandler = (e) => {
    setSearchTerm(e.target.value);
  };

  const onSearch = (e) => {
    if (e.key === "Enter") {
      if (searchTerm.trim()) {
        if (props.isPersonalTodo) {
          props.setPersonalLoading(true);

          axiosInstance
            .get("/personal-todo", {
              params: {
                filter: ["m", "n"],
                search: searchTerm,
              },
            })
            .then((res) => {
              const personalTodos = res.data.map((pTodo) => {
                const deadline = new Date(pTodo.deadline);
                console.log(deadline);

                let extraClass = getTodoExtraClass(
                  pTodo.status,
                  pTodo.deadline
                );
                return {
                  id: pTodo.id,
                  title: pTodo.title,
                  time:
                    pTodo.deadline !== null
                      ? deadline.toLocaleTimeString(navigator.language, {
                          hour: "2-digit",
                          minute: "2-digit",
                          hour12: false,
                        })
                      : "",
                  deadline: pTodo.deadline,
                  repeatStartDate: pTodo.repeatStartDate,
                  repeatEndDate: pTodo.repeatEndDate,
                  description: pTodo.description,
                  extraClass: extraClass,
                };
              });

              props.setPersonalItems(personalTodos);
            })
            .finally(() => props.setPersonalLoading(false));
        } else {
          props.setRoadmapLoading(true);
          axiosInstance
            .get("/roadrunner/roadmap-todo", {
              params: {
                filter: ["n", "m"],
                search: searchTerm,
              },
            })
            .then((res) => {
              const roadmapStepsTodo = res.data.map((step) => {
                const deadline = new Date(step.dueDateTo);
                const roadmapName = step.RoadmapEntity.Roadmap.title;
                const stepName = step.RoadmapEntity.title;
                const tasks = step.SubSteps.map((subtask) => {
                  return {
                    id: subtask.id,
                    title: subtask.RoadmapEntity.title,
                    isDone: subtask.status === "done",
                    priority: subtask.priority,
                  };
                });

                const extraClass = getTodoExtraClass(
                  step.status,
                  step.dueDateTo
                );

                return {
                  id: step.id,
                  name: roadmapName,
                  step: stepName,
                  url: `/user-roadmap/${step.userRoadmapId}`,
                  time:
                    step.dueDateTo !== null
                      ? `${deadline.getDate()} ${deadline.toLocaleString(
                          "default",
                          {
                            month: "short",
                          }
                        )}`
                      : "",
                  isDone: step.status === "done",
                  tasks: tasks,
                  extraClass: extraClass,
                };
              });

              props.setRoadmapItems(roadmapStepsTodo);
            })
            .finally(() => props.setRoadmapLoading(false));
        }
        setSearchTerm("");
      }
    }
  };

  return (
    <>
      <div
        className={classes.backdrop}
        style={showCalender ? {} : { display: "none" }}
      ></div>
      <FilterCalender
        show={showCalender}
        setShow={setShowCalender}
        isPersonalTodo={props.isPersonalTodo}
        setPersonalItems={props.setPersonalItems}
        setPersonalLoading={props.setPersonalLoading}
        setRoadmapItems={props.setRoadmapItems}
        setRoadmapLoading={props.setRoadmapLoading}
      />
      <label className={classes.searchContainer}>
        <div className={classes.searchIcon}>
          <img src={search} alt="" />
        </div>
        <input
          type="text"
          value={searchTerm}
          onChange={ChangeHandler}
          onKeyPress={onSearch}
          placeholder="Search"
          className={classes.search}
        />
        <div className={classes.dateIcon} onClick={() => setShowCalender(true)}>
          <img src={date} alt="" />
        </div>
      </label>
    </>
  );
}

export default SearcBox;
