import React, { useEffect, useState } from "react";
import AllTodos from "../AllTodos/AllTodos";
import AllTodosHeader from "../AllTodosHeader/AllTodosHeader";

import classes from "./AllPersonalTodos.module.css";
import addTodo from "../../../assets/images/Home/icons/addTodo.png";
import AddTodoModal from "../AddTodoModal/AddTodoModal";
import DueDateModal from "../AddTodoModal/DueDateModal";
import axiosInstance from "../../../request/axiosInstance";
import { getTodoExtraClass } from "../../../utils/Utils";

function AllPersonalTodos() {
  const [openAddModal, setOpenAddModal] = useState(false);
  const [openDueModal, setOpenDueModal] = useState(false);
  const [name, setName] = useState("");
  const [desc, setDesc] = useState("");
  const [personalLoading, setPersonalLoading] = useState(true);
  const [personalItems, setPersonalItems] = useState([]);

  const addPersonalTodoHandler = () => {
    axiosInstance.post("/personal-todo", {
      title: name,
      description: desc,
      repeatType: "none",
    });
  };

  useEffect(() => {
    const today = new Date();

    axiosInstance
      .get("/personal-todo", {
        params: {
          filter: ["n", "x"],
        },
      })
      .then((res) => {
        const personalTodos = res.data.map((pTodo) => {
          const deadline = new Date(pTodo.deadline);
          let extraClass = getTodoExtraClass(pTodo.status, pTodo.deadline);
          console.log(deadline, extraClass);

          return {
            id: pTodo.id,
            title: pTodo.title,
            time:
              extraClass !== "nodate"
                ? deadline.toLocaleTimeString(navigator.language, {
                    hour: "2-digit",
                    minute: "2-digit",
                    hour12: true,
                  })
                : "",
            deadline: pTodo.deadline,
            repeatStartDate: pTodo.repeatStartDate,
            repeatEndDate: pTodo.repeatEndDate,
            description: pTodo.description,
            extraClass: extraClass,
          };
        });

        setPersonalItems(personalTodos);
      })
      .finally(() => setPersonalLoading(false));
  }, []);

  return (
    <div className={classes.container}>
      <AllTodosHeader title={"Personal To Do"} />
      <AllTodos
        isPersonalTodo={true}
        setItems={setPersonalItems}
        items={personalItems}
        loading={personalLoading}
        setPersonalItems={setPersonalItems}
        setPersonalLoading={setPersonalLoading}
      />
      <div className={classes.addButton}>
        <img src={addTodo} alt="" onClick={() => setOpenAddModal(true)} />
      </div>
      {openAddModal && (
        <AddTodoModal
          onClose={() => setOpenAddModal(false)}
          onNext={() => {
            setOpenAddModal(false);
            setOpenDueModal(true);
          }}
          title={"Task Name"}
          setPersonalItems={setPersonalItems}
          addTodo={addPersonalTodoHandler}
          name={name}
          desc={desc}
          setName={setName}
          setDesc={setDesc}
        />
      )}
      {openDueModal && (
        <DueDateModal
          title={""}
          onBack={() => {
            setOpenAddModal(true);
            setOpenDueModal(false);
          }}
          onClose={() => {
            setOpenDueModal(false);
            setOpenAddModal(false);
          }}
          setPersonalItems={setPersonalItems}
          name={name}
          desc={desc}
          setName={setName}
          setDesc={setDesc}
        />
      )}
    </div>
  );
}

export default AllPersonalTodos;
