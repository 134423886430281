import styles from "./TabContentContainer.module.css";

const TabContentContainer = (props) => {
  return (
    <div className={styles.container}>
      <div className={styles.layout} id="scrollable">
        {props.children}
      </div>
    </div>
  );
};

export default TabContentContainer;
