import React, { useState } from "react";

import classes from "./RepeatRange.module.css";
import RangeCalendar from "./RangeCalendar";
import { calculateDateRange } from "../../../../utils/Utils";

function RepeatRange({ repeatWay, setRepeatWay, repeatRange, setRepeatRange }) {
  const [isOpen, setIsOpen] = useState(false);
  const [rangeOpen, setRangeOpen] = useState(false);
  const [range, setRange] = useState(repeatRange);

  const onClickHandler = (val) => {
    setRepeatWay(val);
    setIsOpen(false);
  };
  return (
    <div>
      <div className={classes.repeatField}>
        <p>Repeat Range</p>
        <div onClick={() => setIsOpen(true)}>
          {range === null
            ? repeatWay
            : calculateDateRange({ openedOnce: true }, range, "1-2 Jul")}
        </div>
      </div>
      <div>
        <RangeCalendar
          show={rangeOpen}
          setShow={setRangeOpen}
          range={range}
          setRange={setRange}
          setRepeatRange={setRepeatRange}
        />
      </div>
      {isOpen && (
        <div className={classes["repeat-popup"]}>
          <div className={classes.repeatVals}>
            <p
              onClick={() => {
                onClickHandler("Select range");
                setRangeOpen(true);
              }}
              className={repeatWay === "Select range" && classes.selected}
            >
              Select range
            </p>
            <p
              onClick={() => {
                onClickHandler("Forever");
                setRangeOpen(false);
              }}
              className={repeatWay === "Forever" && classes.selected}
            >
              Forever
            </p>
          </div>
        </div>
      )}
    </div>
  );
}

export default RepeatRange;
