import GetStarted from "../components/GetStarted/GetStarted";
import Layout from "../components/UI/Layout/Layout";

const GetStartedPage = () => {
  return (
    <Layout>
      <GetStarted />
    </Layout>
  );
};

export default GetStartedPage;
