import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Progress } from "antd";
import { memo } from "react";

const MainContainer = styled.div`
  width: 100%;
  max-width: 600px;
  overflow-x: auto;
  -ms-overflow-style: none; /* Hide scrollbar in IE and Edge */
  scrollbar-width: none; /* Hide scrollbar in Firefox */

  &::-webkit-scrollbar {
    display: none; /* Hide scrollbar in Chrome, Safari, and Opera */
  }
  margin-bottom: 30px;
`;

const ScrollableWrapper = styled.div`
  display: flex;
  min-width: ${({ stepsCount }) =>
    stepsCount > 5
      ? `${stepsCount * 90}px`
      : "auto"}; /* Adjust the width based on step count */
`;

const StepContainer = styled.div`
  display: flex;
  justify-content: space-between;
  position: relative;
  width: 100%;
  :before {
    content: "";
    position: absolute;
    background: #e6e0e9;
    height: 1px;
    width: 100%;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
  }
  :after {
    content: "";
    position: absolute;
    background: #e6e0e9;
    height: 1px;
    width: ${({ width }) => width};
    top: 50%;
    transition: 0.4s ease;
    transform: translateY(-50%);
    left: 0;
  }
`;

const StepWrapper = styled.div`
  position: relative;
  z-index: 1;
`;

const StepStyle = styled.div`
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background-color: #ffffff;
  border: ${({ step }) =>
    step === "completed" ? "2px solid #23CC85" : "0px solid #E6E0E9"};
  transition: 0.4s ease;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StepCount = styled.span`
  font-size: 19px;
  color: ${({ step }) => (step === "completed" ? "#ffffff" : "#ccc2dc")};
  @media (max-width: 600px) {
    font-size: 16px;
  }
`;

const CheckMark = styled.div`
  font-size: 18px;
  font-weight: 600;
  color: white;
  transform: scaleX(-1) rotate(-46deg);
`;

const ProgressSteps = (props) => {
  const [activeStep, setActiveStep] = useState(1);
  const [currStepPercent, setCurrentStepProgress] = useState({});
  const [steps, setSteps] = useState(null);

  useEffect(() => {
    const len = Object.keys(props.progress).length;
    let count = 0;
    const thisSteps = Object.keys(props.progress).map((step) => {
      if (props.progress[step].status === "done") {
        setCurrentStepProgress((prev) => {
          return { ...prev, [step]: 100 };
        });
        count = count + 1;
        return {
          step: step,
        };
      } else if (props.progress[step].status === "locked") {
        setCurrentStepProgress((prev) => {
          return { ...prev, [step]: 0 };
        });
        return {
          step: step,
        };
      } else {
        setActiveStep(Number(step));
        const currPercent =
          (props.progress[step].detail.done / props.progress[step].detail.all) *
          100;
        setCurrentStepProgress((prev) => {
          return { ...prev, [step]: currPercent };
        });
        return {
          step: step,
        };
      }
    });
    const progressOfRoadmap =
      (count / len) * 100 >= 50 && (count / len) * 100 < 60
        ? 50
        : (count / len) * 100;
    if (progressOfRoadmap === 50 || progressOfRoadmap === 100) {
      props.setRatingModalOpen(true);
    }
    setSteps(thisSteps);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.progress]);

  const totalSteps = steps && steps.length;
  const width = `${(100 / (totalSteps - 1)) * (activeStep - 1)}%`;

  return (
    <MainContainer>
      <ScrollableWrapper stepsCount={totalSteps}>
        <StepContainer width={width}>
          {steps &&
            steps.map(({ step }) => (
              <StepWrapper key={step}>
                <StepStyle
                  step={activeStep > step ? "completed" : "incomplete"}
                >
                  <Progress
                    type="circle"
                    percent={currStepPercent[step]}
                    width={activeStep > step ? 31 : 35}
                    status="success"
                    strokeColor={"#23CC85"}
                    trailColor={"#E6E0E9"}
                    style={
                      activeStep > step
                        ? { background: "#23CC85", borderRadius: "50%" }
                        : {}
                    }
                    strokeWidth={6}
                    format={() =>
                      activeStep > step ? (
                        <CheckMark>L</CheckMark>
                      ) : (
                        <StepCount
                          step={activeStep > step ? "completed" : "incomplete"}
                        >
                          {step}
                        </StepCount>
                      )
                    }
                  />
                </StepStyle>
              </StepWrapper>
            ))}
        </StepContainer>
      </ScrollableWrapper>
    </MainContainer>
  );
};

export default memo(ProgressSteps);
