import React, { useContext, useState, useEffect } from "react";
import Button from "../../UI/Button";
import Input from "../../UI/Input";
import AuthTitle from "../../UI/AuthTitle";
import Timer from "../../UI/Timer";
import UserContext from "../../../store/user-context";
import ErrorMessage from "../../UI/ErrorMessage";
import { shortEmail } from "../../../utils/Utils";
import { useLocation, useNavigate } from "react-router-dom";

import classes from "./SignUpVerification.module.css";
import code from "../../../assets/images/icon/code.png";

const SignUpEmailVerification = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { email, user } = location.state;
  const userCtx = useContext(UserContext);
  const [enteredCode, setEnteredCode] = useState("");
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const activateButton = enteredCode.length === 6;

  console.log(user);

  useEffect(() => {
    const sendCodeInterval = setInterval(() => {
      userCtx.getOtp(email);
    }, 120000);

    userCtx.getOtp(email);

    return () => clearInterval(sendCodeInterval);
  }, [email, userCtx]);

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <div className={classes.container}>
      <div className={classes.backButton}>
        <svg
          onClick={handleBack}
          width="23"
          height="14"
          viewBox="0 0 23 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6.33554 0L0 6.91738L6.33554 13.8348L7.66158 12.3548L3.62452 7.94694H22.5427V5.88781H3.62452L7.66158 1.48L6.33554 0Z"
            fill="#010440"
            fill-opacity="0.7"
          />
        </svg>
      </div>
      <div className={classes.authContainer}>
        <div>
          <AuthTitle
            title="Sign Up"
            pageType="confirm"
            email={shortEmail(email)}
          />

          <div className={classes.inputContainer}>
            <Input
              placeholder="6 digit code"
              source={code}
              icon={"code"}
              value={enteredCode}
              onChange={(code) => setEnteredCode(code)}
            />
            <Timer />
            {error && <ErrorMessage message={error} />}
          </div>
        </div>
        <div className={classes.buttonsContainer}>
          <Button
            onClick={() => {
              userCtx.validateOtp(
                email,
                enteredCode,
                () =>
                  navigate("/signup/createPass", {
                    state: {
                      email,
                      otp: enteredCode,
                      user,
                    },
                  }),
                (value) => setIsLoading(value),
                (value) => setError(value)
              );
            }}
            disabled={!activateButton}
            isLoading={isLoading}
          >
            Next
          </Button>
        </div>
      </div>
    </div>
  );
};

export default SignUpEmailVerification;
