import React from "react";
import SimilarItem from "./SimilarItem";

import classes from "./SimilarRoadmap.module.css";
import c1A from "../../../../assets/images/Roadmap/avatar.png";
import roadmapImg1 from "../../../../assets/images/Roadmap/roadmapImg.png";
import roadmapImg2 from "../../../../assets/images/Roadmap/roadmapImg2.png";

function SimilarRoadmaps() {
  return (
    <div className={classes.contianer}>
      <h1>Similar Roadmaps</h1>
      <div className={classes.items}>
        <SimilarItem
          name={"Front-End Development Learning Plan"}
          creator={"Emily h."}
          creatorAvatar={c1A}
          rate={"3.2"}
          roadrunners={"1K roadrunners"}
          roadmapImg={roadmapImg1}
        />
        <SimilarItem
          name={"UI UX Design"}
          creator={"Emily h."}
          creatorAvatar={c1A}
          rate={"3.2"}
          roadrunners={"1K roadrunners"}
          roadmapImg={roadmapImg2}
        />
        <SimilarItem
          name={"UI UX Design"}
          creator={"Emily h."}
          creatorAvatar={c1A}
          rate={"3.2"}
          roadrunners={"1K roadrunners"}
          roadmapImg={roadmapImg2}
        />
      </div>
    </div>
  );
}

export default SimilarRoadmaps;
