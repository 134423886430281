import React, { useEffect, useRef, useState } from "react";
import { Card } from "react-rainbow-components";
import { useNavigate } from "react-router-dom";

import stepCheck from "../../../../assets/images/Roadmap/icon/stepCheck.png";
import stepLock from "../../../../assets/images/Roadmap/icon/stepLock.png";

import classes from "./RoadmapSingleStep.module.css";
import arrow from "../../../../assets/images/Roadmap/icon/arrowDown.png";
import roadmap from "../../../../assets/images/Roadmap/icon/roadmapIcon.png";
import rightArrow from "../../../../assets/images/Roadmap/icon/arrowRight.png";
import dots from "../../../../assets/images/Roadmap/icon/threeDots.png";
import Menu from "./Menu/Menu";
import StepCalender from "./Calender/StepCalender";
import StepSection from "./StepSection";
import DateInfo from "./DateInfo/DateInfo";

function RoadmapSingleStep(props) {
  const navigate = useNavigate();
  const [show, setShow] = useState(props.stepOpen ? true : false);
  const isRoadmap = props.type === "roadmapStep";
  const itemRef = useRef(null);
  const [itemWidth, setItemWidth] = useState(0);
  const [isDone, setIsDone] = useState(false);
  const [isLocked, setIsLocked] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const [range, setRange] = useState(null);
  const [showCalender, setShowCalender] = useState({
    show: false,
    openedOnce: false,
  });

  useEffect(() => {
    if (itemRef.current) {
      setItemWidth(itemRef.current.offsetWidth);
    }
    if (props.dueDateFrom !== null && props.dueDateTo !== null) {
      const from = new Date(props.dueDateFrom);
      const to = new Date(props.dueDateTo);
      setRange([from, to]);
    }
    if (props.dueDateFrom !== null && props.dueDateTo === null) {
      const from = new Date(props.dueDateFrom);
      setRange([from]);
    }

    if (props.status) {
      setIsDone(props.status === "done");
      setIsLocked(props.status === "locked");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.status]);

  const clickHandler = () => {
    if (props.isUserRoadmap && props.dueDateFrom === null) {
      if (props.type === "roadmapStep" && !isLocked) {
        navigate(`/roadmaps/${props.roadmapRefrence}`);
      } else {
        if (props.started && !showCalender.openedOnce && !isLocked) {
          setShowCalender({ show: true, openedOnce: true });
        }
      }
    }
  };
  const handleFullScreenChat = () => {
    props.setFullScreenChat();
  };

  return (
    <>
      <Card
        className={`rainbow-p-around_large ${classes.calenderContainer}`}
        style={
          showCalender.show
            ? {
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                zIndex: "1000",
                margin: "0 12px",
                width: "85%",
                position: "absolute",
                top: "7%",
                height: "38%",
              }
            : { display: "none" }
        }
      >
        <StepCalender
          isStep={true}
          setRange={setRange}
          entityId={props.id}
          setShowCalender={setShowCalender}
          refreshUserRoadmap={props.handleStepDone}
          setShow={setShow}
        />
      </Card>
      <div
        ref={itemRef}
        className={`${
          !isRoadmap
            ? show
              ? classes.stepOpened
              : classes.step
            : classes.rmStep
        }`}
        onClick={() => clickHandler()}
        id={props.id}
      >
        <div className={classes.stepName}>
          <div
            className={isDone ? classes.stepDone : classes.stepNum}
            style={isRoadmap ? { marginTop: "11%" } : {}}
          >
            {isDone ? (
              <img
                src={stepCheck}
                alt=""
                style={{ width: "16px", height: "10.67px" }}
              />
            ) : (
              props.stepNum
            )}
            {isLocked && (
              <img src={stepLock} alt="" className={classes.stepLock} />
            )}
          </div>
          <div className={classes.stepInfo}>
            <p className={classes.rmName}>{props.name}</p>
            {isRoadmap && (
              <DateInfo
                condition={props.isEnrolled && showCalender.openedOnce}
                urCondition={props.dueDateFrom !== null}
                isUserRoadmap={props.isUserRoadmap}
                range={range}
                showCalender={showCalender}
                stepDuration={props.stepDuration}
                isRoadmap={isRoadmap}
              />
            )}
            {!isRoadmap && (
              <div
                className={classes.menue}
                style={{ width: `${0.79 * itemWidth}px` }}
              >
                {props.started && showMenu ? (
                  <Menu
                    setFullScreenChat={handleFullScreenChat}
                    dueDateEstimaion={props.dueDateEstimaion}
                    dateRange={range}
                    started={props.started}
                    entityId={props.id}
                    dueDateFrom={props.dueDateFrom}
                    dueDateTo={props.dueDateTo}
                    isLocked={isLocked}
                    isDone={isDone}
                    showMenu={showMenu}
                    type={props.type}
                    description={props.description}
                    registerCallback={props.registerCallback}
                    itemWidth={itemWidth}
                    isTask={false}
                  />
                ) : (
                  <DateInfo
                    condition={
                      props.isEnrolled &&
                      props.started &&
                      showCalender.openedOnce
                    }
                    urCondition={props.dueDateFrom !== null && props.started}
                    isUserRoadmap={props.isUserRoadmap}
                    range={range}
                    showCalender={showCalender}
                    stepDuration={props.stepDuration}
                    isRoadmap={isRoadmap}
                  />
                )}
              </div>
            )}
          </div>
          {show && (
            <img
              src={dots}
              alt=""
              className={classes.dots}
              onClick={() => setShowMenu((prev) => !prev)}
            />
          )}
        </div>
        {!isRoadmap && (
          <>
            <div
              className={show ? classes.cardFooterOpened : classes.cardFooter}
            ></div>
            <div
              className={show ? classes.cardPolygonOpened : classes.cardPolygon}
              onClick={() => setShow((prev) => !prev)}
            >
              <div>
                <img src={arrow} alt="" />
              </div>
            </div>
          </>
        )}
        {isRoadmap && (
          <>
            <img src={roadmap} className={classes.rmIcon} alt="" />
            <div className={classes.leftSideCard} />
            <div className={classes.leftSideCardPolygon}>
              <div>
                <img src={rightArrow} alt="" />
              </div>
            </div>
          </>
        )}
      </div>
      <StepSection
        setFullScreenChat={handleFullScreenChat}
        show={show}
        setShow={setShow}
        itemWidth={itemWidth}
        roadmapSetter={props.roadmapSetter}
        handleStepDone={props.handleStepDone}
        subSteps={props.subSteps}
        setIsStepDone={setIsDone}
        isEnrolled={props.isEnrolled}
        started={props.started}
        range={range}
        registerCallback={props.registerCallback}
        setRoadmapModalOpen={props.setRoadmapModalOpen}
      />
    </>
  );
}

export default RoadmapSingleStep;
