import React from "react";

import classes from "./AllTodosHeader.module.css";
import back from "../../../assets/images/Home/icons/back.png";
import { useNavigate } from "react-router-dom";

function AllTodosHeader(props) {
  const navigate = useNavigate();
  return (
    <>
      {" "}
      <div className={classes.back}>
        <img
          src={back}
          alt=""
          onClick={() => {
            navigate(-1);
          }}
        />
      </div>
      <div className={classes.title}>
        <p>{props.title}</p>
      </div>
    </>
  );
}

export default AllTodosHeader;
