import TabContentContainer from "../TabContentContainer/TabContentContainer";

import styles from "./StartOnboardingContent.module.css";
import character from "../../../../assets/images/Explore/character.png";
import { useNavigate } from "react-router-dom";

const StartOnboardingContent = () => {
  const navigate = useNavigate();

  return (
    <TabContentContainer>
      <div className={styles.container}>
        <div className={styles.content}>
          <img src={character} className={styles.char} alt="" />
          <span className={styles.title}>Start Your Journey!</span>
          <span className={styles.description}>
            Complete the onboarding to get personalized roadmaps tailored just
            for you!
          </span>
          <button
            className={styles.button}
            onClick={() => navigate(`/onboarding/`)}
          >
            Start Onboarding
          </button>
        </div>
      </div>
    </TabContentContainer>
  );
};

export default StartOnboardingContent;
