import React from "react";
import { Avatar } from "react-rainbow-components";

import classes from "./SimilarItem.module.css";
import star from "../../../../assets/images/Roadmap/icon/star.png";
import roadrunner from "../../../../assets/images/Roadmap/icon/roadRunner.png";

function SimilarItem(props) {
  return (
    <div className={classes.item}>
      <div className={classes.img}>
        <img src={props.roadmapImg} alt="" />
      </div>
      <div className={classes.name}>
        <p>{props.name}</p>
      </div>
      <div className={classes.stats}>
        <p>
          <Avatar
            src={props.creatorAvatar}
            assistiveText="Emily h."
            title="Emily h."
            size="x-small"
          />
          {props.creator}
        </p>
        <p>
          <img src={star} alt="" className={classes.itemIcon} /> {props.rate}
        </p>
        <p>
          <img src={roadrunner} alt="" className={classes.itemIcon} />
          {props.roadrunners}
        </p>
      </div>
    </div>
  );
}

export default SimilarItem;
