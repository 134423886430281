import React, { useEffect, useState } from "react";
import Checkbox from "react-custom-checkbox";
import { Input, Spin } from "antd";
import styles from "./ToDoList.module.css";
import check from "../../../../../assets/images/Roadmap/icon/checkIcon.png";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import useRequest from "../../../../../requests/request";

const ToDoList = ({ id, type, node }) => {
  const { data, loading, patch, get, del, post } = useRequest();
  const [tasks, setTasks] = useState([]);
  const [addTask, setAddTask] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [editInputValue, setEditInputValue] = useState("");

  useEffect(() => {
    const token = localStorage.getItem("token");
    get(`/roadrunner/checklist/get/${id}`, {
      headers: {
        Authorization: "Bearer " + JSON.parse(token),
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (data && data.length >= 0) {
      setTasks(
        data.map((task) => {
          return {
            id: task.id,
            title: task.title,
            completed: task.status === "done" ? true : false,
            isEditing: false,
          };
        })
      );
    }
  }, [data]);

  const completeTaskHandler = (id) => {
    const token = localStorage.getItem("token");
    const editedTasks = tasks.map((task) => {
      if (task.id === id) {
        return { ...task, completed: !task.completed };
      }
      return task;
    });
    const updatedTask = editedTasks.find((task) => task.id === id);
    setTasks(editedTasks);
    patch(
      `/roadrunner/checklist/update/${id}`,
      { status: updatedTask.completed ? "done" : "pending" },
      {
        headers: {
          Authorization: "Bearer " + JSON.parse(token),
        },
      }
    );
  };

  const taskEditorHandler = (id) => {
    const editedTasks = tasks.map((task) => {
      if (task.id === id) {
        return { ...task, isEditing: true };
      }
      return task;
    });
    setTasks(editedTasks);
    setEditInputValue(tasks.find((task) => task.id === id).title);
  };

  const handleEditInputChange = (e) => {
    setEditInputValue(e.target.value);
  };

  const editItemHandler = (id, e) => {
    const token = localStorage.getItem("token");
    if (e.key === "Enter") {
      if (editInputValue.trim() === "") {
        const filteredTasks = tasks.filter((task) => task.id !== id);
        setTasks(filteredTasks);
        console.log(id);
        del(`/roadrunner/checklist/delete/${id}`, {
          headers: {
            Authorization: "Bearer " + JSON.parse(token),
          },
        });
      } else {
        const editedTasks = tasks.map((task) => {
          if (task.id === id) {
            return { ...task, title: editInputValue, isEditing: false };
          }
          return task;
        });
        setTasks(editedTasks);
      }
    }
  };

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleAddItem = (e) => {
    const token = localStorage.getItem("token");
    if (e.key === "Enter") {
      if (inputValue.trim()) {
        setTasks([
          ...tasks,
          {
            id: Math.floor(Math.random() * 1000),
            title: inputValue,
            completed: false,
            isEditing: false,
          },
        ]);
        setInputValue("");
      }
      setAddTask(false);
      post(
        `/roadrunner/checklist/add/${id}`,
        {
          title: inputValue,
          priority: Math.floor(Math.random() * 1000),
        },
        {
          headers: {
            Authorization: "Bearer " + JSON.parse(token),
          },
        },
        (task) => {
          setTasks([
            ...tasks,
            {
              id: task.id,
              title: inputValue,
              completed: false,
              isEditing: false,
            },
          ]);
        }
      );
    }
  };

  return (
    <>
      <ul className={styles.todoList}>
        {loading ? (
          <Spin indicator={<LoadingOutlined spin />} size="small" />
        ) : (
          <>
            {tasks.map((task) => (
              <li key={task.id} className={styles.todoItem}>
                <Checkbox
                  size={10}
                  value={task.completed}
                  checked={task.completed}
                  onChange={() => completeTaskHandler(task.id)}
                  icon={
                    <img src={check} style={{ width: 7, height: 5 }} alt="" />
                  }
                  borderColor={`${
                    task.completed
                      ? "rgba(35, 204, 133, 1)"
                      : "rgba(202, 196, 208, 1)"
                  }`}
                />
                {task.isEditing ? (
                  <Input
                    variant="borderless"
                    value={editInputValue}
                    onChange={handleEditInputChange}
                    onKeyPress={(e) => editItemHandler(task.id, e)}
                  />
                ) : (
                  <span onClick={() => taskEditorHandler(task.id)}>
                    {task.title}
                  </span>
                )}
              </li>
            ))}
            {addTask && (
              <li className={styles.todoItem}>
                <Input
                  placeholder="Enter a new Item"
                  variant="borderless"
                  value={inputValue}
                  onChange={handleInputChange}
                  onKeyPress={handleAddItem}
                />
              </li>
            )}
          </>
        )}
      </ul>
      <div className={styles.addToDo} onClick={() => setAddTask(true)}>
        <PlusOutlined />
        <span>Add To Do</span>
      </div>
    </>
  );
};

export default ToDoList;
