import React from 'react';


import classes from "./CheckBox.module.css";

function CheckBox(props) {
    return (
        <>
            <input className={classes.checkBox} type='checkbox' value={props.isChecked} onChange={props.onClick}/>
        </>
    );
}

export default CheckBox;
