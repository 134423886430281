import { useNavigate } from "react-router-dom";
import { Avatar } from "antd";
import styles from "./ExploreSingleItem.module.css";
import { formatCompactNumber, shortName } from "../../../../utils/Utils";

import star from "../../../../assets/images/Explore/icon/star.png";
import roadRunner from "../../../../assets/images/Explore/icon/roadRunner.png";
import bRoadRunner from "../../../../assets/images/Explore/icon/bRoadRunner.png";
import crown from "../../../../assets/images/Explore/icon/crown.png";
import avatar from "../../../../assets/images/Explore/items/avatar.png";

const ExploreSingleItem = ({
  type,
  roadmapId,
  pic,
  title,
  // avatar,
  roadmapOwnre,
  rate,
  roadRunners,
  premium,
}) => {
  const navigate = useNavigate();

  const clickHandler = () => {
    navigate(`/roadmaps/${roadmapId}`);
  };
  return (
    <>
      {type === "horizontal" && (
        <div className={styles.hItem} onClick={clickHandler}>
          <div className={styles.hItemPic}>
            {premium && (
              <div className={styles.premium}>
                <img
                  src={crown}
                  style={{ width: "15.71px", height: "12.86px" }}
                  alt=""
                />
              </div>
            )}
            <img src={pic} alt="" />
          </div>
          <div className={styles.hItemInfo}>
            <div className={styles.titleContainer}>
              <p className={styles.title}>{title}</p>
            </div>
            <div>
              <div className={styles.hAvatarContainer}>
                <Avatar src={avatar} size={15} />
                <span className={styles.info}>{shortName(roadmapOwnre)}</span>
              </div>
              <div className={styles.footer}>
                <div className={styles.rateContainer}>
                  <img src={star} alt="" />
                  <span className={styles.info}>{rate}</span>
                </div>
                <div className={styles.roadRunnerContainer}>
                  <img src={roadRunner} alt="" />
                  <span className={styles.info}>
                    {formatCompactNumber(roadRunners)}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {type === "vertical" && (
        <div className={styles.vItem} onClick={clickHandler}>
          <div className={styles.vItemPic}>
            {premium && (
              <div className={styles.premium}>
                <img
                  src={crown}
                  style={{ width: "15.71px", height: "12.86px" }}
                  alt=""
                />
              </div>
            )}
            <img src={pic} alt="" />
          </div>
          <div className={styles.vItemInfo}>
            <div className={styles.titleContainer}>
              <p className={styles.title}>{title}</p>
            </div>
            <div>
              <div className={styles.vAvatarContainer}>
                <Avatar src={avatar} size={15} />
                <span className={styles.info}>{shortName(roadmapOwnre)}</span>
              </div>
              <div className={styles.footer}>
                <div className={styles.rateContainer}>
                  <img src={star} alt="" />
                  <span className={styles.info}>{rate}</span>
                </div>
                <div className={styles.roadRunnerContainer}>
                  <img src={roadRunner} alt="" />
                  <span className={styles.info}>
                    {formatCompactNumber(roadRunners)}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {type === "square" && (
        <div className={styles.sItem} onClick={clickHandler}>
          <img src={pic} alt="" />
          {premium && (
            <div className={styles.sPremium}>
              <img
                src={crown}
                style={{ width: "15.71px", height: "12.86px" }}
                alt=""
              />
            </div>
          )}
          <div className={styles.sInfoContainer}>
            <div className={styles.titleContainer}>
              <p className={styles.sTitle}>{title}</p>
            </div>
            <div>
              <div className={styles.sAvatarContainer}>
                <Avatar src={avatar} size={15} />
                <span className={styles.sInfo}>{shortName(roadmapOwnre)}</span>
              </div>
              <div className={styles.sFooter}>
                <div className={styles.rateContainer}>
                  <img
                    src={star}
                    style={{ width: "13px", height: "13px" }}
                    alt=""
                  />
                  <span className={styles.sInfo}>{rate}</span>
                </div>
                <div className={styles.roadRunnerContainer}>
                  <img
                    src={bRoadRunner}
                    style={{ width: "10px", height: "10.82px" }}
                    alt=""
                  />
                  <span className={styles.sInfo}>
                    {formatCompactNumber(roadRunners)}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {type === "square2" && (
        <div className={styles.s2Item} onClick={clickHandler}>
          <img src={pic} alt="" />
          {premium && (
            <div className={styles.s2Premium}>
              <img
                src={crown}
                style={{ width: "12.14px", height: "10.24px" }}
                alt=""
              />
            </div>
          )}
          <div className={styles.s2TitleContainer}>
            <p className={styles.s2Title}>{`${title.substring(0, 11)}${title.length>11 ? "..." : ""}`}</p>
          </div>
        </div>
      )}
    </>
  );
};

export default ExploreSingleItem;
