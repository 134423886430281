import React from "react";
import Layout from "../components/UI/Layout/Layout";
import SignUp from "../components/SignUp/SignUpEmail/SignUp";
import {useParams} from "react-router-dom";
import SignUpVerification from "../components/SignUp/SignUpVerification/SignUpVerification";
import SignUpCreatePass from "../components/SignUp/SignUpCreatePass/SignUpCreatePass";


function SignUpPage() {
    const params = useParams();

    let currentPage = <SignUp/>;

    switch (params.pageName) {
        case 'verification':
            currentPage = <SignUpVerification/>;
            break;
        case 'createPass':
            currentPage = <SignUpCreatePass/>;
            break;
        default:
            break;
    }

    return <Layout>
        {currentPage}
    </Layout>

}

export default SignUpPage;
