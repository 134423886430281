import React, { createContext, useState, useEffect } from "react";
import axios from "axios";

const api_url = process.env.REACT_APP_API_URL;

const UserContext = createContext({
  user: {},
  token: "",
  isLoading: false,
  roadmaps: [],
  roadmap: {},
  signUp: (email, otp, password, user, setError, isLoading, navigate) => {},
  logIn: (email, password, userInfo, setError, setIsLoading, navigate) => {},
  logOut: (navigate) => {},
  getOtp: (email) => {},
  validateOtp: (email, otp, navigate, setIsLoading, setError) => {},
  checkEmail: (email, setIsLoading, setError, setIsOpen, navigate) => {},
  checkUsername: (username, setIsLoading, setError, navigate) => {},
  updatePassword: (email, password, otp, setIsLoading, navigate) => {},
  getUserCategories: (setLoading) => {},
  getUserIntrests: (setLoading) => {},
  getRoadmaps: (setLoading) => {},
  getRoadmapById: (id, setLoading, navigate) => {},
  getUser: () => {},
  googleSignUp: () => {},
  setUser: () => {},
});

const initailToken = () => {
  const token = localStorage.getItem("token");
  return token ? JSON.parse(token) : null;
};

const initailUser = () => {
  const user = localStorage.getItem("user");
  return user ? JSON.parse(user) : {};
};

export const UserContextProvider = (props) => {
  const [token, setToken] = useState(initailToken);
  const [user, setUser] = useState(initailUser);
  const [mainIsLoading, setMainIsLoading] = useState(false);

  // useEffect(() => {
  //   localStorage.setItem("token", JSON.stringify(token));
  // }, [token]);

  useEffect(() => {
    localStorage.setItem("user", JSON.stringify(user));
  }, [user]);

  // change the place
  // const getRoadmapsHandler = (setLoading) => {
  //   const userToken = localStorage.getItem("token");

  //   setLoading(true);
  //   get(`/Roadmap`, {
  //     headers: {
  //       Authorization: "Bearer " + JSON.parse(userToken),
  //     },
  //   })
  //     .then((res) => setRoadmaps(res.data))
  //     .catch((err) => console.log(err.message))
  //     .finally(() => setLoading(false));
  // };

  // const getRoadmapByIdHandler = (id, setLoading, navigate) => {
  //   const userToken = localStorage.getItem("token");

  //   setLoading(true);
  //   axios
  //     .get(api_url + `/Roadmap/${id}/detail`, {
  //       headers: {
  //         Authorization: "Bearer " + JSON.parse(userToken),
  //       },
  //     })
  //     .then((res) => {
  //       setRoadmap(res.data);
  //       navigate(res.data);
  //       console.log(res.data.title);
  //     })
  //     .catch((err) => console.log(err))
  //     .finally(() => setLoading(false));
  // };

  const signUpHandler = (
    email,
    otp,
    password,
    userInfo,
    setError,
    isLoading,
    navigate
  ) => {
    isLoading(true);
    axios({
      method: "post",
      url: api_url + "/auth/register",
      data: {
        email: email,
        password: password,
        otp: otp,
      },
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        if (res.status === 201) {
          const stringToken = JSON.stringify(res.data.access_token);
          const stringUser = JSON.stringify({
            email: email,
            ...userInfo,
          });
          setUser({ email, ...userInfo });
          setToken(res.data.access_token);
          localStorage.setItem("token", stringToken);
          localStorage.setItem("user", stringUser);
          if (userInfo) {
            onBoardUserHandler(
              userInfo.intrests,
              userInfo.name,
              userInfo.birthDate,
              userInfo.categories,
              res.data.access_token
            );
          }

          navigate();
        }
      })
      .catch((err) => {
        setError(err.response.data.message);
      })
      .finally(() => isLoading(false));
  };
  const logInHandler = (
    email,
    password,
    userInfo,
    setError,
    setIsLoading,
    navigate
  ) => {
    setIsLoading(true);
    axios
      .post(
        api_url + "/auth/login",
        {
          email: email,
          password: password,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        const stringToken = JSON.stringify(res.data.access_token);
        const stringUser = JSON.stringify({
          ...JSON.parse(res.config.data),
          ...userInfo,
        });
        setUser({ ...JSON.parse(res.config.data), ...userInfo });
        setToken(res.data.access_token);
        localStorage.setItem("token", stringToken);
        localStorage.setItem("user", stringUser);
        getUserCategoriesHandler((val) => setIsLoading(val));
        getUserIntrestsHandler((val) => setIsLoading(val));

        navigate();
      })
      .catch((err) => setError("Email not found or password incorrect."))
      .finally(() => setIsLoading(false));
  };

  const onBoardUserHandler = (
    intrests,
    name,
    birthDate,
    categories,
    userToken
  ) => {
    let intrestIds = [];

    intrests.forEach((intrest) => {
      intrest.subCat.forEach((subCat) => {
        if (subCat !== null) {
          if (subCat.selected === true) {
            intrestIds.push(subCat.id);
          }
        }
      });
    });
    axios
      .patch(
        api_url + "/onboarding/user-categories",
        {
          categories: categories.map((cat) => cat.id),
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + JSON.parse(userToken),
          },
        }
      )
      .then((res) => console.log(res))
      .catch((err) => console.log(err));
    axios
      .patch(
        api_url + "/onboarding/user-interests",
        {
          interests: intrestIds,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + JSON.parse(userToken),
          },
        }
      )
      .then((res) => console.log(res))
      .catch((err) => console.log(err));
    axios
      .patch(
        api_url + "/onboarding/user-info",
        {
          fullName: name,
          birthdate: new Date(birthDate).toISOString(),
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + JSON.parse(userToken),
          },
        }
      )
      .then((res) => console.log(res))
      .catch((err) => console.log(err));
  };

  const getUserCategoriesHandler = (setLoading) => {
    setLoading(true);
    const userToken = localStorage.getItem("token");
    const currUser = localStorage.getItem("user");

    axios
      .get(api_url + "/onboarding/user-categories", {
        headers: {
          Authorization: "Bearer " + JSON.parse(userToken),
        },
      })
      .then((res) => {
        if (res.data.length > 0) {
          setUser((prev) => {
            return {
              selectedCategories: res.data,
              ...JSON.parse(currUser),
              ...prev,
            };
          });
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setLoading(false));
  };

  const getUserIntrestsHandler = (setLoading) => {
    setLoading(true);

    const userToken = localStorage.getItem("token");
    const currUser = localStorage.getItem("user");

    axios
      .get(api_url + "/onboarding/user-interests", {
        headers: {
          Authorization: "Bearer " + JSON.parse(userToken),
        },
      })
      .then((res) => {
        if (res.data.length > 0) {
          setUser((prev) => {
            return {
              selectedInterests: res.data,
              ...JSON.parse(currUser),
              ...prev,
            };
          });
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setLoading(false));
  };

  const getCurrentUser = () => {
    const currToken = localStorage.getItem("token");
    const thisToken = "Bearer " + JSON.parse(currToken);

    axios
      .get(api_url + "/user", {
        headers: {
          Authorization: thisToken,
        },
      })
      .then((res) =>
        setUser((prev) => {
          return { ...prev, ...res.data };
        })
      )
      .catch((err) => console.log(err));
  };

  const logOutHandler = (navigate) => {
    setToken(null);
    setUser({});
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    navigate();
  };

  const getOtpHandler = (email) => {
    axios.post(api_url + "/auth/request-otp", {
      email: email,
    });
  };

  const validateOtpHandler = (email, otp, navigate, setIsLoading, setError) => {
    setIsLoading(true);
    axios
      .post(
        api_url + "/auth/check-otp",
        {
          email: email,
          otp: otp,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        if (res.data) {
          navigate();
        } else {
          setError("Invalid OTP code. Please try again.");
        }
      })
      .catch((err) => setError("Invalid OTP code. Please try again."))
      .finally(() => setIsLoading(false));
  };

  const checkEmailHandler = (
    email,
    setIsLoading,
    setError,
    setIsOpen,
    navigate
  ) => {
    setIsLoading(true);
    axios
      .post(api_url + "/auth/register", {
        email: email,
        password: "12345Aaa",
        otp: "123456",
      })
      .then((res) => {
        if (res.status === 201) {
          navigate();
        }
      })
      .catch((err) => {
        if (
          err.response.data.message === "Invalid OTP code. Please try again."
        ) {
          navigate();
        }
        setIsOpen(true);
        setError(err.response.data.message);
      })
      .finally(() => setIsLoading(false));
  };

  const updatePasswordHandler = (
    email,
    password,
    otp,
    setIsLoading,
    navigate
  ) => {
    setIsLoading(true);
    axios
      .post(
        api_url + "/auth/change-password",
        {
          email: email,
          password: password,
          otp: otp,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        if (res.status === 201) {
          navigate();
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setIsLoading(false));
  };

  const isLoggedIn = async () => {
    setMainIsLoading(true);
    try {
      const userToken = await localStorage.getItem("token");
      const currUser = await localStorage.getItem("user");
      const parsedUser = JSON.parse(currUser);
      setToken(userToken);
      setUser(parsedUser);
    } catch (error) {
      console.log(`isloggedIn error ${error}`);
    } finally {
      setMainIsLoading(false);
    }
  };

  useEffect(() => {
    isLoggedIn();
    // const userToken = localStorage.removeItem('token');
    // const currUser = localStorage.removeItem('user');
  }, []);

  const googleSignUpHandler = async () => {
    const authUrl = api_url + "/auth/google";
    window.location.href = authUrl;
  };

  const value = {
    user: user,
    token: token,
    isLoading: mainIsLoading,
    signUp: signUpHandler,
    logIn: logInHandler,
    logOut: logOutHandler,
    getOtp: getOtpHandler,
    validateOtp: validateOtpHandler,
    checkEmail: checkEmailHandler,
    updatePassword: updatePasswordHandler,
    googleSignUp: googleSignUpHandler,
    getUserCategories: getUserCategoriesHandler,
    getUserIntrests: getUserIntrestsHandler,
    getUser: getCurrentUser,
    setUser: setUser,
    onBoardUser: onBoardUserHandler,
  };

  return (
    <UserContext.Provider value={value}>{props.children}</UserContext.Provider>
  );
};

export default UserContext;
