import React, { useState } from "react";

import styles from "./Popup.module.css";
import cancel from "../../../assets/images/Home/icons/cancle.png";

const sortOptions = [
  { title: "Due Date & Time" },
  { title: "Newest First" },
  { title: "Oldest First" },
];

function Popup(props) {
  if (!props.show) {
    return null;
  }
  return (
    <div className={`${styles.container}`}>
      <img
        src={cancel}
        className={styles.cancleButton}
        onClick={props.onClose}
        alt=""
      />
      <p className={styles.header}>Sort by</p>
      {sortOptions.map((item) => (
        <div
          className={styles.itemContainer}
          onClick={() => {
            props.setSelectedFilter(item.title);
            props.onClose();
          }}
        >
          <span>{item.title}</span>
        </div>
      ))}
    </div>
  );
}

export default Popup;
