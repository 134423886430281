import React from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../UI/Button";

import classes from "./onboardingStart.module.css";
import character from "../../../assets/images/onboardingCharacter.png";

function OnboardingStart() {
  const navigate = useNavigate();

  const handleNextClick = () => {
    navigate(`/onboarding/onboarding-name`);
  };

  const handleSkip = () => {
    navigate("/explore");
  };

  return (
    <>
      <div className={classes.background}>
        <div className={classes.progress}>
          <hr />
        </div>
        <div className={classes.character}>
          <div className={classes.message}>
            <p>
              Hey! <br />
              I'm excited to get to know you better.
              <br />
              Mind if I ask you a few questions?
            </p>
          </div>
          <img src={character} alt="" />
        </div>
        <div className={classes.control}>
          <button className={classes.skipButton} onClick={handleSkip}>
            Not Now
          </button>
          <Button onClick={handleNextClick} style={{ width: "40%" }}>
            Yes
          </Button>
        </div>
      </div>
    </>
  );
}

export default OnboardingStart;
