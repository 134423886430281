import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import Button from "../../UI/Button";

import classes from "./OnboardingIntrests.module.css";
import character from "../../../assets/images/intrestMessage.png";
import OnboardingIntrestItem from "../OnboardingIntrestItem/OnboardingIntrestItem";
import UserContext from "../../../store/user-context";

const api_url = process.env.REACT_APP_API_URL;

function OnboardingIntrests() {
  const navigate = useNavigate();
  const location = useLocation();
  const [intrests, setIntrests] = useState([]);
  const { name, birthDate, categories } = location.state;
  const userCtx = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(false);
  const [allowToProgress, setAllowToProgress] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    axios
      .post(api_url + "/onboarding/category-interests", {
        categories: categories.map((cat) => cat.id),
      })
      .then((res) => {
        let all_intrests = [];
        all_intrests = categories.map((cat) => {
          return {
            id: cat.id,
            name: cat.name,
            subCat: res.data.map((intrest) =>
              intrest.categoryId === cat.id
                ? {
                    id: intrest.id,
                    name: intrest.name,
                    selected: false,
                  }
                : null
            ),
          };
        });
        setIntrests(all_intrests);
      })
      .finally(() => setIsLoading(false));
  }, [categories]);

  const categoryClicked = (e) => {
    setAllowToProgress(true);
    setIntrests(
      intrests.map((category) => {
        return {
          ...category,
          subCat: category.subCat
            .filter((subCat) => subCat !== null)
            .map((subCat) =>
              // eslint-disable-next-line eqeqeq
              subCat.id == e.target.getAttribute("data-id")
                ? { ...subCat, selected: !subCat.selected }
                : subCat
            ),
        };
      })
    );
  };

  const handleSkip = () => {
    navigate("/");
  };

  const handleBack = () => {
    navigate(-1);
  };

  const handleNextClick = () => {
    if (allowToProgress) {
      const user = {
        name: name,
        birthDate: new Date(birthDate).toISOString(),
        categories,
        intrests,
      };
      if (userCtx.token) {
        const token = localStorage.getItem("token");
        userCtx.onBoardUser(intrests, name, birthDate, categories, token);
      } else {
        userCtx.setUser(user);
      }

      navigate(`/explore`);
    }
  };

  return (
    <div className={classes.background}>
      <div className={classes.progress}>
        <hr />
        <svg
          onClick={handleBack}
          width="23"
          height="14"
          viewBox="0 0 23 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6.33554 0L0 6.91738L6.33554 13.8348L7.66158 12.3548L3.62452 7.94694H22.5427V5.88781H3.62452L7.66158 1.48L6.33554 0Z"
            fill="#010440"
            fill-opacity="0.7"
          />
        </svg>
      </div>
      <div className={classes.container}>
        {" "}
        <div className={classes.character}>
          <img src={character} alt="" />
        </div>
        {isLoading ? (
          <p>loading...</p>
        ) : (
          <div className={classes.categories}>
            {intrests.map((intrest) => (
              <OnboardingIntrestItem
                key={intrest.id}
                category={intrest}
                onClick={categoryClicked}
              />
            ))}
          </div>
        )}
      </div>

      <div className={classes.control}>
        <button className={classes.skipButton} onClick={handleSkip}>
          Skip
        </button>
        <Button onClick={handleNextClick} style={{ width: "40%" }}>
          let’s Expelore
        </Button>
      </div>
    </div>
  );
}

export default OnboardingIntrests;
