import React from "react";

import classes from "./Button.module.css";
import googleIcon from "../../assets/images/icon/google.png";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";

function Button(props) {
  return props.googleButton ? (
    <button className={classes.googleButton} onClick={props.onClick} {...props}>
      <img src={googleIcon} alt="" className={classes.googleIcon} />

      {props.children}
    </button>
  ) : (
    <button className={classes.button} onClick={props.onClick} {...props}>
      {props.isLoading ? (
        <Spin
          indicator={
            <LoadingOutlined
              spin
              style={{ color: "#FFFFFF", fontSize: "30px" }}
            />
          }
        />
      ) : (
        props.children
      )}
    </button>
  );
}

export default Button;
