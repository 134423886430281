import React from "react";
import styles from "./Popup.module.css";
import cancle from "../../../../assets/images/Roadmap/icon/cancle.png";
import share from "../../../../assets/images/Roadmap/icon/share.png";
import flag from "../../../../assets/images/Roadmap/icon/flag.png";
import help from "../../../../assets/images/Roadmap/icon/help.png";

const Popup = (props) => {
  const morePopupItems = [
    { icon: share, title: "Share" },
    { icon: flag, title: "Report" },
    { icon: help, title: "Need help?" },
  ];

  const avatars = props.avatars;

  if (!props.show) {
    return null;
  }

  return (
    <div className={`${styles.container} ${props.morePopup ? styles.morePopup : styles.avatarPopup}`}>
      <img
        src={cancle}
        className={styles.cancleButton}
        onClick={props.onClose}
        alt=""
      />
      {props.morePopup
        ? morePopupItems.map((item) => (
            <div className={styles.itemContainer}>
              <img src={item.icon} className={styles.icon} alt="" />
              <span>{item.title}</span>
            </div>
          ))
        : avatars.map((avatar) => (
            <div className={styles.avatarContainer}>
              <div className={styles.avatarPicContainer}>
                <img src={avatar.src} className={styles.avatarPic} alt="" />
              </div>
              <span>{avatar.title}</span>
            </div>
          ))}
    </div>
  );
};

export default Popup;
