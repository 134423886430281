import TabContentContainer from "../TabContentContainer/TabContentContainer";
import GridBasedContent from "../GridBasedContent/GridBasedContent";
import LoadingSkeleton from "../Skeleton/LoadingSkeleton";

const FilterContent = ({ isLoading, explorItems }) => {
  return (
    <TabContentContainer>
      {explorItems.length === 0 && !isLoading && (
        <p style={{ textAlign: "center" }}>Nothing found.</p>
      )}
      {isLoading && <LoadingSkeleton />}
      {!isLoading && <GridBasedContent items={explorItems.slice(0, 8)} />}
      {!isLoading && <GridBasedContent items={explorItems.slice(9, 12)} />}
    </TabContentContainer>
  );
};

export default FilterContent;
