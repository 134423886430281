import React, { useState } from "react";

import classes from "../AddTodoModal.module.css";

function RepeatDaysSelector({ setSelectedList, selectedList }) {
  const [selected, setSeleted] = useState(selectedList);
  const handleSelect = (val) => {
    setSelectedList((prev) => {
      if (!prev.includes(-1)) {
        if (prev.includes(val)) {
          return [...prev.filter((d) => d !== val)];
        }
        return [...prev, val];
      } else {
        return [val];
      }
    });
    setSeleted((prev) => {
      if (prev.includes(val)) {
        return [...prev.filter((d) => d !== val)];
      }
      return [...prev, val];
    });
  };

  return (
    <div className={classes.repeatDays}>
      <p
        onClick={() => handleSelect("Sun")}
        className={selected.includes("Sun") && classes.selected}
      >
        S
      </p>
      <p
        onClick={() => handleSelect("Mon")}
        className={selected.includes("Mon") && classes.selected}
      >
        M
      </p>
      <p
        onClick={() => handleSelect("Tue")}
        className={selected.includes("Tue") && classes.selected}
      >
        T
      </p>
      <p
        onClick={() => handleSelect("Wed")}
        className={selected.includes("Wed") && classes.selected}
      >
        W
      </p>
      <p
        onClick={() => handleSelect("Thu")}
        className={selected.includes("Thu") && classes.selected}
      >
        T
      </p>
      <p
        onClick={() => handleSelect("Fri")}
        className={selected.includes("Fri") && classes.selected}
      >
        F
      </p>
      <p
        onClick={() => handleSelect("Sat")}
        className={selected.includes("Sat") && classes.selected}
      >
        S
      </p>
    </div>
  );
}

export default RepeatDaysSelector;
