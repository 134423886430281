import React from "react";

import classes from "./OnboardingCategoryItem.module.css";

function OnboardingCategory(props) {
  let subClass = classes.education;

  switch (props.name) {
    case "Fun & Entertainment ":
      subClass = classes.fun;
      break;
    case "Career Growth":
      subClass = classes.career;
      break;
    case "Education":
      subClass = classes.education;
      break;
    case "Relationships":
      subClass = classes.realtion;
      break;
    case "Personal Development":
      subClass = classes.presonalDev;
      break;
    case "Exploration":
      subClass = classes.explore;
      break;
    case " Technology &  Innovation":
      subClass = classes.tech;
      break;
    case "Health  & Fitness":
      subClass = classes.health;
      break;
    case "Art and Design":
      subClass = classes.art;
      break;
    case "Finance & Cryptocurrency":
      subClass = classes.finance;
      break;

    default:
      break;
  }

  return (
    <div
      className={`${classes.category} ${subClass} ${props.selected &&
        classes.selected}`}
      onClick={props.onClick}
      data-id={props.id}
    >
      {/* <div className={classes.circle}>
        <img src={props.img} alt="" />
      </div> */}
      <img onClick={props.onClick} data-id={props.id} src={props.img} alt="" />
      <p onClick={props.onClick} data-id={props.id}>
        {props.name}
      </p>
    </div>
  );
}

export default OnboardingCategory;
