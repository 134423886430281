import { useContext, useEffect, useState } from "react";
import UserContext from "../../store/user-context";

import ExploreHeader from "./ExploreHeader/ExploreHeader";
import ExploreTabs from "./ExploreTabs/ExploreTabs";
import TabBar from "../UI/TabBar/TabBar";

const ExploreContent = () => {
  const userCtx = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const [explorItems, setExploreItems] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    console.log(userCtx.token);

    if (userCtx.token !== null) {
      userCtx.getUserCategories((val) => {
        setLoading(val);
      });
      userCtx.getUserIntrests((val) => setLoading(val));
      userCtx.getUser();
    }
  }, []);

  return (
    <>
      {loading ? (
        <p style={{ textAlign: "center" }}>loading...</p>
      ) : (
        <>
          {" "}
          <ExploreHeader
            setExploreItems={setExploreItems}
            setIsLoading={setIsLoading}
            loading={loading}
          />
          <ExploreTabs
            explorItems={explorItems}
            isLoading={isLoading}
            loading={loading}
          />
          <TabBar />
        </>
      )}
      {/* {userCtx.token === null ? (
        <p>wtf</p>
      ) : (
        <Button onClick={() => userCtx.logOut(() => navigate("/"))}>
          log out
        </Button>
      )} */}
    </>
  );
};

export default ExploreContent;
