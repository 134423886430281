import Chat from "../RoadmapSteps/Chatbot/Chatbot";
import minimize from "../../../../assets/images/Roadmap/icon/minimize.png";

const FullScreenChat = ({ fullScreen, onFullScreen }) => {
  return (
    <div
      style={
        fullScreen
          ? {
              width: "100vw",
              height: "120vh",
              position: "absolute",
              top: "0",
              left: "0",
              zIndex: "10000",
              backgroundColor: "white",
            }
          : { display: "none" }
      }
    >
      <img
        src={minimize}
        alt=""
        style={{
          position: "absolute",
          width: "18px",
          height: "18px",
          top: "8px",
          left: "15px",
          zIndex: "1000000",
        }}
        onClick={onFullScreen}
      />

      <Chat
        avatarWidth={"3.5rem"}
        avatarHeight={"3.5rem"}
        style={
          fullScreen
            ? {
                width: "100%",
                height: "90%",
                position: "absolute",
                top: "0",
                left: "0",
              }
            : {
                display: "none",
              }
        }
      />
    </div>
  );
};

export default FullScreenChat;
