import React, {useEffect, useState} from 'react';

import classes from './Timer.module.css'

const Timer = () => {
    const countdownTime = 300;
    const [time, setTime] = useState(countdownTime);

    useEffect(() => {
        const timer = setInterval(() => {
            if (time > 0) {
                setTime(time - 1);
            }
        }, 1000);

        return () => clearInterval(timer);
    }, [time]);

    const minutes = Math.floor(time / 60);
    const seconds = time % 60;

    return (
        <div className={classes.timerContainer}>
            {time === 0 ? (
                <div style={{cursor:'pointer'}} onClick={() => setTime(countdownTime)}>
                    <p className={classes.timer}>
                        <span className={classes.resend}>Resend</span>
                    </p>
                </div>
            ) : (
                <p className={classes.timer}>
                    <span className={classes.resend}>Resend </span>
                    code in {`${minutes}:${seconds < 10 ? '0' : ''}${seconds}`}
                </p>
            )}
        </div>
    );
};

export default Timer;
