import ExploreSingleItem from "../../ExploreSingleItem/ExploreSingleItem";
import InnerHorizontal from "../InnerHorizontal/InnerHorizontal";
import styles from "./HorizontalT2GriBased.module.css";

const HorizontalT2GriBased = ({ items }) => {
  return (
    <div className={styles.container}>
      <InnerHorizontal items={items.slice(0, 2)} />
      <ExploreSingleItem
        type="horizontal"
        roadmapId={items[2].id}
        pic={items[2].thumbnail}
        title={items[2].title}
        roadmapOwnre={items[2].creator.fullName}
        rate={items[2].averageRate}
        roadRunners={items[2].roadRunners}
        premium={items[2].premium}
      />
    </div>
  );
};

export default HorizontalT2GriBased;
