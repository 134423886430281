import React from "react";

import { Calendar, Card } from "react-rainbow-components";

import classes from "./RangeCalendar.module.css";

function RangeCalendar({ show, range, setRange, setShow, setRepeatRange }) {
  const calenderRangeHandler = () => {
    setRepeatRange(range);
  };
  return (
    <Card
      className={`rainbow-p-around_large ${classes.calenderContainer}`}
      style={
        show
          ? {
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              zIndex: "1000",
              marginTop: "10px",
              width: "100%",
            }
          : { display: "none" }
      }
    >
      <Calendar
        id="calendar-7"
        selectionType="range"
        value={range}
        onChange={(value) => {
          setRange(value);
        }}
        className={classes.calender}
      />
      <div className={classes.calenderButtons}>
        <button
          className={classes.calenderButton}
          onClick={() => {
            calenderRangeHandler();
            setShow(false);
          }}
        >
          Ok
        </button>
        <button
          className={classes.calenderButton}
          onClick={() => {
            setRange(null);
            setShow(false);
          }}
        >
          Cancel
        </button>
      </div>
    </Card>
  );
}

export default RangeCalendar;
