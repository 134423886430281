export const isValidEmail = (email) => {
  const isEmail = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g;
  if (email.match(isEmail)) {
    return true;
  }
  return false;
};

export const isValidUsername = (username) => {
  if (username.length >= 5 && username.length <= 15) {
    return true;
  }
  return false;
};

export const shortEmail = (email) => {
  const emailParts = email.split("@");
  let firstPart = emailParts[0];
  const firstPartSubstring = firstPart.substring(
    0,
    Math.floor(firstPart.length / 2) + 3
  );
  // const stars = new Array(firstPartSubstring.length).join('*');
  firstPart = firstPart.replace(firstPartSubstring, "***");

  return firstPart + "@" + emailParts[1];
};

export const isValidPassword = (pass) => {
  let pattern = new RegExp("^(?=.*[a-z])(?=.*[A-Z])");

  if (pass.length >= 8 && pass.match(/\d+/) !== null && pattern.test(pass)) {
    return true;
  }
  return false;
};

export const isUnequalPass = (pass, passConfirmation) => {
  if (pass !== passConfirmation) {
    return true;
  }
  return false;
};

export const formatCompactNumber = (number) => {
  if (number < 1000) {
    return number;
  } else if (number >= 1000 && number < 1_000_000) {
    return (number / 1000).toFixed(1).replace(/\.0$/, "") + "K";
  }
};

export const datesAreEqual = (date1, date2) => {
  const day1 = date1.getDate();
  const month1 = date1.getMonth();
  const year1 = date1.getFullYear();
  const day2 = date2.getDate();
  const month2 = date2.getMonth();
  const year2 = date2.getFullYear();
  if (day1 === day2 && month1 === month2 && year1 === year2) {
    return true;
  }
  return false;
};

export const calculateDateRange = (showCalender, range, stepDuration) => {
  let calculatedrange = stepDuration;
  if (showCalender.openedOnce && range) {
    if (range[1]) {
      if (
        range[0].toLocaleString("default", {
          month: "short",
        }) ===
        range[1].toLocaleString("default", {
          month: "short",
        })
      ) {
        calculatedrange = `${range[0].getDate()} - ${range[1].getDate()} ${range[1].toLocaleString(
          "default",
          { month: "short" }
        )}`;
      } else {
        calculatedrange = `${range[0].getDate()} ${range[0].toLocaleString(
          "default",
          { month: "short" }
        )} - ${range[1].getDate()} ${range[1].toLocaleString("default", {
          month: "short",
        })}`;
      }
    } else {
      calculatedrange = `${range[0].getDate()} ${range[0].toLocaleString(
        "default",
        { month: "short" }
      )}`;
    }
  }

  return calculatedrange;
};

export function findParentEntityWithSubstep(entities, targetId) {
  for (const entity of entities) {
    if (entity.SubSteps) {
      for (const substep of entity.SubSteps) {
        if (substep.id === targetId) {
          return entity;
        }
        const parentEntity = findParentEntityWithSubstep([substep], targetId);
        if (parentEntity) {
          return parentEntity;
        }
      }
    }
  }
  return null;
}

export const shortName = (fullName) => {
  const nameParts = fullName.trim().split(" ");
  const firstName = nameParts[0];
  const lastName =
    nameParts.length > 1
      ? nameParts[nameParts.length - 1].charAt(0).toUpperCase() + "."
      : "";

  return `${firstName} ${lastName}`;
};

function addDays(date, days) {
  const newDate = new Date(date);
  newDate.setDate(date.getDate() + days);
  return newDate;
}

export const getTodoExtraClass = (status, todoDeadline) => {
  const today = new Date();
  const deadline = new Date(todoDeadline);
  let extraClass = "upcoming";
  if (today > deadline) {
    extraClass = "missed";
  }
  if (deadline > today) {
    extraClass = "future";
  }
  if (todoDeadline === null) {
    extraClass = "nodate";
  }
  if (status === "done") {
    extraClass = "completed";
  }
  return extraClass;
};

export const findIntForDays = (daysList) => {
  let list = [];
  daysList.forEach((day) => {
    switch (day) {
      case "Sun":
        list.push(0);
        break;
      case "Mon":
        list.push(1);
        break;
      case "Tue":
        list.push(2);
        break;
      case "Wed":
        list.push(3);
        break;
      case "Thu":
        list.push(4);
        break;
      case "Fri":
        list.push(5);
        break;
      case "Sat":
        list.push(6);
        break;
      default:
        list.push(-1);
        break;
    }
  });
  return list;
};
