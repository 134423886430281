import { useState } from "react";
import Roadmapsteps from "../RoadmapSteps/Roadmapsteps";
import SimilarRoadmaps from "../SimilarRoadmap/SimilarRoadmaps";
import Button from "../../../UI/Button";

import styles from "./RoadmapDetailsBody.module.css";

const RoadmapDetailsBody = ({
  loading,
  roadmap,
  roadmapSetter,
  isEnrolled,
  started,
  onFullScreenChat,
  registerCallback,
  setRatingModalOpen,
  onStart,
  startLoading,
  onAdd,
  isUserRoadmap,
  handleStepDone,
  currId,
}) => {
  const [showMore, setShowMore] = useState(false);

  let currRoadmap = roadmap.Roadmap
    ? roadmap.Roadmap
    : roadmap.description && roadmap;

  return (
    <div className={styles.detailsContaner}>
      {currRoadmap && (
        <>
          <p className={styles.roadmapCategory}>
            {currRoadmap.type} /{currRoadmap.title}
          </p>
          <div className={styles.roadmapPlanContainer}>
            <p className={styles.description}>
              {showMore
                ? currRoadmap.description
                : `${currRoadmap.description.substring(0, 178)} ...`}
              <span
                className={styles.showButton}
                onClick={() => setShowMore(!showMore)}
              >
                {showMore ? "Show Less" : "Show More"}
              </span>
            </p>
          </div>
          <div className={styles.skillsContainer}>
            <p className={styles.skillsTitle}>Skills</p>
            <div className={styles.skillBoxesContainer}>
              {currRoadmap.skills.map((skill) => (
                <div className={styles.skill}>{skill}</div>
              ))}
            </div>
          </div>
          <Roadmapsteps
            steps={
              roadmap.UserRoadmapEntity
                ? roadmap.UserRoadmapEntity
                : roadmap.RoadmapSteps
            }
            roadmapSetter={roadmapSetter}
            isUserRoadmap={isUserRoadmap}
            handleStepDone={handleStepDone}
            progress={roadmap.progress}
            isEnrolled={isEnrolled}
            started={started}
            setFullScreenChat={onFullScreenChat}
            registerCallback={registerCallback}
            setRatingModalOpen={setRatingModalOpen}
            currId={currId}
          />
          {!started && <SimilarRoadmaps />}
          <div
            className={styles.button}
            style={started ? { display: "none" } : {}}
          >
            {isEnrolled && !started ? (
              <Button
                style={{ width: "90%" }}
                onClick={onStart}
                isLoading={startLoading}
              >
                Start the Roadmap
              </Button>
            ) : (
              <Button
                style={{ width: "90%" }}
                onClick={onAdd}
                isLoading={startLoading}
              >
                Add to my roadmaps
              </Button>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default RoadmapDetailsBody;
