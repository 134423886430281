import React from "react";

import classes from "./AllBadges.module.css";
import FilterBadge from "./FilterBadge";

function AllBadges(props) {
  return (
    <div className={classes.filterBadges}>
      <FilterBadge
        setFilterList={props.setFilterList}
        setPersonalItems={props.setPersonalItems}
        setPersonalLoading={props.setPersonalLoading}
        filterList={props.filterList}
        title={"Upcomming"}
        extraClass={"upcoming"}
        setRoadmapItems={props.setRoadmapItems}
        setRoadmapLoading={props.setRoadmapLoading}
        isPersonalTodo={props.isPersonalTodo}
      />
      <FilterBadge
        setFilterList={props.setFilterList}
        setPersonalItems={props.setPersonalItems}
        setPersonalLoading={props.setPersonalLoading}
        filterList={props.filterList}
        title={"Future"}
        extraClass={"future"}
        setRoadmapItems={props.setRoadmapItems}
        setRoadmapLoading={props.setRoadmapLoading}
        isPersonalTodo={props.isPersonalTodo}
      />
      <FilterBadge
        setFilterList={props.setFilterList}
        setPersonalItems={props.setPersonalItems}
        setPersonalLoading={props.setPersonalLoading}
        filterList={props.filterList}
        title={"Missed"}
        extraClass={"missed"}
        setRoadmapItems={props.setRoadmapItems}
        setRoadmapLoading={props.setRoadmapLoading}
        isPersonalTodo={props.isPersonalTodo}
      />
      <FilterBadge
        setFilterList={props.setFilterList}
        setPersonalItems={props.setPersonalItems}
        setPersonalLoading={props.setPersonalLoading}
        filterList={props.filterList}
        title={"No Date"}
        extraClass={"nodate"}
        setRoadmapItems={props.setRoadmapItems}
        setRoadmapLoading={props.setRoadmapLoading}
        isPersonalTodo={props.isPersonalTodo}
      />
      <FilterBadge
        setFilterList={props.setFilterList}
        setPersonalItems={props.setPersonalItems}
        setPersonalLoading={props.setPersonalLoading}
        filterList={props.filterList}
        title={"Completed"}
        extraClass={"completed"}
        setRoadmapItems={props.setRoadmapItems}
        setRoadmapLoading={props.setRoadmapLoading}
        isPersonalTodo={props.isPersonalTodo}
      />
    </div>
  );
}

export default AllBadges;
