import { Skeleton } from "antd";
import styles from "./LoadingSkeleton.module.css";

const LoadingSkeleton = ({ items }) => {
  return (
    <div className={styles.loadingContainer}>
      <div className={styles.loadingImage}>
            <Skeleton.Image active />
        </div>
        <div className={styles.loadingImage}>
            <Skeleton.Image active />
        </div>
        <div className={styles.loadingImage}>
            <Skeleton.Image active />
        </div>
        <div className={styles.loadingImage}>
            <Skeleton.Image active />
        </div>
        <div className={styles.loadingImage}>
            <Skeleton.Image active />
        </div>
        <div className={styles.loadingImage}>
            <Skeleton.Image active />
        </div>
        <div className={styles.loadingImage}>
            <Skeleton.Image active />
        </div>
        <div className={styles.loadingImage}>
            <Skeleton.Image active />
        </div>
    </div>
  );
};

export default LoadingSkeleton;
