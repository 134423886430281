import { Route, Routes } from "react-router-dom";
import OnboardingPage from "./pages/Onborading";
import Explore from "./pages/Explore";
import SignUpPage from "./pages/SignUp";
import LoginPage from "./pages/Login";
import GoogleRedirectHandler from "./components/GoogleOAuth/GoogleRedirectHandler";
import Roadmap from "./pages/Roadmap";
import Animation from "./pages/Animation";
import UserRoadmapPage from "./pages/UserRoadmap";
import HomePage from "./pages/HomePage";
import GetStartedPage from "./pages/GetStartedPage";
import Logout from "./pages/logout";

function App() {
  return (
    <Routes>
      <Route path="/" element={<GetStartedPage />} />
      <Route path="/onboarding/:pageName?" element={<OnboardingPage />} />
      <Route path="/explore" element={<Explore />} />
      <Route path="/animation" element={<Animation />} />
      <Route path="/login/:pageName?" element={<LoginPage />} />
      <Route path="/signup/:pageName?" element={<SignUpPage />} />
      <Route path="/auth/google/oauth" element={<GoogleRedirectHandler />} />
      <Route path="/roadmaps/:pageId?" element={<Roadmap />} />
      <Route path="/user-roadmap/:pageId?" element={<UserRoadmapPage />} />
      <Route path="/home-page/:todo?" element={<HomePage />} />
      <Route path="/logout" element={<Logout />} />
    </Routes>
  );
}

export default App;
