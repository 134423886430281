import React, { useState } from "react";

import classes from "./FilterBadge.module.css";
import axiosInstance from "../../../request/axiosInstance";
import { getTodoExtraClass } from "../../../utils/Utils";

function FilterBadge(props) {
  const [isSelected, setIsSelected] = useState(false);

  const handleClick = () => {
    setIsSelected((prev) => !prev);
    if (props.isPersonalTodo) {
      props.setPersonalLoading(true);
      let filters = [...props.filterList, props.extraClass];
      if (isSelected) {
        filters = filters.filter((f) => f !== props.extraClass);
      }
      if (filters.length === 0) {
        filters = ["n", "x"];
      }
      props.setFilterList(filters);

      console.log(filters);

      axiosInstance
        .get("/personal-todo", {
          params: {
            filter: filters,
          },
        })
        .then((res) => {
          const personalTodos = res.data.map((pTodo) => {
            const deadline = new Date(pTodo.deadline);
            console.log(deadline);

            let extraClass = getTodoExtraClass(pTodo.status, pTodo.deadline);
            return {
              id: pTodo.id,
              title: pTodo.title,
              time:
                pTodo.deadline !== null
                  ? deadline.toLocaleTimeString(navigator.language, {
                      hour: "2-digit",
                      minute: "2-digit",
                      hour12: false,
                    })
                  : "",
              deadline: pTodo.deadline,
              repeatStartDate: pTodo.repeatStartDate,
              repeatEndDate: pTodo.repeatEndDate,
              description: pTodo.description,
              extraClass: extraClass,
            };
          });

          props.setPersonalItems(personalTodos);
        })
        .finally(() => props.setPersonalLoading(false));
    } else {
      props.setRoadmapLoading(true);
      let filters = [...props.filterList, props.extraClass];
      if (isSelected) {
        filters = filters.filter((f) => f !== props.extraClass);
      }
      if (filters.length === 0) {
        filters = ["v", "x"];
      }
      props.setFilterList(filters);
      console.log(filters);
      axiosInstance
        .get("/roadrunner/roadmap-todo", {
          params: {
            filter: filters,
          },
        })
        .then((res) => {
          const roadmapStepsTodo = res.data.map((step) => {
            const deadline = new Date(step.dueDateTo);
            const roadmapName = step.RoadmapEntity.Roadmap.title;
            const stepName = step.RoadmapEntity.title;
            const tasks = step.SubSteps.map((subtask) => {
              return {
                id: subtask.id,
                title: subtask.RoadmapEntity.title,
                isDone: subtask.status === "done",
                priority: subtask.priority,
              };
            });

            const extraClass = getTodoExtraClass(step.status, step.dueDateTo);

            return {
              id: step.id,
              name: roadmapName,
              step: stepName,
              url: `/user-roadmap/${step.userRoadmapId}`,
              time:
                step.dueDateTo !== null
                  ? `${deadline.getDate()} ${deadline.toLocaleString(
                      "default",
                      {
                        month: "short",
                      }
                    )}`
                  : "",
              isDone: step.status === "done",
              tasks: tasks,
              extraClass: extraClass,
            };
          });

          props.setRoadmapItems(roadmapStepsTodo);
        })
        .finally(() => props.setRoadmapLoading(false));
    }
  };

  return (
    <div
      className={`${classes.contianer} ${
        classes[`${props.extraClass}`]
      } ${isSelected && classes.active}`}
      onClick={handleClick}
    >
      <p>{props.title}</p>
    </div>
  );
}

export default FilterBadge;
