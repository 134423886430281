import React from "react";
import Layout from "../components/UI/Layout/Layout";
import { useParams } from "react-router-dom";
import RoadmapsList from "../components/Roadmap/RoadmapsList/RoadmapsList";
import RoadmapDetails from "../components/Roadmap/RoadmapDetails/RoadmapDetails";

function Roadmap() {
  const params = useParams();

  let currentPage = <RoadmapsList />;

  if (params.pageId) {
    currentPage = <RoadmapDetails pageId={params.pageId} />;
  }

  return <Layout>{currentPage}</Layout>;
}

export default Roadmap;
