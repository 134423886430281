import React, { useState } from "react";
import Button from "../../UI/Button";
import AuthTitle from "../../UI/AuthTitle";
import ErrorMessage from "../../UI/ErrorMessage";
import Input from "../../UI/Input";
import { isValidEmail } from "../../../utils/Utils";
import { useNavigate } from "react-router-dom";
import classes from "./ForgetPassword.module.css";
import email from "../../../assets/images/icon/email.png";

const FrogetPassword = () => {
  const [enteredEmail, setEnteredEmail] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const activateButton = enteredEmail.length >= 5;


  const handleBack = () => {
    navigate(-1);
}

  return (
    <div className={classes.container}>
      <div className={classes.backButton}>
        <svg
          onClick={handleBack}
          width="23"
          height="14"
          viewBox="0 0 23 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6.33554 0L0 6.91738L6.33554 13.8348L7.66158 12.3548L3.62452 7.94694H22.5427V5.88781H3.62452L7.66158 1.48L6.33554 0Z"
            fill="#010440"
            fill-opacity="0.7"
          />
        </svg></div>
      <div className={classes.authContainer}>
        <div>
          <AuthTitle title="Forget Password" />
          <div className={classes.inputContainer}>
            <Input
              placeholder="Email"
              source={email}
              icon={"email"}
              value={enteredEmail}
              onChange={(email) => setEnteredEmail(email)}
            />
          </div>
          {error && <ErrorMessage message={error} />}
        </div>

        <div className={classes.buttonsContainer}>
          <Button
            onClick={() => {
              if (isValidEmail(enteredEmail)) {
                navigate("/login/verification", {
                  state: {
                    email: enteredEmail,
                  },
                });
              } else {
                setError("Email should be a valid Email.");
              }
            }}
            disabled={!activateButton}
          >
            Next
          </Button>
        </div>
      </div>
    </div>
  );
};

export default FrogetPassword;
