import React, { useState, useContext } from "react";
import Button from "../../UI/Button";
import AuthTitle from "../../UI/AuthTitle";
import ErrorMessage from "../../UI/ErrorMessage";
import UserContext from "../../../store/user-context";
import Input from "../../UI/Input";
import CheckBox from "../../UI/CheckBox";
import { isValidEmail } from "../../../utils/Utils";
import { useNavigate } from "react-router-dom";
import { Modal } from "react-rainbow-components";

import classes from "./SignUp.module.css";
import email from "../../../assets/images/icon/email.png";

const SignUp = () => {
  const [isChecked, setIsChecked] = useState(false);
  const [enteredEmail, setEnteredEmail] = useState("");
  const [error, setError] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const activateButton = enteredEmail.length >= 5 && isChecked;
  const userCtx = useContext(UserContext);

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <div className={classes.container}>
      <div className={classes.backButton}>
        <svg
          onClick={handleBack}
          width="23"
          height="14"
          viewBox="0 0 23 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6.33554 0L0 6.91738L6.33554 13.8348L7.66158 12.3548L3.62452 7.94694H22.5427V5.88781H3.62452L7.66158 1.48L6.33554 0Z"
            fill="#010440"
            fill-opacity="0.7"
          />
        </svg>
      </div>
      <div className={classes.authContainer}>
        <div>
          <AuthTitle title="Sign Up" pageType="signup" to={"/login"} />
          <div className={classes.inputContainer}>
            <Input
              placeholder="Email"
              source={email}
              icon={"email"}
              value={enteredEmail}
              onChange={(email) => setEnteredEmail(email)}
            />
          </div>
          {error && <ErrorMessage message={error} />}
        </div>

        <div className={classes.buttonsContainer}>
          <div className={classes.checkBoxContainer}>
            <div className={classes.checkBox}>
              <CheckBox
                isChecked={isChecked}
                onClick={() => setIsChecked((prev) => !prev)}
              />
              <div
                style={{ flexDirection: "row", display: "flex", gap: "3px" }}
              >
                <p className={classes.checkBoxText}>I agree to the </p>
                <p className={classes.checkBoxTermText}>Term Of Use</p>
              </div>
            </div>
            <Button
              isLoading={isLoading}
              onClick={() => {
                if (isValidEmail(enteredEmail)) {
                  userCtx.checkEmail(
                    enteredEmail,
                    (val) => setIsLoading(val),
                    (val) => setError(val),
                    (val) => setIsOpen(val),
                    () =>
                      navigate("/signup/verification", {
                        state: {
                          email: enteredEmail,
                          user: userCtx.user,
                        },
                      })
                  );
                } else {
                  setError("Your email should contain '@'.");
                }
              }}
              disabled={!activateButton}
            >
              Next
            </Button>
          </div>
          <Button
            googleButton={true}
            onClick={userCtx.googleSignUp}
            isLoading={isLoading}
          >
            Sign Up with Google
          </Button>
        </div>
      </div>
      <Modal
        id="modal-1"
        isOpen={isOpen}
        onRequestClose={() => setIsOpen(false)}
      >
        <p className={classes.modal}>
          You already have an account with us. Please log in to continue
        </p>
        <Button onClick={() => navigate("/login")}>Sign In</Button>
      </Modal>
    </div>
  );
};

export default SignUp;
