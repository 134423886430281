import { useContext, useEffect, useState } from "react";
import styles from "./ExploreTabs.module.css";
import { Tabs } from "antd";
import TabPane from "antd/es/tabs/TabPane";
import ForYouContent from "./ForYouContent/ForYouContent";
import NewestContent from "./NewestContent/NewestContent";
import PopularContent from "./PopularContent/PopularContent";
import TrendingContent from "./TrendingContent/TrendingContent";
import FilterContent from "./FilterContent/FilterContnet";
import StartOnboardingContent from "./StartOnboardingContent/StartOnboardingContent";
import UserContext from "../../../store/user-context";

const ExploreTabs = ({ explorItems, isLoading, loading }) => {
  const userCtx = useContext(UserContext);
  const isOnboarded =
    userCtx.user.selectedCategories &&
    (userCtx.user.selectedCategories.length >= 1 ? true : false);
  const [activeKey, setActiveKey] = useState(`${isOnboarded ? "1" : "3"}`);
  const [reloadContent, setReloadContent] = useState(false);

  useEffect(() => {
    if (explorItems !== null) {
      setActiveKey("5");
    }
  }, [explorItems]);

  useEffect(() => {
    setReloadContent(true);
    const timer = setTimeout(() => {
      setReloadContent(false);
    }, 100);

    return () => clearTimeout(timer);
  }, [activeKey]);

  return (
    <>
      {!loading && (
        <Tabs
          style={{ marginLeft: "20px", marginTop: "-7px" }}
          activeKey={activeKey}
          onChange={(key) => setActiveKey(key)}
          indicator={{ size: 0 }}
        >
          {activeKey !== "5" && (
            <>
              <TabPane
                tab={
                  <div
                    className={`${styles.tab} ${activeKey === "1" &&
                      styles.activeTab}`}
                  >
                    <span>For You</span>
                  </div>
                }
                key="1"
              >
                {isOnboarded ? (
                  !reloadContent && <ForYouContent />
                ) : (
                  <StartOnboardingContent />
                )}
              </TabPane>
              <TabPane
                tab={
                  <div
                    className={`${styles.tab} ${activeKey === "2" &&
                      styles.activeTab}`}
                  >
                    <span>Trending</span>
                  </div>
                }
                key="2"
              >
                {!reloadContent && <TrendingContent />}
              </TabPane>
              <TabPane
                tab={
                  <div
                    className={`${styles.tab} ${activeKey === "3" &&
                      styles.activeTab}`}
                  >
                    <span>Popular</span>
                  </div>
                }
                key="3"
              >
                {!reloadContent && <PopularContent />}
              </TabPane>
              <TabPane
                tab={
                  <div
                    className={`${styles.tab} ${activeKey === "4" &&
                      styles.activeTab}`}
                  >
                    <span>Newest</span>
                  </div>
                }
                key="4"
              >
                {!reloadContent && <NewestContent />}
              </TabPane>
            </>
          )}
          <TabPane
            tab={
              <div className={`${styles.tab}`}>
                <span></span>
              </div>
            }
            key="5"
          >
            <FilterContent explorItems={explorItems} isLoading={isLoading} />
          </TabPane>
        </Tabs>
      )}
    </>
  );
};

export default ExploreTabs;
