import React from "react";
import { Skeleton } from "antd";

import styles from "./LoadingSkeleton.module.css";

function LoadinSkeleton() {
  return (
    <>
      <div className={styles.headerContainer}>
        <p className={styles.title}>
          <Skeleton
            active
            paragraph={{
              rows: 0,
            }}
          />
        </p>
        <div className={styles.statisticsContainer}>
          <div className={styles.statisticContainer}>
            <Skeleton
              active
              paragraph={{
                rows: 0,
              }}
            />
          </div>
        </div>
        <div className={styles.infoContainer}>
          <Skeleton active paragraph={{ rows: 1 }} />
        </div>
        <div className={styles.roadmapPicContainer}>
          <Skeleton.Image
            active
            style={{
              height: "160px",
              width: "125px",
              marginRight: "8px",
            }}
          />
        </div>
      </div>
      {/* <p className={styles.roadmapCategory}>
        <Skeleton
          active
          paragraph={{
            rows: 0,
          }}
        />
      </p> */}
      <div className={styles.roadmapPlanContainer}>
        <p className={styles.description}>
          <Skeleton
            active
            paragraph={{
              rows: 2,
            }}
          />
        </p>
      </div>
      <div className={styles.skillsContainer}>
        <div className={styles.skillBoxesContainer}>
          <Skeleton.Button active size={"default"} shape={"round"} />
          <Skeleton.Button active size={"default"} shape={"round"} />
          <Skeleton.Button active size={"default"} shape={"round"} />
          <Skeleton.Button active size={"default"} shape={"round"} />
        </div>
      </div>
      <div
        style={{
          padding: "0 30px",
          marginTop: "30px",
          marginBottom: "30px",
          display: "flex",
          flexDirection: "column",
          gap: "8px",
        }}
      >
        <Skeleton.Button
          active
          shape={"default"}
          block
          style={{ height: "80px" }}
        />
        <Skeleton.Button
          active
          shape={"default"}
          block
          style={{ height: "80px" }}
        />
        <Skeleton.Button
          active
          shape={"default"}
          block
          style={{ height: "80px" }}
        />
      </div>
      <div
        style={{
          paddingLeft: "30px",
          display: "flex",
          overflow: "hidden",
        }}
      >
        <Skeleton.Image
          active
          style={{
            height: "160px",
            width: "125px",
            marginRight: "8px",
          }}
        />
        <Skeleton.Image
          active
          style={{
            height: "160px",
            width: "125px",
            marginRight: "8px",
          }}
        />
        <Skeleton.Image active style={{ height: "160px", width: "125px" }} />
      </div>
    </>
  );
}

export default LoadinSkeleton;
