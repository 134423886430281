import { Skeleton } from "antd";
import styles from "./SkeletonLoading.module.css";

const SkeletonLoading = () => {
    return <div className={styles.container}>
        <Skeleton active paragraph={false}/>
        <Skeleton active paragraph={false}/>
        <Skeleton active paragraph={false}/>
        <Skeleton active paragraph={false}/>
        <Skeleton active paragraph={false}/>
        <Skeleton active paragraph={false}/>
        <Skeleton active paragraph={false}/>
    </div>
};

export default SkeletonLoading;