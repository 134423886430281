import React, { useEffect, useState } from "react";
import RoadmapDetailsHeader from "./RoadmapDetailsHeader/RoadmapDetailsHeader";
import RoadmapDetailsBody from "./RoadmapDetailsBody/RoadmapDetailsBody";
import RatingModal from "./RatingModal/RatingModal";
import FullScreenChat from "./FullScreenChat/FullScreenChat";

import styles from "./RoadmapDetails.module.css";
import useRequest from "../../../requests/request";
import { useNavigate } from "react-router-dom";

const RoadmapDetails = (props) => {
  const { data: roadmap, loading, error, get, post } = useRequest();
  const {
    data: enrollment,
    loading: enrollLoading,
    post: enrollPost,
  } = useRequest();
  const navigate = useNavigate();
  const [startLoading, setStartLoading] = useState(false);
  const [fullScreenChat, setFullScreenChat] = useState(false);
  const [childCallback, setChildCallback] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem("token");

    get(`/Roadmap/${props.pageId}/detail`, {
      headers: {
        Authorization: "Bearer " + JSON.parse(token),
      },
    });
  }, []);

  useEffect(() => {
    if (roadmap && roadmap["enroled_data"]) {
      console.log(roadmap);
      if (roadmap["enroled_data"].status === "enroled") {
        navigate(`/user-roadmap/${roadmap["enroled_data"].userRoadmapId}`, {
          replace: true,
        });
      }
    }
  }, [navigate, roadmap]);

  useEffect(() => {
    if (enrollment) {
      navigate(`/user-roadmap/${enrollment.userRoadmapId}`, { replace: true });
    }
  }, [enrollment, navigate]);

  const addClickHandler = () => {
    setStartLoading(true);
    const token = localStorage.getItem("token");
    enrollPost(
      `/Roadmap/${props.pageId}/enroll`,
      {},
      {
        headers: {
          Authorization: "Bearer " + JSON.parse(token),
        },
      }
    );

    setStartLoading(false);
  };

  const registerChildCallback = (callback) => {
    setChildCallback(() => callback);
  };

  const handleFullScreenChats = () => {
    setFullScreenChat((prev) => !prev);
    if (childCallback) {
      childCallback();
    }
  };

  return (
    <div className={styles.container}>
      {roadmap && (
        <>
          <FullScreenChat
            fullScreen={fullScreenChat}
            onFullScreen={() => {
              handleFullScreenChats();
              window.scrollTo(0, 0);
            }}
          />
          <RoadmapDetailsHeader roadmap={roadmap} />
          <RoadmapDetailsBody
            loading={loading}
            roadmap={roadmap}
            isEnrolled={false}
            started={false}
            onFullScreenChat={handleFullScreenChats}
            registerCallback={registerChildCallback}
            setRatingModalOpen={setIsModalOpen}
            startLoading={startLoading}
            onAdd={addClickHandler}
            isUserRoadmap={false}
          />
          <RatingModal
            isModalOpen={isModalOpen}
            setIsModalOpen={setIsModalOpen}
            roadmap={roadmap}
          />
        </>
      )}
    </div>
  );
};

export default RoadmapDetails;
