import React from "react";

import TreeNode from "./TreeNode";
import classes from "./Tree.module.css";

function Tree({
  treeData,
  isEnrolled,
  started,
  dateRange,
  itemWidth,
  setFullScreenChat,
  registerCallback,
  isTDone,
  setRoadmapModalOpen,
  setIsStepDone,
  handleStepDone,
  roadmapSetter,
}) {
  const handleFullScreenChat = () => {
    setFullScreenChat();
  };
  // function addDays(date, days) {
  //   const newDate = new Date(date);
  //   newDate.setDate(date.getDate() + days);
  //   return newDate;
  // }
  return (
    <ul className={classes.itemContainer}>
      {treeData.map((node) => (
        <TreeNode
          node={node}
          roadmapSetter={roadmapSetter}
          roadmapEntity={node.RoadmapEntity}
          key={node.id}
          isEnrolled={isEnrolled}
          started={started}
          setIsStepDone={setIsStepDone}
          handleStepDone={handleStepDone}
          // dateRange={
          //   dateRange && [
          //     dateRange[0],
          //     addDays(
          //       dateRange[0],
          //       Math.ceil(
          //         (node.roadmapEntity.minEstimation +
          //           node.roadmapEntity.maxEstimation) /
          //           2
          //       ) * 7
          //     ),
          //   ]
          // }
          dateRange={dateRange && dateRange}
          itemWidth={itemWidth}
          setFullScreenChat={handleFullScreenChat}
          registerCallback={registerCallback}
          isTDone={isTDone}
          setRoadmapModalOpen={setRoadmapModalOpen}
        />
      ))}
    </ul>
  );
}

export default Tree;
