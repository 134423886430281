import React from "react";

import classes from "../AddTodoModal.module.css";
import RepeatDaysSelector from "./RepeatDaysSelector";
import RepeatRange from "./RepeatRange";

function CustomRepeat(props) {
  return (
    <div id="myModalCustom" className={classes.modal}>
      <div className={classes["modal-content"]}>
        <div className={classes["modal-header"]}>
          <span className={classes.close} onClick={props.onClose}>
            &times;
          </span>
        </div>

        <div
          className={classes["modal-body"]}
          style={{
            display: "flex",
            flexDirection: "column",
            paddingLeft: "32px",
          }}
        >
          <p>Select the days you want this task to repeat</p>

          <RepeatDaysSelector
            setSelectedList={props.setSelectedList}
            selectedList={props.selectedList}
          />
          {!props.selectedList.includes(-1) &&
            props.selectedList.length > 0 && (
              <p className={classes.days}>
                Every {props.selectedList.join(", ")}
              </p>
            )}

          <RepeatRange
            repeatWay={props.repeatWay}
            setRepeatWay={props.setRepeatWay}
            setRepeatRange={props.setRepeatRange}
            repeateRange={props.repeateRange}
          />

          <div className={classes.calenderButtons}>
            <button
              className={classes.calenderButton}
              onClick={() => {
                props.onClose();
              }}
            >
              OK
            </button>
            <button
              className={classes.calenderButton}
              onClick={() => props.onClose()}
            >
              REMOVE
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CustomRepeat;
